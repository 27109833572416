//-------------------------------------------------------------------------------
// Copyright © 2022 Valven. - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and Confidential
//-------------------------------------------------------------------------------

import React from 'react'
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import colors from '../../styles/_export.scss';

export default function CustomButton(props) {
    const {heightSize, paddingSize, backgroundColor, textTransform, textColor, ...other } = props

    const theme = createTheme({
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        fontSize: '16px',
                        textTransform: textTransform ?? 'capitalize',
                        fontWeight: 600,
                        padding: paddingSize ?? '20px',
                        lineHeight: 1,
                        borderRadius: '4px',
                        boxShadow: 'none',
                        whiteSpace: 'nowrap',
                        letterSpacing: '0',
                        "&.Mui-disabled": {
                            backgroundColor: `${colors.disabledButtonBg}`,
                            color: `${colors.disabledButtonFg}`,
                            border: `1px solid ${colors.disabledButtonBorder}`
                        },
                        '&:hover': {
                            boxShadow: 'none',
                        },
                        '& .MuiButton-startIcon':{
                            marginRight: 4,
                        }
                    },
                    sizeLarge: {
                        fontSize: '20px',
                        paddingRight: '22px',
                        paddingLeft: '22px',
                        minWidth: "185px",
                        height:"66px"
                    },
                    sizeMedium: {
                        paddingRight: '16px',
                        paddingLeft: '16px',
                        height:"50px",
                    },
                    sizeSmall: {
                        paddingRight: '16px',
                        paddingLeft: '16px',
                        fontSize: other.fontSize &&`${other.fontSize}px !important`,
                        height: heightSize ?? "34px"
                    },
                    textSizeMedium: {
                        fontSize: '14px'
                    },
                    textSizeSmall: {
                        fontSize: '12px'
                    },
                    iconSizeSmall: {
                        marginTop: '-3px'
                    },
                    containedPrimary: {
                        backgroundColor: backgroundColor ?? `${colors.primaryButton}`,
                        color: textColor ?? "rgb(255, 255, 255)",
                        '&:hover': {
                            backgroundColor: backgroundColor ?? `${colors.primaryButtonHover}`
                        }
                    },
                    containedSecondary: {
                        backgroundColor: colors.buttonSecondaryBg,
                        color: `${colors.primaryMain}`,
                        border: `1px solid ${colors.borderField}`,
                        '&:hover': {
                            backgroundColor: colors.buttonSecondaryBgHover
                        }
                    },
                    textPrimary: {
                        color: `${colors.text}`,
                        borderRadius: '200px',
                    },
                    textSecondary: {
                        color: `${colors.primaryButton}`,
                        borderRadius: '200px',
                        '&:hover': {
                            backgroundColor: 'transparent',
                            color: `${colors.primaryButtonHover}`
                        }
                    },
                    outlinedPrimary: {
                        color: `${colors.primaryButton}`,
                        border: `1px solid ${colors.primaryButton}`,
                        '&:hover': {
                            borderColor: colors.primaryButtonHover 
                        }
                    }
                }
            }
        }
    });

    if (other.startIcon){
        theme["components"]["MuiButton"]["styleOverrides"]["root"]["paddingTop"] = '0px'
        theme["components"]["MuiButton"]["styleOverrides"]["root"]["paddingBottom"] = '0px'
    }
 
    return (
        <ThemeProvider theme={theme}>
            <Button {...other} />
        </ThemeProvider>
    )
}
