//-------------------------------------------------------------------------------
// Copyright © 2022 Valven. - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and Confidential
//-------------------------------------------------------------------------------

import React from 'react'
import { Box, Link } from "@mui/material"
import MenuItem from '@mui/material/MenuItem';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import InputField from './InputField';
import { StyledTooltip } from '../tooltip';
import CustomSelect from './CustomSelect';
import RadioButton from './RadioButton';
import CheckboxButton from './CheckboxButton';
import OutlinedInput from './OutlinedInput';
import ThemedSwitch from './ThemedSwitch';
import LinkV from './Link';
import { ReactComponent as InfoIcon } from '../../static/img/common/info.svg'
import { useNavigate } from "react-router-dom";
import Divider from "@mui/material/Divider";


import styles from './FormField.module.scss';
import colors from '../../styles/_export.scss';
import Slide from './Slide';


const theme = createTheme({
    components: {
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: `${colors.text}`,
                    marginBottom: '16px',
                    '&.Mui-focused': {
                        color: `${colors.text}`
                    }
                }
            }
        },
        MuiFormGroup: {
            styleOverrides: {
                root: {
                    marginTop: '8px'
                }
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    color: `${colors.text}`,
                    marginLeft: 0
                },
                labelPlacementStart: {
                    marginLeft: '0px',
                    justifyContent: 'flex-end',
                    '& .MuiFormControlLabel-label': {
                        marginRight: '12px',
                    }
                },
            }
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    color: `${colors.text}`,
                    fontSize: '19px'
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: colors.fieldOutline,
                    borderWidth: '2px',
                    boxShadow: `0px 3px 6px ${colors.fieldOutlineShadow}`,
                }
            }
        }
    }
});

export default function FormField({ data, onChange, fieldMaxWidth, fieldPadding }) {
    const navigate = useNavigate();
    onChange = onChange ?? function (ev) { console.debug(ev.target.name + " " + ev.target.value); return; };
    const id = data.id ?? data.name
    const fieldRef = React.useRef(null)
    const showLabel = !data.outlined && (data.label || data.freetext) && (data.type != "checkbox" && data.type != "switch")

    const fieldStyle = data.type == "section" ? null : { maxWidth: fieldMaxWidth, padding: fieldPadding }
    const playAudio = (audio) => {
        let song = new Audio(audio);
        song.play();
    }

    return (
        <ThemeProvider theme={theme}>
            <Box className={styles.fieldWrapper + ' ' + (data.outlined ? styles.outlined : '') + ' ' + (data.type === "captcha" ? styles.captcha : '') + ' ' + (data.inner ? styles.FormField_Inner : '')} sx={{...(data.type=="divider" && {height: 0, margin: data.dividerMargin})}}
                style={fieldStyle}>
                <FormControl fullWidth>
                    {showLabel ?
                        <FormLabel id={`data-${id ?? data.name}`} style={{ "display": "flex", "alignItems": "center" }}>{data.label}</FormLabel>
                        : null}
                    {
                        (data.type == "radio" &&
                            <>
                                <RadioGroup
                                    name={`data-${id}`}
                                    onChange={onChange}
                                    value={data.value}
                                    row={data.row}
                                >
                                    {data.choices.map(function (choice, i) {
                                        return (
                                            <FormControlLabel key={i} value={choice.value} name={data.name} control={<RadioButton required={data.required ?? false} />} label={choice.label} />
                                        )
                                    })}
                                </RadioGroup>

                            </>
                        ) ||
                        ((data.type == "text" || data.type == "email") &&
                            <>
                                {data.outlined ?
                                    <div className={styles.textWrapper}>
                                        <span className={styles.iconWrapper}> {data.info && <StyledTooltip title={data.info} arrow><InfoIcon width="20px" height="20px" /></StyledTooltip>}</span>
                                        <OutlinedInput onBlur={data.onBlur} type={data.type} id={`data-${id}`} label={data.label} name={data.name} onChange={onChange} variant="outlined" required={data.required ?? false} defaultValue={data.value} helperText={data.helperText} error={!!data.error} placeholder={data.placeholder} />
                                    </div>
                                    :
                                <InputField type={data.type} id={`data-${id}`} name={data.name} onChange={onChange} variant="outlined" required={data.required ?? false} defaultValue={data.value} helperText={data.helperText} error={!!data.error} placeholder={data.placeholder} />
                                }
                            </>
                        ) ||
                        (data.type == "textarea" &&
                            <>
                                {data.outlined ?
                                <OutlinedInput id={`data-${id}`} label={data.label} name={data.name} onChange={onChange} variant="outlined" multiline rows={data.rows || 3} required={data.required ?? false} value={data.value} helperText={data.helperText} error={!!data.error} placeholder={data.placeholder} />
                                    :
                                <InputField id={`data-${id}`} name={data.name} onChange={onChange} variant="outlined" multiline maxRows={4} required={data.required ?? false} value={data.value} helperText={data.helperText} error={!!data.error} placeholder={data.placeholder} />
                                }
                            </>
                        ) ||
                        (data.type == "checkbox" &&
                            <>
                                <div className={styles.checkboxWrapper}>
                                    <FormControlLabel
                                        control={
                                            <>
                                                <CheckboxButton onChange={onChange} name={data.name} required={data.required ?? false} />
                                            </>
                                        }
                                        label={data.label}
                                        labelPlacement={data.labelPlacement || "end"}
                                        height="50px"
                                    />
                                    {data.info && <StyledTooltip arrow title={data.info}><InfoIcon width="24px" height="24px" /></StyledTooltip>}
                                </div>
                            </>
                        ) ||
                        (data.type == "dropdown" &&
                            <Box className={styles.FormField_Dropdown}>
                                <CustomSelect
                                    fullWidth
                                    displayEmpty
                                    name={data.name}
                                    multiple={data.multiple}
                                    onChange={(ev) => {
                                        console.log(ev.target.value)
                                        onChange(ev);
                                    }}
                                    value={data.value}
                                    size="medium"
                                    info={data.info}
                                    fieldRef={fieldRef}
                                    inputmargin="10px 0"
                                    margin={data.margin}
                                    label={data.outlined && data.label}
                                    required={data.required ?? false}
                                    showavatar={`${data.avatar}`}
                                    choices={data.choices}
                                />
                                {data.audio &&
                                    <Box className={styles.FormField_Audio} onClick={() => {playAudio(data.audio)}} />
                                }
                            </Box>
                        ) ||
                        (data.type == "hidden" &&
                            <input type="hidden" id={`data-${id}`} name={data.name} defaultValue={data.value} />
                        ) ||
                        (data.type == "password" &&
                            <>
                            <OutlinedInput label={data.outlined && data.label} id={`data-${id}`} name={data.name} onChange={onChange} variant="outlined" required={data.required ?? false} value={data.value} type="password" helperText={data.helperText} error={!!data.error} placeholder={data.placeholder} size={data.size} customstyle={data.customStyle} />
                            </>) ||
                        (data.type == "divider" && <Divider />) ||
                        (data.type == "switch" &&
                            (data.description ?
                                <Box className={styles.FormField_SwitchDescriptionWrapper}>
                                    <Box className={styles.FormField_SwitchLeftWrapper}>
                                        <Box className={styles.FormField_Switch__Text}>
                                            {data.label}
                                        </Box>
                                        <Box className={styles.FormField_Switch__Description}>
                                            {data.description}
                                        </Box>
                                    </Box>
                                    <Box className={styles.FormField_SwitchWrapper__Switch}>
                                        <ThemedSwitch name={data.name} checked={data.checked ?? false} onChange={onChange} />
                                    </Box>
                                </Box>
                                :
                                <Box className={styles.FormField_SwitchWrapper}>
                                    <Box className={styles.FormField_SwitchWrapper__Text}>
                                        {data.label}
                                    </Box>
                                    <Box className={styles.FormField_SwitchWrapper__Switch}>
                                        <ThemedSwitch name={data.name} checked={data.checked ?? false} onChange={onChange} />
                                    </Box>
                                </Box>)) ||
                        (data.type == "forgotPasswordLink" && !data.send_mail_to_created_user &&
                        <>
                            <Link
                                component="text"
                                variant="body2"
                                underline="hover"
                                style={{display: "flex", justifyContent: "flex-end", cursor: "pointer", position: "absolute", right: "0px"}}
                                onClick={() => {
                                    navigate("/reset-password");
                                }}
                            >
                                <span style={{fontSize: "12px", color: '#6D809F'}}>{'{{ _("Forgot Password") }}'}</span>
                            </Link> 
                        </>
                        ) ||
                        (data.type == "link" &&
                            <LinkV values={data.values} onChange={onChange} />
                        ) ||
                        (data.type == "slide" &&
                            <Slide data={data} onChange={onChange} />
                        ) ||
                        (data.type == "section" &&
                            <Box className={`${styles.section} ${data.border && styles.border}`}>
                                <Box className={styles.sectionTitle}>
                                    {data.title}
                                </Box>
                                {data.description &&
                                    <Box className={styles.sectionDescription}>
                                        {data.description}
                                    </Box>
                                }
                            </Box>
                        )
                    }
                </FormControl>

            </Box>
        </ThemeProvider >
    )
}
