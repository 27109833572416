//-------------------------------------------------------------------------------
// Copyright © 2022 Valven. - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and Confidential
//-------------------------------------------------------------------------------

import React from 'react'
import { Box, Grid } from "@mui/material";
import CustomDialog from '../../customDialog/CustomDialog';
import styles from './AddSlideDialog.module.scss'
import { strings } from '../../i18n'
import { OutlinedInput } from '../../form';
import { ReactComponent as UploadIcon } from '../../../static/img/upload_orange.svg';
import { ReactComponent as DeleteIcon } from '../../../static/img/ac_delete_32.svg';
import { ReactComponent as AddIcon } from '../../../static/img/common/add.svg';
import CustomButton from '../../form/CustomButton';
import CustomSelect from '../../form/CustomSelect';

export default function AddSlideDialog({ formID, formRef, open, handleClose, onFormSubmit }) {

    const [buttons, setButtons] = React.useState([{}])

    const types = [
        {
            "value": 1,
            "label": "Send to URL"
        },
        {
            "value": 2,
            "label": "Send Next Step"
        },
        {
            "value": 3,
            "label": "Set Variable"
        }
    ]

    const steps = [
        {
            "value": 1,
            "label": "Announcement 1"
        },
        {
            "value": 2,
            "label": "Announcement 2"
        },
    ]

    const onTypeChanged = (ev, i) => {
        const tmp = [...buttons];
        let item = { ...tmp[i] };
        item.type = ev.target.value;
        item.value = null;
        tmp[i] = item;

        setButtons(tmp);
    }

    const onTextChanged = (ev, i, key) => {
        const tmp = [...buttons];
        let item = { ...tmp[i] };
        item[key || "text"] = item.value = ev.target.value;
        tmp[i] = item;

        setButtons(tmp);
    }

    const onButtonCreate = () => {
        setButtons([...buttons, {
            "text": "",
            "type": 1,
            "value": ""
        }])
    }

    const handleDelete = (i) => {
        const tmp = [...buttons];
        tmp.splice(i, 1);
        setButtons(tmp);
    }

    const sx = { padding: '0px 32px 12px 32px' }
    return (
        <CustomDialog
            name={strings.add_slide}
            handleClose={handleClose}
            open={open}
            hasFooter={true}
            onClick={() => {onFormSubmit(buttons)}}
            content={
                <>
                    <Box sx={sx}>
                        <Grid container columns={12}>
                            <Grid item xs={6}>
                                <OutlinedInput type="text" label={strings.title} name="title" variant="outlined" />
                                <OutlinedInput label={strings.description} name="description" variant="outlined" multiline rows={3} />
                            </Grid>
                        </Grid>
                        <UploadField />
                    </Box>
                    <Box sx={sx} className={styles.Carousel}>
                        <Box className={styles.Carousel_Title}>
                            {strings.carousel_buttons}
                        </Box>
                        <Box className={styles.officeHours}>
                            <>
                                <Box className={styles.officeHourContainer}>
                                    {buttons.map((item, i) => {
                                        return (
                                            <Box className={styles.ButtonWrapper} key={i}>
                                                <Box className={styles.officeHourItem}>
                                                    <Box className={styles.index}>{i + 1}</Box>
                                                    <OutlinedInput
                                                        onChange={(ev) => { onTextChanged(ev, i) }}
                                                        className={styles.item}
                                                        type="text"
                                                        value={item.text}
                                                        label={strings.button_text}
                                                        name={i + "text"}
                                                        variant="outlined" />

                                                    <CustomSelect
                                                        className={styles.item}
                                                        onChange={(ev) => { onTypeChanged(ev, i) }}
                                                        label={strings.button_type}
                                                        value={item.type || 1}
                                                        choices={types}
                                                    />

                                                    {(!item.type || item.type == 1) &&
                                                        <OutlinedInput
                                                            className={styles.item}
                                                            type="text"
                                                            label={strings.url}
                                                            name={i + "val"}
                                                            variant="outlined" />
                                                    }
                                                    {(item.type == 2 || item.type == 3) &&
                                                        <CustomSelect
                                                            className={styles.item}
                                                            label={strings.next_step}
                                                            value={item.value || 1}
                                                            choices={steps}
                                                        />
                                                    }
                                                    <DeleteIcon className={styles.delete} onClick={() => handleDelete(i)} />
                                                </Box>
                                                {item.type == 3 &&
                                                    <Box className={styles.subItem}>
                                                        <OutlinedInput
                                                            onChange={(ev) => { onTextChanged(ev, i, "value0") }}
                                                            className={styles.item0}
                                                            type="text"
                                                            value={item.value0}
                                                            label={strings.set_variable}
                                                            name={i + "setvar"}
                                                            variant="outlined" />

                                                        <OutlinedInput
                                                            onChange={(ev) => { onTextChanged(ev, i, "value1") }}
                                                            className={styles.item1}
                                                            type="text"
                                                            value={item.value1}
                                                            label={strings.message_to_send}
                                                            name={i + "message"}
                                                            variant="outlined" />
                                                    </Box>
                                                }
                                            </Box>
                                        )
                                    })}
                                </Box>
                            </>
                        </Box>
                    </Box>
                    <Box sx={sx} className={styles.Color}>
                        <OutlinedInput className={styles.Color_Item} label={strings.button_border_color} name="buttonBorderColor" type="color" onChange={(ev) => handleChange(ev.target.name, ev.target.value)} />
                        <OutlinedInput className={styles.Color_Item} label={strings.button_text_color} name="buttonTextColor" type="color" onChange={(ev) => handleChange(ev.target.name, ev.target.value)} />
                    </Box>
                    <Box sx={sx} className={styles.Add}>
                        <CustomButton onClick={onButtonCreate} className={styles.Add_Button} startIcon={<AddIcon />} size="medium" variant="outlined" color="primary">{strings.add_button}</CustomButton>
                    </Box>
                </>
            }>
        </CustomDialog>
    )
}

function UploadField() {

    const [uploadedImage, setUploadedImage] = React.useState(null)

    const onDelete = () => {
        setUploadedImage(null)
    }

    return (
        <Grid container columns={12} sx={{ marginTop: "12px" }}>
            <Grid item xs={6}>
                <OutlinedInput
                    InputProps={{
                        endAdornment:
                            (uploadedImage ? <DeleteIcon onClick={onDelete} /> : <></>)
                    }}
                    fullWidth
                    type="text"
                    variant="outlined"
                    label={strings.photo}
                    value={uploadedImage?.name || ''}
                    name="photo" />
            </Grid>
            <Grid item xs={6} className={styles.UploadField_GridPhoto}>
                {uploadedImage ?
                    <img id="output" className={styles.UploadFile_Img} src={URL.createObjectURL(uploadedImage)} />
                    :
                    <>

                        <input accept="image/*" id="upload" type="file" style={{ display: 'none' }} onChange={(e) => {
                            if (e.target.files.length > 0) setUploadedImage(e.target.files['0'])
                        }} />
                        <label htmlFor="upload" className={styles.UploadField_Label}>
                            <div><UploadIcon /></div>
                            <div className={styles.UploadField_Label_Text}>Upload File</div>
                        </label>
                    </>
                }
            </Grid>
        </Grid>
    )
}