import { useMediaQuery } from "react-responsive";
import { useEffect, useRef } from "react";
export const sortArrayByType = (arr, sortBy = "id") => {
  arr.sort((a, b) => {
    if (a[sortBy] < b[sortBy]) {
      return -1;
    } else if (a[sortBy] > b[sortBy]) {
      return 1;
    } else {
      return 0;
    }
  });

  return arr;
};
export const arraysAreEqual = (arr1, arr2, isSort = true, sortBy) => {
  let array1 = structuredClone(arr1);
  let array2 = structuredClone(arr2);
  if (array1.length !== array2.length) {
    return false;
  }

  let sortedArr1 = array1;
  let sortedArr2 = array2;
  if (isSort) {
    sortedArr1 = sortArrayByType(array1, sortBy);
    sortedArr2 = sortArrayByType(array2, sortBy);
  }
  for (let i = 0; i < array1.length; i++) {
    if (JSON.stringify(sortedArr1[i]) !== JSON.stringify(sortedArr2[i])) {
      return false;
    }
  }

  return true;
};
export function isMobile() {
  return useMediaQuery({ maxWidth: 600 });
}

export const useInterval = (callback, delay) => {

  const savedCallback = useRef();

  useEffect(() => {
      savedCallback.current = callback;
  }, [callback]);


  useEffect(() => {
      function tick() {
          savedCallback.current();
      }
      if (delay !== null) {
          const id = setInterval(tick, delay);
          return () => clearInterval(id);
      }
  }, [delay]);
}
export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
      function handleResize() {
          setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}
export function isEN() {
  return window.lang == "tr" ? false : true
}

export function isTR() {
  return window.lang == "tr" ? true : false
}

export function getCookie (cname) {
  let name = cname + "=";
  let ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
          c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
      }
  }
  return "";
}

export function getStatusColorForVoiceStatus(status) {
  if(status == "available" || status == "müsait"){
      return {color:"#FFFFFF", backgroundColor:"#1DBE79", borderColor:"#1DBE79"}
  }  else if (status == "shortbreak" || status == "kısa mola" || status == "lunch" || status == "öğle yemeğinde" || status == "training" || status == "eğitimde" || status == "backoffice" || status == "ofis çalışması" || status == "meeting" || status == "toplantıda" || status == "aftercallwork" || status == "çağrı sonu işi" || status == "wrapup" || status == "kısa ara"){
      return {color:"#1171D9", backgroundColor:"#1171D926", borderColor:"#1171D9"}
  } else if (status == "ringing" || status == "çalıyor" || status == "dialing" || status == "arıyor" || status == "outbounding" || status == "giden arama"){
      return {color:"#FD581F", backgroundColor:"#FD581F26", borderColor:"#FD581F"}
  } else if (status == "talking" || status == "konuşuyor" || status == "chatting" || status == "sohbet"){
      return {color:"#1DBE79", backgroundColor:"#1DBE7926", borderColor:"#1DBE79"}
  } else if (status == "hold" || status == "beklet"){
      return {color:"#ECAD00", backgroundColor:"#ECAD0026", borderColor:"#ECAD00"}
  } else if (status == "notanswering" || status == "cevap verilmiyor"){
      return {color:"#F02833", backgroundColor:"#F0283326", borderColor:"#F02833"}
  } else if(status == "logoff" || status == "çevrimdışı" || status == "") {
      return {color:"#808080", backgroundColor:"#F5F5F5", borderColor:"#808080"}
  } else {
      return {color:"#314469", backgroundColor:"#31446926", borderColor:"#314469"}
  }
}