import * as React from "react";
import { Button, IconButton, Snackbar } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import MuiAlert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

import CloseIcon from "@mui/icons-material/Close";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import styles from "./Alert.module.scss";

import PropTypes from "prop-types";

Alert.propTypes = {
  open: PropTypes.bool,
  closable: PropTypes.bool,
  message: PropTypes.string,
  title: PropTypes.string,
  action: PropTypes.string,
  severity: PropTypes.oneOf(["success", "info", "warning", "error"]),
  vertical: PropTypes.oneOf(["top", "center", "bottom"]),
  horizontal: PropTypes.oneOf(["left", "center", "right"]),
  autoHideDuration: PropTypes.number,
  onClose: PropTypes.func,
  onActionClick: PropTypes.func,
};

export default function Alert(props) {
  const theme = createTheme({
    components: {
      MuiAlert: {
        styleOverrides: {
          root: {
            "& .MuiAlert-message": {
              lineHeight: "1",
            },
            "& .MuiButton-text": {
              lineHeight: "2",
            },
          },
        },
      },
      MuiSnackbar: {
        styleOverrides: {
          root: {
            left: props.leftMargin ? props.leftMargin + " !important" : "",
          },
        },
      },
    },
  });

  const {
    message,
    action,
    severity,
    title,
    autoHideDuration,
    closable,
    onClose,
    onActionClick,
  } = props;
  const [state, setState] = React.useState({
    open: props.open ?? false,
    vertical: props.vertical || "bottom",
    horizontal: props.horizontal || "left",
  });
  const { vertical, horizontal, open } = state;

  React.useEffect(() => {
    setState({ ...state, open: props.open });
  }, [props.open]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setState({ ...state, open: false });
    if (onClose) {
      onClose();
    }
  };

  const handleClick = (event) => {
    if (onActionClick) {
      onActionClick();
    }
  };

  const actionObj = (
    <React.Fragment>
      {action && (
        <Button
          sx={{ fontSize: "14px" }}
          color="inherit"
          className={styles.actionButton}
          size="small"
          onClick={handleClick}
        >
          {action}
        </Button>
      )}
      {closable ? (
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ) : null}
    </React.Fragment>
  );

  return (
    <ThemeProvider theme={theme}>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        autoHideDuration={autoHideDuration ?? 5000}
        onClose={handleClose}
        action={actionObj}
        key={vertical + horizontal}
      >
        <MuiAlert
          severity={severity ?? "success"}
          variant="filled"
          iconMapping={{
            success: <CheckCircleOutlineIcon fontSize="inherit" />,
          }}
          className={title && action ? styles["titleAction"] : styles["action"]}
          action={actionObj}
          sx={{ width: "100%", fontSize: "14px" }}
        >
          {title && <AlertTitle>{title}</AlertTitle>}
          {message}
        </MuiAlert>
      </Snackbar>
    </ThemeProvider>
  );
}
