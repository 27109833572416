import React, { useState, useEffect, useCallback } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { VariableSizeList as List } from "react-window";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { ReactComponent as DeleteFilterIcon } from "../../static/img/close.svg";
import { ReactComponent as AgentsIcon } from "../../static/img/filter_name_l.svg";
import { ReactComponent as SelectedAgentsIcon } from "../../static/img/performance_voice_clicked_org.svg";
import { ReactComponent as TagIcon } from "@alotech/common/static/img/filter_tag.svg";
import { ReactComponent as SelectedTagIcon } from "@alotech/common/static/img/filter_tag_selected.svg";
import { ReactComponent as WarningIcon } from "@alotech/common/static/img/warning-icon.svg";
import { ReactComponent as SelectedWarningIcon } from "@alotech/common/static/img/warning-icon-h.svg";
import { ReactComponent as SelectedFtpIcon } from "@alotech/common/static/img/key_h.svg";
import { ReactComponent as FtpIcon } from "@alotech/common/static/img/key.svg";
import { ReactComponent as QueueIcon } from '../../static/img/dashboard_queue.svg';
import { ReactComponent as QueueIconHover } from '../../static/img/hover_item_queue.svg';
import { ReactComponent as TeamsIcon } from '../../static/img/voice_table_team.svg';
import { ReactComponent as SelectedTeamsIcon } from '../../static/img/TeamHover-voice.svg';
import { ReactComponent as AutoCloseSelectedIcon } from "@alotech/common/static/img/clock-time2-selected.svg"
import { ReactComponent as AutoCloseIcon } from "@alotech/common/static/img/clock-time2.svg";

import { Box, Chip, Divider, TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Menu from "@mui/material/Menu";
import _, { debounce } from "lodash";
import { OutlinedInput, RadioButton } from "../form";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import CheckboxButton from "../form/CheckboxButton";
import colors from "../../styles/_export.scss";
import styles from "./FilterTab.module.scss";
import "dayjs/locale/en";

dayjs.locale("en");

const FilterTab = React.memo((props) => {
  const {
    title,
    icon,
    selectedIcon,
    options,
    update,
    singleSelect,
    multiSelect,
    isUpdateEveryChange,
    closeMenuEveryDataChange,
    autoFocus,
    enableDeleteAction = true,
    clearAfterEnter,
    searchable,
    selectAllBySearch,
    virtualized,
    parent,
    getData,
    selectedOptions,
    dateSelect,
    dateFormat,
    minDate,
    maxDate,
    timeSelect,
    inputSelect,
    maxWidth,
    searchType,
  } = props;
  const [customPopup, setCustomPopup] = useState(null);
  const customPopupOpen = Boolean(customPopup);

  const handleChangeClosePopup = (event) => {
    setCustomPopup(null);
    event && event.stopPropagation();
  };

  const handleCustomPopup = (event) => {
    setCustomPopup(event.currentTarget);
    event.stopPropagation();
  };

  const handleDeleteCustomButton = (e) => {
    let newData = structuredClone(options);
    newData = newData.map((x) => {
      if (!x.disabled && x.checked) {
        x.checked = false;
      }
      return x;
    });
    update(newData, []);
  };

  const chiptheme = (enableDeleteAction, maxWidth) =>
    createTheme({
      components: {
        MuiChip: {
          styleOverrides: {
            root: {
              padding:
                enableDeleteAction == true
                  ? "6px 0 6px 10px"
                  : "6px 14px 6px 10px",
              height: "28px",
              maxWidth: maxWidth ?? "250px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              backgroundColor: `${colors.hover}`,
            },
            colorPrimary: {
              color: "#002983",
              "&.Mui-checked": {
                color: "#002983",
              },
            },
            colorSecondary: {
              color: "#FD581F",
              "&.Mui-checked": {
                color: "#FD581F",
              },
            },
            colorInfo: {
              color: "#002983",
              "&.Mui-checked": {
                color: "#002983",
              },
            },
            outlined: {
              border: `1px solid #FD7422`,
            },
            outlinedPrimary: {
              border: `1px solid #D8DCEB`,
            },
            outlinedSecondary: {
              border: `1px solid #FD7422`,
            },
            label: {
              fontSize: "12px",
              fontWeight: "500",
              paddingLeft: "4px !important",
              paddingRight: "4px !important",
              marginTop: "2px",
            },
            deleteIcon: {
              flexShrink: 0,
              display: enableDeleteAction == true ? "block" : "none !important",
              width: "16px",
              height: "16px",
              marginLeft: "0px !important",
            },
            icon: {
              flexShrink: 0,
              marginRight: "0px !important",
              marginLeft: "0px !important",
              width: "16px",
              height: "16px",
            },
            selectIcon: {
              flexShrink: 0,
              marginRight: "0px !important",
              marginLeft: "0px !important",
              width: "16px",
              height: "16px",
            },
          },
        },
      },
    });

  const getIcon = (icon) => {
    switch (icon) {
      case "user":
        return <AgentsIcon />;
      case "selectedUser":
        return <SelectedAgentsIcon />;
      case "ftpAddress":
        return <FtpIcon />;
      case "selectedFtpAddress":
        return <SelectedFtpIcon />;
      case "recordType":
        return <TagIcon />;
      case "selectedRecordType":
        return <SelectedTagIcon />;
      case "downloadResult":
        return <WarningIcon />;
      case "selectedDownloadResult":
        return <SelectedWarningIcon />;
      case "queues":
        return <QueueIcon />;
      case "selectedQueues":
        return <QueueIconHover />;
      case "teams":
        return <TeamsIcon />;
      case "selectedTeams":
        return <SelectedTeamsIcon />;
      case "statuses":
        return <AutoCloseIcon />;
      case "selectedStatuses":
        return <AutoCloseSelectedIcon />;
      default:
        null;
    }
  };

  return (
    <ThemeProvider
      key={title}
      theme={chiptheme(
        (getData ? selectedOptions : options).some(
          ({ checked, disabled }) => !disabled && checked
        )
          ? enableDeleteAction
          : false,
        maxWidth
      )}
    >
      <Chip
        sx={{marginRight: "5px"}}
        key={title}
        label={
          (getData ? selectedOptions : options).some(
            ({ checked, disabled }) => !disabled && checked
          )
            ? timeSelect || inputSelect
              ? `${
                  (getData ? selectedOptions : options).find(
                    ({ checked, disabled }) => !disabled && checked
                  ).label
                }`
              : dateSelect
              ? `${dayjs(
                  (getData ? selectedOptions : options).find(
                    ({ checked, disabled }) => !disabled && checked
                  ).id
                ).format("DD.MM.YYYY")}`
              : `${
                  (getData ? selectedOptions : options).filter(
                    ({ checked, disabled }) => !disabled && checked
                  ).length
                } ${title}`
            : title
        }
        icon={getIcon(
          (getData ? selectedOptions : options).some(
            ({ checked, disabled }) => !disabled && checked
          ) || customPopupOpen
            ? selectedIcon
            : icon
        )}
        data-id={title}
        color={
          customPopupOpen
            ? "secondary"
            : (getData ? selectedOptions : options).some(
                ({ checked, disabled }) => !disabled && checked
              )
            ? "info"
            : "primary"
        }
        variant="outlined"
        onClick={handleCustomPopup}
        onDelete={handleDeleteCustomButton}
        deleteIcon={<DeleteFilterIcon />}
      />
      <AgentsPopup
        open={customPopupOpen}
        anchorEl={customPopup}
        handleClose={handleChangeClosePopup}
        title={title}
        options={options}
        update={update}
        multiSelect={multiSelect}
        singleSelect={singleSelect}
        isUpdateEveryChange={isUpdateEveryChange}
        closeMenuEveryDataChange={closeMenuEveryDataChange}
        autoFocus={autoFocus}
        clearAfterEnter={clearAfterEnter}
        searchable={searchable}
        selectAllBySearch={selectAllBySearch}
        virtualized={virtualized}
        parent={parent}
        getData={getData}
        selectedOptions={selectedOptions}
        dateSelect={dateSelect}
        dateFormat={dateFormat}
        minDate={minDate}
        maxDate={maxDate}
        timeSelect={timeSelect}
        inputSelect={inputSelect}
        searchType={searchType}
      />
    </ThemeProvider>
  );
});

export default FilterTab;

const AgentsPopup = React.memo((props) => {
  const {
    title,
    open,
    anchorEl,
    handleClose,
    options,
    update,
    multiSelect,
    singleSelect,
    isUpdateEveryChange,
    closeMenuEveryDataChange,
    autoFocus = true,
    clearAfterEnter,
    searchable = props.dateSelect ? false : true,
    selectAllBySearch = true,
    virtualized = true,
    parent,
    getData,
    selectedOptions,
    dateSelect,
    dateFormat,
    minDate,
    maxDate,
    timeSelect,
    inputSelect,
    searchType,
  } = props;
  const [query, setQuery] = useState("");
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(
    options.every((x) => x.checked)
  );
  const setParent = (arr) => {
    let newData = structuredClone(arr);
    if (parent) {
      newData.map((x) => {
        if (x[parent]) {
        } else {
          x[parent] = "Others";
        }
      });
    }
    return newData;
  };
  const [data, setData] = useState(setParent(options));
  const [isLoading, setIsLoading] = useState(false);
  const [selectedData, setSelectedData] = useState([]);

  const getDatas = useCallback(
    debounce((search) => {
      getData(search, selectedData);
    }, 750),
    [selectedData]
  );

  useEffect(() => {
    if (open) {
      setData(setParent(options));
      setIsSelectAllChecked(
        structuredClone(options)
          .filter((x) => !x.disabled)
          .every((x) => x.checked)
      );
    }
  }, [open]);

  useEffect(() => {
    getData && getDatas(query);
  }, []);

  useEffect(() => {
    if (open && getData) {
      if (isLoading) {
        setIsLoading(false);
        setData(setParent(options));
        setIsSelectAllChecked(
          structuredClone(options)
            .filter((x) => !x.disabled)
            .every((x) => x.checked)
        );
      }
    }
    if (open && isUpdateEveryChange && !getData) {
      setData(setParent(options));
      setIsSelectAllChecked(
        structuredClone(options)
          .filter((x) => !x.disabled)
          .every((x) => x.checked)
      );
    }
  }, [options]);

  useEffect(() => {
    selectedOptions && setSelectedData(selectedOptions);
  }, [selectedOptions]);

  const onTextChanged = (ev) => {
    const { value } = ev.target;
    allSelectChangeByText(value);
  };

  const allSelectChangeByText = (search) => {
    setQuery(search);
    if (getData) {
      setIsLoading(true);
      getDatas(search);
    }
    setSelectedAllChecked(data, search);
  };

  const handleFilterRadioChange = (event) => {
    const { id, value, checked } = event.target;
    let newData = [...data];
    newData.map((x) =>
      x.disabled ? x : x.id == id ? (x.checked = checked) : (x.checked = false)
    );
    setDatas(event, newData);
  };

  const handleFilterCheckedChange = (event) => {
    const { id, checked, disabled } = event.target;
    let newData = [...data];
    if (id == "select_all") {
      if (selectAllBySearch) {
        newData = selectAllByQuery(newData, checked);
      } else {
        newData.map((x) => (x.disabled ? x : (x.checked = checked)));
      }
    } else {
      newData.find((x) => x.id == id).checked = checked;
    }
    setDatas(event, newData);
  };

  const setDatas = (event, newData, selectData) => {
    let newSelectData = selectData || setSelectData(newData);
    setSelectedData(newSelectData);
    setData(newData);
    setSelectedAllChecked(newData);
    updateEveryChange(event, newData, newSelectData);
  };

  const setSelectData = (newData) => {
    let newSelectData = [...selectedData]; //??
    newData.forEach((x) => {
      if (x.disabled) return;
      if (!x.checked) {
        newSelectData = newSelectData.filter((y) => {
          return x?.id !== y?.id;
        });
        return;
      }
      if (x.checked) {
        if (singleSelect || timeSelect) {
          let emptyArr = [];
          emptyArr.push(x);
          newSelectData = emptyArr;
        } else if (newSelectData.every((y) => y?.id !== x?.id)) {
          newSelectData.push(x);
        }
      }
    });
    return newSelectData;
  };

  const setSelectedAllChecked = (newData = [...data], text = query) => {
    selectAllBySearch && text != ""
      ? setIsSelectAllChecked(
          newData
            .filter((x) => {
              return (
                !x.disabled &&
                x.label.toLocaleLowerCase().includes(text.toLocaleLowerCase())
              );
            })
            .every((x) => x.checked)
        )
      : setIsSelectAllChecked(
          newData.filter((x) => !x.disabled).every((x) => x.checked)
        );
  };

  const clearAll = (event) => {
    const { id, checked } = event.target;
    let newData = [...data];
    newData.map((x) => (x.disabled ? x : (x.checked = false)));
    setDatas(event, newData, []);
  };
  const handleSelectedItemRemove = (event, item) => {
    let newData = [...data];
    newData.find((x) => x.id == item.id).checked = false;

    setDatas(event, newData);
  };

  const handleCloseMenu = (
    event,
    reason,
    newData = data,
    selectData = selectedData
  ) => {
    if (inputSelect) {
      if (newData.find((x) => x.checked)?.id == query) {
        !isUpdateEveryChange && update(newData, selectData);
      } else {
        let newData = [{ id: query, label: query, checked: true }];
        setDatas(event, newData);
      }
    } else {
      !isUpdateEveryChange && update(newData, selectData);
    }
    handleClose(event);
  };

  const updateEveryChange = (
    event,
    newData = data,
    selectData = selectedData
  ) => {
    isUpdateEveryChange && update(newData, selectData);
    closeMenuEveryDataChange &&
      handleCloseMenu(event, "updateEveryChange", newData, selectData);
  };

  const selectAllByQuery = (newData, checked = true) => {
    newData.map((x) => {
      if (
        !x.disabled &&
        x.label.toLocaleLowerCase().includes(query.toLocaleLowerCase())
      ) {
        x.checked = checked;
      }
      return x;
    });
    return newData;
  };

  const handleKeyDown = (event) => {
    event.stopPropagation();
    if (event.key === "Enter") {
      if (inputSelect) {
        let newData = [{ id: query, label: query, checked: true }];
        setDatas(event, newData);
        // clearAfterEnter && allSelectChangeByText('');
      }
      if (multiSelect) {
        let newData = [...data];
        newData = selectAllByQuery(newData);
        setDatas(event, newData);
        clearAfterEnter && allSelectChangeByText("");
      }
      if (singleSelect && false) {
        // single select için bir tane seçim yapılması gerekiyor. Şuanlık aktif değil
        let newData = [...data];
        newData.map((x) => {
          if (x.label.toLocaleLowerCase().includes(query.toLocaleLowerCase())) {
            x.checked = true;
          }
          return x;
        });
        setDatas(event, newData);
        clearAfterEnter && allSelectChangeByText("");
      }
    }
  };

  const FormLabel = ({ id, label, checked, disabled, i }) => {
    return (
      <FormControlLabel
        control={
          <>
            {multiSelect ? (
              <CheckboxButton
                id={id}
                checked={checked}
                disabled={disabled}
                onChange={handleFilterCheckedChange}
                sx={{
                  "&.MuiCheckbox-root": {
                    margin: "0 !important",
                    padding: "0 !important",
                  },
                }}
              />
            ) : null}
            {singleSelect ? (
              <RadioButton
                id={id}
                checked={checked}
                disabled={disabled}
                onChange={handleFilterRadioChange}
                sx={{ padding: "0 !important" }}
              />
            ) : null}
            {timeSelect ? (
              <RadioButton
                id={id}
                checked={checked}
                disabled={disabled}
                onChange={handleFilterRadioChange}
                sx={{ padding: "0 !important", display: "none" }}
              />
            ) : null}
          </>
        }
        label={label}
        disabled={disabled}
        className={styles.checkboxItems}
        labelPlacement={"end"}
        key={i}
        sx={{
          ".MuiFormControlLabel-label": {
            fontSize: "12px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            width: "220px",
            ...(timeSelect && {
              color: checked ? "#FD581F" : "#6D809F",
              fontWeight: "600",
            }),
          },
        }}
      />
    );
  };

  const getLabelByParent = () => {
    let newData = [];
    Object.entries(groupedByQueue).map(([prent, dta]) => {
      let showParent = dta.some((x) => {
        return (
          x[parent] == prent &&
          x.label.toLocaleLowerCase().includes(query.toLocaleLowerCase())
        );
      });
      showParent &&
        newData.push(
          { id: "prent", label: prent },
          ...dta.filter((x) => {
            return x.label
              .toLocaleLowerCase()
              .includes(query.toLocaleLowerCase());
          })
        );
    });
    return renderLabel(newData);
  };

  const getLabel = (labelData) => {
    let newData = [...labelData];
    newData = newData.filter((fltr) =>
      fltr.label.toLocaleLowerCase().includes(query.toLocaleLowerCase())
    );
    return renderLabel(newData);
  };

  const renderLabel = (labelData) => {
    return virtualized ? (
      <div style={{ height: timeSelect ? "205px" : "168px" }}>
        <AutoSizer>
          {({ height, width }) => (
            <List
              height={height}
              width={width}
              itemSize={(index) => (labelData.length - 1 === index ? 20 : 28)}
              itemData={labelData}
              itemCount={labelData.length}
            >
              {({ data, index, style }) => (
                <div style={style}>
                  {data[index].id == "prent" ? (
                    <div
                      style={{
                        fontSize: "12px",
                        height: "20px",
                        color: "#032983",
                      }}
                    >
                      {data[index].label}
                    </div>
                  ) : (
                    <FormLabel
                      id={data[index].id}
                      label={data[index].label}
                      checked={data[index].checked}
                      disabled={data[index].disabled}
                      i={index}
                    />
                  )}
                </div>
              )}
            </List>
          )}
        </AutoSizer>
      </div>
    ) : (
      newData.map(({ id, label, checked, disabled }, i) => (
        <FormLabel
          id={id}
          label={label}
          checked={checked}
          disabled={disabled}
          i={i}
        />
      ))
    );
  };

  const agentsTheme = createTheme({
    components: {
      MuiMenu: {
        styleOverrides: {
          paper: {
            border: `1px solid ${colors.border}`,
            borderRadius: "8px !important",
            boxShadow: `0px 20px 40px ${colors.boxShadow}`,
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            paddingTop: "0px",
            paddingBottom: "0px",
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            padding: "11px 24px",
            color: colors.infoTableHeader,
            fontWeight: 500,
            lineHeight: "24px",
            "& svg": {
              marginRight: "6px",
            },
          },
        },
      },
      MuiFormGroup: {
        styleOverrides: {
          root: {
            padding: "6px 16px 0px 16px",
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            margin: "0",
            padding: "0",
            gap: "12px",
          },
        },
      },
    },
  });

  const dateTheme = createTheme({
    components: {
      MuiPickerStaticWrapper: {
        styleOverrides: {
          root: {
            padding: 0,
            margin: "0 16px",
            width: "100% !important",
          },
          content: {
            minWidth: "100%",
          },
        },
      },
      MuiPickersCalendarHeader: {
        styleOverrides: {
          root: {
            marginTop: 0,
            paddingLeft: "2px",
            paddingRight: 0,
          },
          label: {
            fontSize: "14px",
            fontWeight: 600,
            color: "#314469",
          },
        },
      },
      MuiPickersArrowSwitcher: {
        styleOverrides: {
          spacer: {
            display: "none",
          },
        },
      },
      PrivatePickersSlideTransition: {
        styleOverrides: {
          root: {
            minHeight: "190px !important",
          },
        },
      },
      MuiPickersDay: {
        styleOverrides: {
          root: {
            margin: 0,
            height: "28px",
            width: "28px",
            color: "#6D809F !important",
            "&.Mui-selected": {
              color: "#FFFFFF !important",
              backgroundColor: "#FD581F !important",
            },
            "&:hover": {
              color: "#FFFFFF !important",
              backgroundColor: "#FD581F !important",
              opacity: 0.5,
            },
            "&.Mui-disabled": {
              color: "#CCDCE6 !important",
            },
            "&.MuiPickersDay-dayOutsideMonth": {
              color: "#CCDCE6 !important",
            },
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            "&.Mui-disabled": {
              opacity: 0.25,
            },
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            width: "0.8em",
            fill: "#314469",
          },
        },
      },
      MuiDayPicker: {
        styleOverrides: {
          slideTransition: {
            minHeight: "230px",
          },
          weekDayLabel: {
            color: "#314469",
            fontWeight: 500,
          },
        },
      },
      MuiCalendarPicker: {
        styleOverrides: {
          root: {
            padding: 0,
            margin: 0,
            width: "185px",
            "&.Mui-selected": {
              backgroundColor: "#FD581F",
            },
          },
        },
      },
    },
  });

  let groupedByQueue = data.reduce((result, agent) => {
    if (agent[parent]) {
      result[agent[parent]] = result[agent[parent]] || [];
      result[agent[parent]].push(agent);
    }
    return result;
  }, {});

  const getSelectedDate = () => {
    let date = data.find((x) => x.checked)?.id;
    return date ? dayjs(date).format("DD MMMM") : "-";
  };

  return (
    <ThemeProvider theme={agentsTheme}>
      <Box className={styles.popupMenu}>
        <Menu
          open={open}
          onClose={handleCloseMenu}
          transformOrigin={{
            vertical: -4,
            horizontal: 11,
          }}
          anchorEl={anchorEl}
        >
          <Box>
            {/* do not remove this box due to mui bug. adding tabindex to first elem */}
          </Box>
          <Box className={styles.title}>{title}</Box>
          <Box className={styles.closeAction} onClick={handleCloseMenu}>
            <DeleteFilterIcon />
          </Box>
          <Box
            className={styles.searchWrapper}
            sx={{ ...(dateSelect && { width: "185px" }) }}
          >
            {searchable ? (
              <OutlinedInput
                autoFocus={autoFocus}
                onChange={(ev) => {
                  onTextChanged(ev);
                }}
                onKeyPress={handleKeyDown}
                autoComplete="off"
                className={styles.item}
                type={searchType ?? "text"}
                size="xsmall"
                value={query}
                label={"Search"}
                InputLabelProps={{
                  style: {
                    color: "#D8DCEB",
                    fontWeight: 600,
                    fontSize: "12px",
                    top: "-2px",
                  },
                  size: "small",
                }}
                name="search"
                variant="outlined"
              />
            ) : null}
            {dateSelect ? (
              <Box sx={{ fontSize: "20px", color: "#FD581F" }}>
                {getSelectedDate()}
              </Box>
            ) : null}
          </Box>

          {dateSelect ? (
            <Box sx={{ width: "215px" }}>
              <ThemeProvider theme={dateTheme}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <StaticDatePicker
                    displayStaticWrapperAs="desktop"
                    views={["day"]}
                    openTo="day"
                    outsideCurrentMonth
                    showDaysOutsideCurrentMonth
                    disableFuture
                    disableHighlightToday
                    leftArrowButtonText="Previous Month"
                    rightArrowButtonText="Next Month"
                    minDate={minDate}
                    maxDate={maxDate}
                    value={
                      data.find(({ checked, disabled }) => !disabled && checked)
                        ?.id ?? null
                    }
                    onChange={(newValue) => {
                      setDatas(null, [
                        {
                          id: dayjs(newValue).format(),
                          label: dayjs(newValue).format(dateFormat),
                          checked: true,
                        },
                      ]);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </ThemeProvider>
            </Box>
          ) : null}

          {multiSelect || singleSelect || timeSelect ? (
            <Box sx={{ height: multiSelect || timeSelect ? "205px" : "168px" }}>
              {isLoading ? (
                <Box className={styles.loadingBox}>
                  <CircularProgress sx={{ margin: "auto" }} />
                </Box>
              ) : data && data.length > 0 ? (
                <FormGroup sx={{ padding: "0 16px", gap: "8px" }}>
                  {multiSelect ? (
                    <>
                      <FormControlLabel
                        control={
                          <>
                            <CheckboxButton
                              id="select_all"
                              name="Select All"
                              sx={{
                                "&.MuiCheckbox-root": {
                                  margin: "0 !important",
                                  padding: "0 !important",
                                },
                              }}
                              checked={isSelectAllChecked}
                              onChange={handleFilterCheckedChange}
                            />
                          </>
                        }
                        label={
                          selectAllBySearch && query != ""
                            ? "Filtered Select All"
                            : "Select All"
                        }
                        className={styles.selectAllWrapper}
                        labelPlacement={"end"}
                        key="select_all"
                        sx={{
                          ".MuiFormControlLabel-label": {
                            fontWeight: "bold",
                            fontSize: "12px",
                          },
                        }}
                      />
                      <Divider
                        className={styles.divider}
                        orientation="horizontal"
                        flexItem
                      ></Divider>
                    </>
                  ) : null}
                  <Box
                    className={styles.checkButtonsAreaWrapper}
                    sx={{ ...(timeSelect && { maxHeight: "195px" }) }}
                  >
                    {parent ? getLabelByParent() : null}
                    {!parent ? getLabel(data) : null}
                  </Box>
                </FormGroup>
              ) : (
                <Box className={styles.noResults}>No results found</Box>
              )}
            </Box>
          ) : null}
          {multiSelect || singleSelect ? (
            <Box className={styles.checkBoxSelectedDataArea}>
              {(getData ? selectedData : data).some(
                ({ checked, disabled }) => !disabled && checked
              )
                ? [
                    <Box key={"selectedBox"}>
                      <Box
                        key={"selectedBoxChecked"}
                        className={styles.selectedCheckbox}
                      >
                        <Box className={styles.selectValues}>
                          <Box>
                            {(getData ? selectedData : data)
                              .filter(
                                ({ checked, disabled }) => !disabled && checked
                              )
                              .length.toString()}
                          </Box>
                          <Box className={styles.selectedValue}>Selected</Box>
                        </Box>
                        <Box
                          className={styles.clearAll}
                          onClick={(event) => clearAll(event)}
                        >
                          Clear All
                        </Box>
                      </Box>
                    </Box>,
                  ]
                : [
                    <Box
                      key={"nothingSelected"}
                      className={styles.nothingSelected}
                    >
                      Nothing Selected
                    </Box>,
                  ]}
              <Box className={styles.boxWrapperForSelecteds}>
                {(getData ? selectedData : data)
                  .filter(({ checked, disabled }) => !disabled && checked)
                  .slice(0, 3)
                  .map((item, i) => {
                    return (
                      <Box
                        key={i}
                        className={styles.boxWrapperForSelectedsItem}
                      >
                        <span className={styles.selectedCheckboxItemText}>
                          {item.label}{" "}
                        </span>
                        <span
                          onClick={(e) => handleSelectedItemRemove(e, item)}
                          style={{
                            cursor: "pointer",
                            height: "16px !important",
                            width: "25px",
                            height: "18px",
                            marginLeft: "5px",
                          }}
                        >
                          <DeleteFilterIcon />
                        </span>
                      </Box>
                    );
                  })}
              </Box>
              {(getData ? selectedData : data).filter(
                ({ checked, disabled }) => !disabled && checked
              ).length > 3 ? (
                <Box className={styles.multiSelectedShowText}>
                  {(getData ? selectedData : data)
                    .filter(({ checked, disabled }) => !disabled && checked)
                    .length.toString()}
                </Box>
              ) : null}
            </Box>
          ) : null}
        </Menu>
      </Box>
    </ThemeProvider>
  );
});

export function FilterContainer({
  children,
  customId = "customId",
  filterGap,
  rightMenuButton,
  relative,
  containerStyle,
  filterMenuStyle,
}) {
  if (!Array.isArray(children)) return children;
  let gap = filterGap ?? 10;
  const [isHiddenMenuButton, setIsHiddenMenuButton] = useState(false);

  useEffect(() => {
    let filterCss = {};
    let containerWidth = 0;
    let menuWidth = 0;
    const observer = new ResizeObserver((entries) => {
      entries.forEach((entry, index) => {
        let id = entry.target.id;
        if (id === `container-${customId}`) {
          containerWidth = entry.contentRect.width;
        } else if (id === `menu-${customId}`) {
          menuWidth = entry.contentRect.width;
        } else {
          if (id.startsWith("filter")) {
            if (entry.contentRect.height !== 0) {
              filterCss[id] = entry.contentRect.width;
            }
          }
          if (id.startsWith("menu-filter")) {
            id = id.replace("menu-", "");
            if (entry.contentRect.height !== 0) {
              filterCss[id] = entry.contentRect.width;
            }
          }
        }
      });

      const changeDisplay = (id, display) => {
        let disDisplay = display == "block" ? "none" : "block";
        let element = document.getElementById(id);
        let menuElement = document.getElementById(`menu-${id}`);
        if (element) element.style.display = display;
        if (menuElement) menuElement.style.display = disDisplay;
      };

      let accWidth = 10;
      let isVisible = true;
      Object.keys(filterCss).forEach(function (id, i) {
        let filterWidth = filterCss[id];

        if (isVisible && accWidth + filterWidth + i * gap < containerWidth) {
          if (!Object.keys(filterCss)[i + 1]) {
            accWidth += filterWidth;
            changeDisplay(id, "block");
            setIsHiddenMenuButton(true);
          } else {
            if (
              isVisible &&
              accWidth + filterWidth + (i + 1) * gap + menuWidth <
                containerWidth
            ) {
              accWidth += filterWidth;
              changeDisplay(id, "block");
              setIsHiddenMenuButton(false);
            } else {
              isVisible = false;
              changeDisplay(id, "none");
            }
          }
        } else {
          isVisible = false;
          changeDisplay(id, "none");
        }
      });
    });

    observer.observe(document.getElementById(`container-${customId}`));
    children.forEach((x, i) => {
      observer.observe(document.getElementById(`filter-${customId}-${i}`));
    });
    observer.observe(document.getElementById(`menu-${customId}`));
    children.forEach((x, i) => {
      observer.observe(document.getElementById(`menu-filter-${customId}-${i}`));
    });
    return () => {
      observer.disconnect();
    };
  }, []);

  const handleOpenMenu = () => {
    document.getElementById(`menu-${customId}`).classList.toggle("open");
  };

  return (
    <div
      id={`container-${customId}`}
      style={{
        flex: 1,
        display: "flex",
        width: "auto",
        flexWrap: "wrap",
        position: relative ? "relative" : "unset",
        ...containerStyle,
      }}
    >
      {children.map((child, i) => {
        return (
          <div id={`filter-${customId}-${i}`} key={i}>
            {child}
          </div>
        );
      })}
      {rightMenuButton && (
        <div
          style={{ marginLeft: "auto", width: 0 }}
          hidden={isHiddenMenuButton}
        ></div>
      )}
      <div
        id={`menu-${customId}`}
        className={styles.dropdown}
        hidden={isHiddenMenuButton}
      >
        <button onClick={handleOpenMenu}>Filter</button>
        <nav style={filterMenuStyle}>
          {children.map((child, i) => {
            return (
              <div id={`menu-filter-${customId}-${i}`} key={i}>
                {child}
              </div>
            );
          })}
        </nav>
      </div>
    </div>
  );
}

/*
  icon: "agent", // icon string olarak eşleşecek. Komponent içerisinden stringe eşleşen icon renderlanacak. Yoksa eklenmelidir.<AgentsIcon />

  selectedIcon: "selectedAgent", // selectedIcon string olarak eşleşecek. Komponent içerisinden stringe eşleşen icon renderlanacak. Yoksa eklenmelidir.<AgentsIcon />

  multiSelect: true, // Default: false - Çoklu seçim yapılması içindir.

  singleSelect: false, // Default: false - Tekli seçim yapılması içindir

  inputSelect: false // Default: false - Yazılan yazının filtrelebilmesi içindir. 

  timeSelect: false, // Default: false - Saat seçimi yapılması içindir. options: [] veya [{id: "00:00:00", label: "00:00:00", checked: true}]

  dateSelect: false, // Default: false - Tarih seçimi yapılması içindir. options: [] veya [{id: dayjs(date).format(), label: dayjs(date).format(dateFormat), checked: true}]

  dateFormat: // optional. Örnek: 'YYYY-MM-DD' - Kullanılan sayfada dayjs kullanım zorunluluğunu kaldırmak için eklenmiştir. options içindeki label'da ilgili formatta döner. Api'ye uygun formatı hazırlamak için kullanılabilir.

  minDate: // optional. Örnek: diğer filterinin id'si verilebilir.
  
  maxDate: // optional. Örnek: diğer filterinin id'si verilebilir.

  maxWidth: // optional. Default "250px" görünen filter Chip'in genişliğini limitlemek için. Belirtilen limitin üzerindeki boyutlardaki yazılar için ... gösterimi default olarak çalışıyor.

  searchType: // optional. Default "text". Search alanınında type girilmesi içindir. Örnek: sadece numara girilmesi gereken alana "number" type girilerek farklı tipde karakter yazılması engellenebilir.  

  searchable: true, // Default: true - Search yapılması içindir.

  title: Agents, // Menu başlığını yazmak içindir.

  options: agentDatas, // Required - Array içerisinde object yapısı için uygundur. [{}, {}]. Object içerisinde id ve label bilgisi zorunludur. checked, disabled optional. Parent özelliği kullanılacak ise parent propunun value'su buradaki object'in içinde key değeri olarak bulunmalıdır. Olmadığı senaryoda Others parent değerinde toplanır.

  update: updateFunc, // Required - İki parametre alıyor. Filtrelenmiş data bu fonksiyon aracılığıyle alınır. İkinci paremetre selected datayı döndürür. Options ile selected datada aynı veri olmama durumunda kullanılması önerilir ve doğru çalışması için selectedOptions propsu ile verinin tekrardan filtreye gönderilmesi tavsiye edilir.

  enableDeleteAction: true, // Default - true, Seçilmiş data varsa menuyü açan butonda select'i silmek için X şeklinde buton çıkar.

  isUpdateEveryChange: false, // Default: false - true ise filter sayfasındaki her işlem sonrası düzenlenmiş data'yı geri döndürür. false ise menu kapandığında düzenlenmiş data'yı geri döndürür.

  closeMenuEveryDataChange: false, // Default: false - true ise, filter sayfası her data değişim sonrası kapanacak. singleSelect ile beraber kullanılması önerilir.

  autoFocus: true, // Default: true - true ise menu her açıldığında search'e focuslanır.

  clearAfterEnter: false, // Default: false - searchde entera bastıktan sonra input temizlenir.

  selectAllBySearch: true, // Default: true - True ise search'e göre filtered select all seçeneği çıkar.

  virtualized: true, // Default: true - react-window kullanılıyor.

  parent: undefined, // Default: undefined - yazılan text, dikkat edilecek key'i verir ve ilgili keyin değeri parent olarak gösterilir.

  updateSelected: updateSelectedFunc, // Aktif Değil selectedOptions'ın güncel değerini almak için kullanılmalıdır.

  getData: getAgentData, // searche bağlı istek atılması gerektiğinde kullanılmalıdır. selectedOptions kullanımı zorunludur. 

  selectedOptions: agentSelectedDatas // optionslar ve selected olanların aynı veri olmadığı durumlarda kullanılması tavsiye edilir. getData kullanımında tavsiye edilir.
*/
