//-------------------------------------------------------------------------------
// Copyright © 2022 Valven. - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and Confidential
//-------------------------------------------------------------------------------

import React from 'react'
import Select from '@mui/material/Select';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { Box, Chip } from '@mui/material'
import MenuItem from '@mui/material/MenuItem';
import CheckboxButton from './CheckboxButton';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CancelIcon from '@mui/icons-material/CloseOutlined'
import { DefaultTooltip } from "@alotech/common/components/tooltip";
import { StyledTooltip } from '../tooltip';
import { useSelector } from 'react-redux';
import styles from './CustomSelect.module.scss';
import colors from '../../styles/_export.scss';
import { strings } from '../i18n'

import { ReactComponent as SelectArrow } from '../../static/img/icons8-select-arrow.svg'
import { ReactComponent as SelectArrowDarkMode } from '../../static/img/icons8-select-arrow-darkMode.svg'
import { ReactComponent as InfoIcon } from '../../static/img/common/info.svg'

export default function CustomSelect(props) {

    const {setSelectedUserName, fieldRef, margin, handleDelete, info, infoPosition, startAdornment, endAdornment, minHeightSelect, ...other } = props

    const [selected, setSelected] = React.useState(props.value ?? (props.multiple ? [] : ""));

    let infoIcon = info ? <StyledTooltip title={info} position={infoPosition} arrow>
        <InfoIcon className={styles.info} width="20px" height="20px" />
    </StyledTooltip> : null;

    React.useEffect(() => {
        setSelected(props.value)
    }, [props.value])

    const darkModeState = useSelector(state => state.darkMode);
    const darkMode = darkModeState.darkMode;

    const handleChipDelete = (chipToDelete) => {
        setSelected(selected.filter((chip) => chip != chipToDelete))
        if (handleDelete)
            handleDelete(chipToDelete)
    };

    const theme = createTheme({
        components: {
            MuiSelect: {
                styleOverrides: {
                    root: {
                        '&.Mui-focused .MuiOutlinedInput-root': {
                            color: darkMode ? '#98A5B7' : colors.selectBorder,
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: darkMode ? '#98A5B7' : colors.selectBorder,
                            borderWidth: '2px',
                        },
                        '&.Mui-focused [aria-expanded=true] ~ .MuiOutlinedInput-notchedOutline': {
                            borderBottomRightRadius: 0,
                            borderBottomLeftRadius: 0,
                        },
                        '&.Mui-focused .MuiSelect-select': {
                            color: darkMode ? '#98A5B7' : colors.selectBorder,
                            fontWeight: 600,
                            backgroundColor: darkMode ? '#0E2344' : 'white'
                        },
                    },
                    icon: {
                        marginRight: '13px',
                        marginTop: '3px',
                    },
                    select: {
                        color: darkMode ? '#98A5B7' : colors.selectBorder,
                        borderColor: darkMode ? '#2D4059' : "",
                        paddingTop: '9px',
                        paddingBottom: '9px',
                        paddingRight: '45px !important',
                        '&.MuiInputBase-inputSizeSmall': {
                            display: other.isPopupSelect ? 'block':'flex',
                            alignItems: 'center',
                            gap: '4px',
                            paddingTop: '4px',
                            paddingBottom: '4px',
                            paddingLeft: '8px',
                            fontWeight: '600'
                        },
                        "& .MuiSvgIcon-root": {
                            color: darkMode ? '#98A5B7' : '',
                        },
                    }
                }
            },
            MuiFormControl: {
                styleOverrides: {
                    root: {
                        margin: props.inputmargin ? props.inputmargin : "0",
                        '& .MuiInputLabel-sizeSmall': {
                            fontSize: '12px'
                        },
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        height: props.inputheight ? props.inputheight : "auto",
                        minHeight: minHeightSelect || '50px',
                        borderRadius: '6px',
                        '&.Mui-disabled': {
                            backgroundColor: colors.buttonSecondaryBg,
                            color: colors.borderField,
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: `1px solid ${colors.borderField}`,
                                color: colors.borderField
                            }
                        },
                        '& img': {
                            width: '18px',
                            height: '18px',
                            color: darkMode ? '#98A5B7' : ""
                        },
                        '&.MuiInputBase-sizeSmall': {
                            minHeight: '32px'
                        },
                        '& .MuiSelect-select.MuiInputBase-inputSizeSmall': {
                            paddingTop: '6px',
                            paddingBottom: '6px',
                            fontSize: '12px'
                        },
                        '&.MuiInputBase-adornedStart': {
                            paddingLeft: '0px'
                        }
                    },
                }
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        color: darkMode ? '#98A5B7' : colors.primaryMain,
                        paddingRight: infoIcon ? '55px' : '25px',
                        '&.Mui-focused': {
                            color: darkMode ? '#98A5B7' : colors.primaryMain,
                        }
                    },
                }
            },
            MuiChip: {
                styleOverrides: {
                    root: {
                        color: darkMode ? '#98A5B7' : colors.chipColor,
                    },
                    outlined: {
                        border: darkMode ? '1px solid #0E2344' : `1px solid ${colors.chipBorder}`,
                        backgroundColor: darkMode ? '#0E2344' : colors.chipBg
                    },
                    label: {
                        marginBottom: '0px !important',
                        paddingLeft: '22px',
                        paddingRight: '12px'
                    },
                    deleteIcon: {
                        margin: '0px 20px 0px 0px'
                    }
                }
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        fontWeight: '500',
                        paddingRight: '0px',
                        fontSize: '12px',
                        color: darkMode ? '#98A5B7' : "",
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        display: other.isPopupSelect ? 'block': 'flex'
                    }
                }
            },
            MuiList: {
                styleOverrides: {
                    root: {
                        border: darkMode ? '2px solid #98A5B7' : `2px solid ${colors.selectBorder}`,
                        backgroundColor: darkMode ? '#0E2344' : ""
                        // backgroundColor: darkMode ? '#2D4059' : ""
                    }
                }
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        marginTop: "-3px",
                        width: other.isPopupSelect ? '200px': 'unset'
                    }
                }
            }
        }
    });
    let id = "select-label-" + (parseInt(Math.random() * 10000)) + "-" + (other.id ?? other.name)

    const clickMenuItem = (e) => {
        if(setSelectedUserName !== undefined){
            setSelectedUserName(e.target.id);
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <FormControl fullWidth={other.fullWidth} size={other.size ?? "medium"} className={other.className}>
                {other.label ? <InputLabel id={id}>{other.label + (other.required ? ' *' : '')}</InputLabel> : null}
                <Select {...other}
                        labelId={id}
                        inputRef={fieldRef}
                        defaultValue={props.multiple ? [] : ""}
                        onChange={(ev) => {
                            const value = ev.target.value
                            if (value[value.length - 1] === "all") {
                                setSelected(selected?.length === props.choices.length ? [] : props.choices.map(x => x.value));
                            } else {
                                setSelected(ev.target.value)
                            }
                            other.onChange && other.onChange(ev)
                        }}
                        disabled={props.disabled}
                        size={other.size ?? "medium"}
                        value={selected ? selected : ""}
                        renderValue={(selected) => {
                            if (!selected || selected.length == 0) {
                                return infoIcon;
                            }
                            if (props.multiple) {
                                return <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((key) => {
                                        const choice = props.choices.find(c => c.value === key);
                                        return (
                                            <Chip key={key} label={choice.label}
                                                  onMouseDown={(event) => {
                                                      event.stopPropagation();
                                                  }}
                                                  onDelete={(ev) => {
                                                      handleChipDelete(key)
                                                  }}
                                                  deleteIcon={<CancelIcon className={styles.cancelIcon} onMouseDown={(event) => event.stopPropagation()} />}
                                                  variant="outlined" />
                                        )
                                    })}
                                    {infoIcon}
                                </Box>
                            } else {
                                const choice = props.choices && props.choices.find(c => c.value === selected);
                                return <>
                                    {startAdornment ?? null}
                                    <span>{choice && choice.label}</span>
                                    {infoIcon}
                                </>;

                            }
                        }}
                        IconComponent={darkMode ? SelectArrowDarkMode : SelectArrow}
                >
                    {props.multiple &&
                        <MenuItem value="all">
                            <CheckboxButton
                                checked={selected?.length == props.choices.length}
                                indeterminate={selected?.length > 0 && selected?.length < props.choices.length} />
                            {strings.select_all}
                        </MenuItem>
                    }
                    {props.choices && props.choices.map((choice, i) => {
                        return (
                            other.isPopupSelect
                            ?
                            <MenuItem onClick={clickMenuItem} id={choice.label} key={i} value={choice.value}>
                                {
                                    props.multiple && <CheckboxButton checked={selected?.indexOf(choice.value) > -1} />
                                }
                                {props.avatar && <img src={choice.url} alt={choice.label} className={styles.optionImg} />}
                                <DefaultTooltip title={choice.label || ""}>
                                    <div style={{width:"100%"}}>{choice.label}</div>
                                </DefaultTooltip>
                            </MenuItem>
                            :
                            <MenuItem onClick={clickMenuItem} id={choice.label} key={i} value={choice.value}>
                                {
                                    props.multiple && <CheckboxButton checked={selected?.indexOf(choice.value) > -1} />
                                }
                                {props.avatar && <img src={choice.url} alt={choice.label} className={styles.optionImg} />}
                                {choice.label}
                            </MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
        </ThemeProvider >
    )
}
