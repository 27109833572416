import React from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import { ReactComponent as CloseIcon } from "@alotech/common/static/img/close.svg";
import CustomizedButton from "../form/CustomizedButton";

function CustomModal({
  open,
  setOpen,
  title,
  icon,
  submitClick,
  handleClose,
  content,
  buttonText1,
  buttonText2,
}) {
  return (
    <Dialog
      onClose={handleClose}
      open={open}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "520px",
          borderRadius: "12px",
          backgroundColor: "#FFFFFF",
          position: "relative",
          border: "1px solid #CCDCE6",
          boxShadow: "0px 8px 16px #00000014",
        }}
      >
        <Box
          onClick={() => setOpen(false)}
          sx={{
            position: "absolute",
            right: "16px",
            top: "16px",
            cursor: "pointer",
          }}
        >
          <CloseIcon width="24px" height="24px" />
        </Box>
        <Box
          sx={{
            color: "#F02833",
            fontSize: "20px",
            fontWeight: "600",
            display: "flex",
            marginTop: "24px",
            marginLeft: "24px",
            gap: "8px",
          }}
        >
          {icon && icon}
          <span>{title}</span>
        </Box>
        <Box
          sx={{
            color: "#6D809F",
            fontSize: "14px",
            marginLeft: "24px",
            marginTop: "7px",
            marginRight: "24px",
          }}
        >
          {content}
        </Box>
        <Box sx={{ marginLeft: "auto", marginY: "24px" }}>
          <CustomizedButton
            key="reset"
            variant="outlined"
            type="reset"
            color={"rgb(30, 55, 114)"}
            backgroundColor={"#EAECF567"}
            width={120}
            height={40}
            fontSize={12}
            text={buttonText1}
            borderColor={"rgb(216, 220, 235)"}
            borderRadius={4}
            fontWeight={600}
            onClick={handleClose}
            hoverBorderColor={"rgb(216, 220, 235)"}
          />
          <CustomizedButton
            key="submit"
            variant="outlined"
            onClick={() => submitClick()}
            type="submit"
            width={120}
            height={40}
            backgroundColor={"#F02833"}
            color={"#fff"}
            fontSize={12}
            text={buttonText2}
            hoverBgColor={"#F02833"}
            borderRadius={4}
            marginRight={24}
            marginLeft={8}
            fontWeight={600}
          />
        </Box>
      </Box>
    </Dialog>
  );
}

export default CustomModal;
