//-------------------------------------------------------------------------------
// Copyright © 2022 Valven. - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and Confidential
//-------------------------------------------------------------------------------

import React, { useLayoutEffect, useState } from 'react'
import { Outlet, useNavigate } from "react-router-dom";
import { Grid } from '@mui/material';
import styles from './LoginLayout.module.scss'

import { Box, Container } from '@mui/material';
import whatsapp_logo from "../../static/img/whatsapp3.png";
import whatsapp_logoen from "../../static/img/whatsappen.png";
import { isEN, isTR } from '../../utils/Utils';

const loginTitles = [{
    "title": "Skills-Based Routing",
    "title2": "",
    "subtitle": "Routes to the best fit agent in your call center based on their skills so your customer gets the best support available.",
},{
    "title": "Advanced Transfer Options",
    "title2": "",
    "subtitle": "Agents can transfer calls to the relevant person with a simple click. Callers get their issues resolved faster.",
},{
    "title": "Quality Control",
    "title2": "",
    "subtitle": "Access call, chat and satisfaction survey records, track calls and voicemails and more.",
},{
    "title": "Agent Supervision",
    "title2": "",
    "subtitle": "See the real-time status of your agent team, including names, status and duration of calls.",
},{
    "title": "Integrations",
    "title2": "",
    "subtitle": "Connects with a wide range of other software, including payment systems, CRM, WhatsApp, Facebook and more.",
},{
    "title": "Self-Service Functions",
    "title2": "",
    "subtitle": "Customers can perform simple tasks without an agent, freeing up the queue and reducing wait times.",
},{
    "title": "CRM Integration",
    "title2": "",
    "subtitle": "Make your contact center more functional! Access your contact information on a dashboard, see dial and interaction history, save notes and close leads faster.",
},{
    "title": "Help Desk Integration",
    "title2": "",
    "subtitle": "Integrate into your web-based help desk tools, so your agents can focus more on their conversations and work more efficiently.",
},{
    "title": "Scheduled Callback",
    "title2": "",
    "subtitle": "Automatically call back missed calls to improve close rate, productivity and customer satisfaction.",
},{
    "title": "Multi-Channel Experience",
    "title2": "",
    "subtitle": "Interact with your customers via multiple channels, including Whatsapp, live chat, mobile app and phone calls.",
},{
    "title": "Sales Evaluations",
    "title2": "",
    "subtitle": "Listen to call audio recordings with one click, for easier sales team evaluations.",
},{
    "title": "Outbound Call Support",
    "title2": "",
    "subtitle": "Helps and guides your agents in communicating with the customer, to improve and standardise the interaction.",
}
]

export const LoginLayout = (props) => {
    const navigate = useNavigate();
    const [isCSS, setIsCSS] = useState(false);
    const [shownTitle, setShownTitle] = useState(loginTitles[0]);

    useLayoutEffect(()=> {
        let tenant = window.location.href;
        if(tenant.includes("callcenterstudio.com")){
            setIsCSS(true);
        }
        setShownTitle(loginTitles[Math.floor(Math.random() * loginTitles.length)])
    }, []);

    const right = {
        "title": "Skills-Based Routing",
        "title2": "",
        "subtitle": "Routes to the best fit agent in your call center based on their skills so your customer gets the best support available.",
    }

    const left = {
        "title": null,
        "subtitle": "Welcome to next-generation solution center platform.",
        "back": true
    }


    function SendWhatsapp() {
        window.open(
            "https://api.whatsapp.com/send?phone=908502000256&text=&source=&data=",
            "_blank"
        );
    }

    if (props.contains === "login") {
        left.title = "Login";
        left.back = false;
    } else if (props.contains === "signup") {
        left.title = "Sign Up";
        left.subtitle = "After sign up, your account needs to be activated by your administrator";
    } else if (props.contains === "reset-password") {
        left.title = "Password Reset";
        left.subtitle = "Send reset instructions";
    } else if (props.contains === "new-password") {
        left.title = "New Password";
        left.back = false;
    } else if (props.contains == "renew-password") {
        left.title = "Password Renewal"
    }

    return <>
        <Grid container spacing={0} columns={12} style={{ height: "100%" }}>
            <Grid sx={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between", overflow: "auto" }} item xs={0} md={6}>
                <Box className={styles.LoginTemplate}>
                    <Container className={styles.LoginTemplateContainer} maxWidth={false}>
                        <Box onClick={()=> navigate("/")} className={isCSS ? styles.LoginTemplate_Icon : styles.LoginTemplate_Alotech_Icon} />
                        <Box className={styles.LoginTemplate_Children}>
                            {left.back &&
                                <Box className={styles.LoginTemplate_Back} onClick={() => navigate("/")} />
                            }
                            <Box className={styles.LoginTemplate_Title}>
                                {left.back &&
                                    <Box className={styles.LoginTemplate_BackTitle} onClick={() => navigate("/")} />
                                }
                                {left.title}
                            </Box>
                            <Box className={styles.LoginTemplate_SubTitle}>{left.subtitle}</Box>

                            <Outlet />
                        </Box>
                    </Container>
                </Box>
                {props.contains === "signup" && (isEN()
                    ? <div className={styles.Recaptcha_Text} >This site is protected by reCAPTCHA and the Google <a style={{ color: "blue" }} href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and <a style={{ color: "blue" }} href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.</div>
                    : <div className={styles.Recaptcha_Text} >Bu site reCAPTCHA tarafından korunmaktadır ve Google <a style={{ color: "blue" }} href="https://policies.google.com/privacy" target="_blank">Gizlilik Politikası</a> ile <a style={{ color: "blue" }} href="https://policies.google.com/terms" target="_blank">Hizmet Şartları</a> geçerlidir.</div>)
                }
            </Grid>
            <Grid item xs={0} md={6}>
                <Box className={styles.LoginSidebar}>
                    <Box className={styles.LoginSidebar_Title}>{shownTitle && shownTitle.title}</Box>
                    <Box className={styles.LoginSidebar_Title2}></Box>
                    <Box className={styles.LoginSidebar_SubTitle}>{shownTitle && shownTitle.subtitle}</Box>
                </Box>
            </Grid>
        </Grid>
        {!(window.location.href.includes("callcenterstudio.com")) &&
        <div
            style={{
                position: "fixed",
                bottom: "19px",
                right: 20,
            }}
        >
            <img
                style={{
                    width: "188.7px",
                    height: "75px",
                    cursor: "pointer",
    
                }}
                onClick={() => {
                    SendWhatsapp();
                }}
                alt="whatsapp"
                src={isTR() ? whatsapp_logo : whatsapp_logoen}
            ></img>
        </div>}
    </>;
};

export default LoginLayout;