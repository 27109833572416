import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import { styled } from "@mui/material/styles";
import classes from "./EnhancedTable.module.scss";
import Menu from "@mui/material/Menu";
import Checkbox from "@mui/material/Checkbox";
import { DefaultTooltip } from "@alotech/common/components/tooltip";
import {
  DataGridPro,
  gridPageCountSelector,
  gridPageSelector,
  gridPageSizeSelector,
  useGridApiContext,
  useGridSelector,
  useGridApiRef,
  GridRowEditStopReasons,
} from "@mui/x-data-grid-pro";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import MenuItem from "@mui/material/MenuItem";
import { GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid-pro";
import Tooltip from "@mui/material/Tooltip";
import colors from "../../styles/_export.scss";
import { useResizeDetector } from "react-resize-detector";
import { isMobile } from "@alotech/common/utils/Utils";
//SVG
import { ReactComponent as DescendingIcon } from "../../static/img/descending.svg";
import { ReactComponent as AscendingIcon } from "../../static/img/ascending.svg";
import { ReactComponent as InfoIcon } from "../../static/img/info.svg";
import { ReactComponent as BusyDot } from "../../static/img/dot_busy_voice.svg";
import { ReactComponent as OnlineDot } from "../../static/img/voice_online.svg";
import { ReactComponent as PaginationPerIcon } from "../../static/img/pagination_select_arrow.svg";
import { ReactComponent as ArrowBackIcon } from "../../static/img/icons8-expand-arrow_4.svg";
import { ReactComponent as ArrowForwardIcon } from "../../static/img/icons8-expand-arrow_5.svg";
import { ReactComponent as Check } from "../../static/img/check.svg";
import { ReactComponent as PrimaryChecked } from "@alotech/common/static/img/primary_checked.svg";
import { ReactComponent as DeleteIcon } from "../../static/img/delete_24.svg";
import { ReactComponent as ClockIcon } from "../../static/img/clock_icon.svg";
import { ReactComponent as DateIcon } from "../../static/img/date_icon.svg";
import { ReactComponent as CallIcon } from "../../static/img/call.svg";
import { ReactComponent as VideoCallIcon } from "../../static/img/video-call.svg";
import { ReactComponent as SuccessfullIcon } from "../../static/img/ok.svg";
import { ReactComponent as CancelIcon } from "../../static/img/cancel.svg";
import { ReactComponent as ProblemIcon } from "../../static/img/problem.svg";
import { ReactComponent as DangerArrowIcon } from "../../static/img/danger-arrow.svg";
import {getStatusColorForVoiceStatus} from '@alotech/common/utils/Utils';
import { ReactComponent as OutbountIcon } from '../../static/img/voice_outbound.svg';
import { ReactComponent as InbountIcon } from '../../static/img/voice_coming-outbound.svg';
import { ReactComponent as HoldOutbondIcon } from '../../static/img/hold_outbound.svg';
import { ReactComponent as HoldInbountIcon } from '../../static/img/hold_coming-outbound.svg';
import { ReactComponent as LimitIcon } from '../../static/img/manual_limit.svg';
import { ReactComponent as BusylIcon } from '../../static/img/manual_busy2.svg';
import { ReactComponent as BusySIcon } from '../../static/img/sys_busy.svg';
import dayjs from "dayjs";

function EnhancedTableHead(props) {
  const { headCells, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align || "left"}
            width={headCell.width}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              border: "none",
              padding: "9px 20px",
              height: "60px",
              whiteSpace: "nowrap",
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              hideSortIcon={
                headCell.label && headCell.sortable !== false ? false : true
              }
              IconComponent={DescendingIcon}
            >
              <span className={classes.cellHeader}>{headCell.label}</span>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
              {headCell.info && (
                <Tooltip title={headCell.info} placement="top" arrow>
                  <InfoIcon
                    style={{ marginLeft: "8px" }}
                    width={22}
                    height={22}
                  />
                </Tooltip>
              )}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export function EnhancedTablePro(props) {
  const {
    headers,
    tablePagination,
    rows,
    rowCount,
    page,
    showDetail,
    loading,
    pageSize,
    rowsPerPageOptions,
    darkRowColor,
    checkboxSelection,
    noBorder,
    noPagination,
    onDeleteSelected,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
    onRowEditCommit,
    groupingColDef,
    getTreeDataPath,
    isTree,
    onRowClick,
    paginationMode,
    sortingMode,
    pinnedRows,
    noRowsImage,
    noRowsHeader,
    noRowsText,
    onClickSelectedRows,
    disableVirtualization,
    onSelectionModelChange,
    showSelectionString,
    showTotal,
    getDetailPanelHeight,
    getDetailPanelContent,
    customRowHeight,
    smallPagination,
    fontSize,
    fontWeight,
    checkboxSize,
    paddingSize,
    rowPaddingLeftSize,
    paddingSizeHeader,
    adminTable,
    sx,
    handleOnCellClick,
    enableColumnReorder,
    onColumnOrderChange,
  } = props;
  const [pinnedColumns, setPinnedColumns] = React.useState([]);

  const [sortModel, setSortModel] = React.useState([]);

  const [selectedRows, setSelectedRows] = React.useState([]);

  const [actionsAnchorEl, setActionsAnchorEl] = React.useState(null);
  const actionsOpen = Boolean(actionsAnchorEl);

  const [isValid, setIsValid] = React.useState(true);
  const [editingRowId, setEditingRowId] = React.useState(null);
  const [editingRowAnchorEl, setEditingRowAnchorEl] = React.useState(null);

  const onMouseEnteredCell = (cellValues) => {};

  const onMouseLeaveCell = () => {};

  headers.map((x) => {
    if (x["id"] || x["label"]) {
      x["field"] = x.id;
      x["headerName"] = x.label;
    }
  });

  const apiRef = useGridApiRef();
  useEffect(() => {
    for (let i = 0; i < headers.length; i++) {
      let x = headers[i];
      if (x["id"] || x["label"]) {
        x["field"] = x.id;
        x["headerName"] = x.label;
      }
      if (x.type == "action") {
        x["renderCell"] = (cellValues) => {
          return (
            <>
              <button
                key={cellValues.id}
                data-id={cellValues.id}
                onClick={handleClickActions}
                className={classes.Grid_Action_Button}
                type="button"
              >
                <div></div>
              </button>
            </>
          );
        };
      } else if (x.type == "actionNotHover") {
        x["renderCell"] = (cellValues) => {
          return (
            <>
              <button
                onMouseEnter={() => onMouseEnteredCell(cellValues)}
                onMouseLeave={() => onMouseLeaveCell()}
                key={cellValues.id}
                data-id={cellValues.id}
                onClick={cellValues.id ==="pinned-bottom" ? "" : handleClickActions}
                className={cellValues.id ==="pinned-bottom" ? "" : classes.Grid_Action_Button_Not_Hover}
                type="button"
              >
                <div></div>
              </button>
            </>
          );
        };
      } else if (x.type == "dateWithIcons") {
        x["renderCell"] = (cellValues) => {
          return (
            <>
              <div style={{ display: "flex" }} align="left">
                {cellValues.row[x.id] ? (
                  <>
                    <div style={{ marginRight: "15px", display: "flex" }}>
                      <div style={{ marginRight: "4px", marginTop: "1px" }}>
                        <DateIcon />
                      </div>
                      <div>{dayjs(cellValues.row[x.id], { utc: true }).format("YYYY-MM-DD")}</div>
                    </div>
                    {cellValues.row[x.id].split("T")[1] && (
                      <div style={{ display: "flex" }}>
                        <div style={{ marginRight: "4px", marginTop: "1px" }}>
                          <ClockIcon />
                        </div>
                        <div>{dayjs(cellValues.row[x.id], { utc: true }).format("HH:mm:ss")}</div>
                      </div>
                    )}
                  </>
                ) : (
                  <>-</>
                )}
              </div>
            </>
          );
        };
      } else if (x.type == "statusCell") {
        x["renderCell"] = (cellValues) => {
            let style = getStatusColorForVoiceStatus(cellValues.row[x.id]);
            let text = cellValues.row[x.id] === "" ? strings.unavailable : cellValues.row[x.id];
            return (
                text ?
                    <>
                        <div className={classes.voiceStatusCellType} style={{ cursor: cellValues.row.agentactivechatcount && cellValues.row.agentactivechatcount > 0 ? "pointer" : "default", ...style}}>
                            <DefaultTooltip spanStyle={{ width: "100%", display: "block" }} title={text}><span style={{ display: "block" }} className={classes.voiceStatusCellTypeText}> {text} </span></DefaultTooltip>
                            {text === "talking" || text === "hold" ?
                                cellValues.row.call_direction == "outbound" || cellValues.row.call_direction == "out" || cellValues.row.inbound === false ?
                                    text === "hold" ? <HoldOutbondIcon className={classes.outbountIcon} /> : <OutbountIcon className={classes.outbountIcon}/>
                                    : text === "hold" ? <HoldInbountIcon className={classes.outbountIcon} /> : <InbountIcon className={classes.outbountIcon}/>
                                : null
                            }
                            {
                                text === "chatting" && cellValues.row.agentactivechatcount && cellValues.row.agentactivechatcount > 0 ?
                                    <span style={{ marginLeft: "3px" }}>{`(${cellValues.row.agentactivechatcount})`}</span>
                                    :
                                    null
                            }
                        </div>
                        <div className={classes.statusTypes}>
                            {cellValues.row.statuslimit ?  <DefaultTooltip title={"{{_('Status Limit')}}"}><div>
                                <LimitIcon className={classes.billIcon} />
                            </div></DefaultTooltip>: null}
                            {cellValues.row.statusbusy == 1 ?
                                cellValues.row.statusbusy_agentclick ?
                                    <DefaultTooltip title={"{{_('Agent Chat Busy')}}"}>
                                    <div>
                                        <BusylIcon className={classes.busyIcon} />
                                    </div>
                                    </DefaultTooltip>
                                    :
                                    <DefaultTooltip title={"{{_('System Chat Busy')}}"}>
                                    <div>
                                        <BusySIcon className={classes.busySystemIcon} />
                                    </div>
                                    </DefaultTooltip>
                                : null}
                        </div>
                    </> : null
            )
        }
       } else if (x.type == "downloadResult") {
        x["renderCell"] = (cellValues) => {
          return (
            <>
              <div
                align="left"
                onClick={() => {
                  if (x.callback) x.callback(cellValues);
                }}
                disabled={cellValues.row[x.id] == "Success" || cellValues.row[x.id] == "Cancelled" || cellValues.row[x.id] == "Failed" ? false : true}
                style={
                  cellValues.row[x.id] !== "Success" ||  cellValues.row[x.id] !== "Cancelled" || cellValues.row[x.id] !== "Failed"
                    ? { cursor: "pointer" }
                    : {}
                }
              >
                {cellValues.row[x.id].split(",")[0] ? (
                  <>
                    <div style={{ marginRight: "15px", display: "flex" }}>
                      <div
                        style={{
                          marginRight: "8px",
                          width: "14px",
                          height: "14px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginTop: "-1px",
                        }}
                      >
                        {cellValues.row[x.id] == "Success" ? (
                          <SuccessfullIcon />
                        ) : cellValues.row[x.id] == "Cancelled" ? (
                          <CancelIcon />
                        ) : cellValues.row[x.id] == "Failed" ? (
                          <CancelIcon />
                        ) : cellValues.row[x.id].length > 0 ? (
                          <ProblemIcon />
                        ) : null}
                      </div>
                      <div
                        style={
                          cellValues.row[x.id] == "Success"
                            ? { color: "#6D809F" }
                            : cellValues.row[x.id] == "Cancelled"  || cellValues.row[x.id] == "Failed"
                            ? { color: "#F02833" }
                            : { color: "#F02833" }
                        }
                      >
                        {cellValues.row[x.id].split(",")[0]}
                      </div>
                      <div
                        style={{
                          marginRight: "8px",
                          width: "14px",
                          height: "14px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {cellValues.row[x.id] !== "Success" &&
                        cellValues.row[x.id] !== "Cancelled"  &&
                        cellValues.row[x.id] !== "Failed" 
                         ? (
                          <DangerArrowIcon />
                        ) : null}
                      </div>
                    </div>
                  </>
                ) : (
                  <>-</>
                )}
              </div>
            </>
          );
        };
      } else if (x.type == "recordType") {
        x["renderCell"] = (cellValues) => {
          return (
            <>
              <div style={{ display: "flex" }} align="left">
                {cellValues.row[x.id] ? (
                  <>
                    <div style={{ marginRight: "15px", display: "flex" }}>
                      <div
                        style={{
                          marginRight: "10px",
                          width: "14px",
                          height: "14px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {cellValues.row[x.id] == "CALL" ? (
                          <CallIcon />
                        ) : (
                          <VideoCallIcon />
                        )}
                      </div>
                      <div>{cellValues.row[x.id]}</div>
                    </div>
                  </>
                ) : (
                  <>-</>
                )}
              </div>
            </>
          );
        };
      } else if (x.type == "stringWithNoneLine") {
        x["renderCell"] = (cellValues) => {
            return (
                <>
                    <div style={{ display: "flex" }} align="left">
                        {
                            cellValues.row[x.id] ? 
                                <>
                                    <div style={{ marginRight: "15px", display: "flex" }} >
                                        {cellValues.row[x.id]}
                                    </div> 
                                </> :
                                <>
                                    -
                                </>
                        }
                    </div>
                </>
            )
        }
    } 
      if (x.preProcessEditCellProps == null && x.preProcessEditCell) {
        let func = x.preProcessEditCell;
        x["preProcessEditCellProps"] = (params) => {
          let val = func(params);
          setIsValid(!val.error);
          return val;
        };
      }
    }
    let pinnedHeaders = headers.filter((x) => x.pin);
    setPinnedColumns(
      pinnedHeaders.length
        ? [GRID_CHECKBOX_SELECTION_COL_DEF.field].concat(
            pinnedHeaders.map((x) => x.field)
          )
        : []
    );
    apiRef.current.updateColumns(headers);
  }, [headers]);

  const theme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          root: {
            border: "none",
            backgroundColor: "",
          },
          columnHeaders: {
            border: "none !important",
            paddingTop: "0px",
            paddingRight: paddingSizeHeader ?? "10px",
            paddingLeft: paddingSizeHeader ?? "10px",
            paddingBottom: "0px",
          },
          columnHeader: {
            color: colors.infoTableHeader,
            fontSize: fontSize ?? "13px",
            fontWeight: 500,
            outline: "none !important",
          },
          pinnedColumnHeaders: {
            marginLeft: "0px",
            padding: "0px 1px",
            paddingLeft: "18px",
            boxShadow: isTree ? "none" : "-",
            borderRadius: adminTable ? "12px 0 0" : "unset",
          },
          pinnedColumns: {
            paddingLeft: "16px",
            boxShadow: isTree ? "none" : "-",
            "& .MuiDataGrid-row:hover, .MuiDataGrid-row.Mui-hovered": {
              width: "calc(100% + 1px)",
              borderTopRightRadius: "0px",
              borderBottomRightRadius: "0px",
            },
          },
          pinnedRows: {
            marginBottom: '10px',
            marginTop: '20px !important' ,
            '&:hover, &.Mui-hovered, &.Mui-selected': "none",
            '& .MuiDataGrid-row': {
                width: 'calc(100% - 30px)',
                color: '#002983 !important',
                backgroundColor:  '#F1FBFE !important',
                border:'1px solid #AFDDEA !important',
            },
            '& .MuiDataGrid-cell': {
                color:'#002983 !important',
                fontWeight:  '500 !important',
            }
        },
          virtualScroller: {
            paddingTop: "0px",
            paddingRight: paddingSize ?? "10px",
            paddingLeft:
              pinnedColumns && pinnedColumns.length
                ? "0px"
                : paddingSize ?? "10px",
            paddingBottom: "0px",
            "& .MuiDataGrid-detailPanel": {
              width: "calc(100% + 12px)",
              backgroundColor: "#F1FBFE",
              border: "1px solid #AFDDEA",
              borderTop: "none !important",
              borderRadius: "8px",
              borderTopLeftRadius: "0",
              borderTopRightRadius: "0",
              padding: "0 7px 0 16px",
            },
          },
          columnSeparator: {
            display: "none",
          },
          columnHeaderCheckbox: {
            "& .MuiCheckbox-root": {
              paddingRight: "10px",
              "& svg": {
                width: checkboxSize ? "16px" : "",
                height: checkboxSize ? "16px" : "",
              },
            },
          },
          cellCheckbox: {
            "& .MuiCheckbox-root": {
              paddingRight: "10px",
              "& svg": {
                width: checkboxSize ? "16px" : "",
                height: checkboxSize ? "16px" : "",
              },
            },
          },
          cell: {
            color: !showTotal && colors.text,
            fontSize: fontSize ?? "13px",
            fontWeight: fontWeight ?? 500,
            outline: "none !important",
            border: "none !important",
            "&.MuiDataGrid-cell--editing": {
              boxShadow: "none",
              backgroundColor: "initial",
            },
          },
          row: {
            color: showTotal && colors.text,
            borderRadius: "10px",
            width: "calc(100% - 2px)",
            paddingLeft: rowPaddingLeftSize ?? "",
            "&:hover, &.Mui-hovered, &.MuiDataGrid-row--detailPanelExpanded": {
              backgroundColor:
                !showTotal && `${colors.infoTableHoverBg} !important`,
              color: !showTotal && `${colors.blue} !important`,
              border: `1px solid ${colors.infoTableHoverBorder} !important`,
              "& button": {
                display: "inline-flex",
                // 'div': {
                //     backgroundImage: `url(${DotIcon})`,
                // }
              },
            },
            "&.MuiDataGrid-row--detailPanelExpanded": {
              borderBottomRightRadius: "0",
              borderBottomLeftRadius: "0",
              borderBottom: "none !important",
            },
            "&:nth-of-type(odd)": {
              backgroundColor: darkRowColor || colors.dataTableRowDark,
              border: `1px solid ${darkRowColor || colors.dataTableRowDark}`,
              "&.MuiDataGrid-row--editing": {
                backgroundColor: darkRowColor || colors.dataTableRowDeepDark,
              },
            },
            "&:nth-of-type(even)": {
              backgroundColor: colors.white,
              border: `1px solid ${colors.white}`,
            },
            "&.MuiDataGrid-row--editing": {
              boxShadow: "none",
              "& .MuiDataGrid-cell": {
                backgroundColor: "initial",
              },
            },
          },
          footerContainer: {
            border: "none",
            paddingTop: smallPagination ? "0px !important" : "21px",
            paddingBottom: smallPagination ? "0px !important" : "21px",
            borderTop: `1px solid ${colors.outlineBorder}`,
            minHeight: smallPagination ? "10px !important" : "52px",
            height: smallPagination ? "27px !important" : "",
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            border: `1px solid ${colors.border}`,
            borderRadius: "8px !important",
            boxShadow: `0px 20px 40px ${colors.boxShadow}`,
          },
        },
      },
      MuiFormGroup: {
        styleOverrides: {
          root: {
            padding: "8px 24px 0px 24px",
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            padding: "2px 0px",
          },
          labelPlacementEnd: {
            padding: "12px 0px",
            marginLeft: "0px",
            "&:first-of-type": {
              paddingTop: "16px",
            },
            "& .MuiFormControlLabel-label": {
              height: "26px",
              display: "flex",
              alignItems: "center",
              color: colors.infoTableHeader,
              fontWeight: "400 !important",
              "& svg": {
                marginRight: "8px",
              },
            },
          },
        },
      },

      MuiCheckbox: {
        styleOverrides: {
          colorPrimary: {
            color: colors.borderField,
            "&.Mui-checked": {
              color: "#1DBE79",
            },
          },
        },
      },
      MuiCircularProgress: {
        styleOverrides: {
          root: {
            zIndex: "999",
          },
        },
      },
    },
  });

  if (pinnedColumns && pinnedColumns.length) {
    theme["components"]["MuiDataGrid"]["styleOverrides"][
      "virtualScrollerRenderZone"
    ] = {
      "&": {
        paddingLeft: "16px",
      },
      "& .MuiDataGrid-row": {
        "&:hover, &.Mui-hovered": {
          borderTopLeftRadius: "0",
          borderBottomLeftRadius: "0",
        },
      },
    };
  }

  const handleClickActions = (event) => {
    setActionsAnchorEl(event.currentTarget);
  };

  const handleActionsClose = () => {
    setActionsAnchorEl(null);
  };

  const handleSelectionChanged = (selectionModel, details) => {
    setSelectedRows(selectionModel);
    if (onSelectionModelChange) {
      onSelectionModelChange(selectionModel, details);
    }
  };

  const handleDeleteClick = () => {
    onDeleteSelected(selectedRows);
    setSelectedRows([]);
  };

  const handleRowCommit = (id) => {
    if (isValid) {
      apiRef.current.stopRowEditMode({ id });
      onRowEditCommit(apiRef.current.getRow(id));
      setEditingRowAnchorEl(null);
      setEditingRowId(null);
    }
  };

  const handleRowEditStart = (params, event) => {
    if (editingRowId) {
      event.defaultMuiPrevented = true;
    } else {
      setEditingRowId(params.id);
      setEditingRowAnchorEl(event.currentTarget.parentElement);
    }
  };

  const handleRowEditStop = (params, event, details) => {
    if (
      params.reason === GridRowEditStopReasons.rowFocusOut ||
      params.reason === GridRowEditStopReasons.tabKeyDown ||
      params.reason === GridRowEditStopReasons.shiftTabKeyDown
    ) {
      event.defaultMuiPrevented = true;
    } else if (params.reason === GridRowEditStopReasons.escapeKeyDown) {
      setEditingRowAnchorEl(null);
      setEditingRowId(null);
    } else if (params.reason === GridRowEditStopReasons.enterKeyDown) {
      event.defaultMuiPrevented = true;
      handleRowCommit(params.id);
    }
  };

  const handleSortModelChange = (newModel) => {
    setSortModel(newModel);
    if (onSortModelChange) onSortModelChange(newModel);
  };

  const handleOnRowClick = (params) => {
    if (onRowClick) onRowClick(params);
  };
  const [hoverActionMenu, setHoverActionMenu] = React.useState("");

  const onMouseEnteredRow = (row, x) => {
    setHoverActionMenu(row.label);
  };

  const onMouseLeaveRow = () => {
    setHoverActionMenu("");
  };

  return (
    <Box
      sx={{ width: "100%", height: "100%" }}
      className={classes.EnhancedTable_Box}
      style={{
        backgroundColor: "white",
        borderRadius: "12px",
        ...(!noBorder &&
          !noPagination && {
            border: `1px solid ${colors.outlineBorder}`,
            boxShadow: `0px 1px 1px ${colors.boxShadow}`,
          }),
      }}
    >
      <Paper
        sx={{
          width: "100%",
          height: "100%",
          borderRadius: "12px",
          mb: 2,
          ...((noBorder == true || noPagination == true) && {
            boxShadow: "none",
          }),
        }}
      >
        <ThemeProvider theme={theme}>
          {selectedRows.length != 0 && !showSelectionString && (
            <Box className={classes.Grid_Selection_Bar}>
              <Box className={classes.Grid_Selection_String}>
                {selectedRows.length} Selected
              </Box>
              <Box
                className={`${classes.Grid_Selection_Toolbar_Button} ${classes.delete}`}
                onClick={handleDeleteClick}
              >
                <DeleteIcon />
                <span>Delete</span>
              </Box>
            </Box>
          )}
          <DataGridPro
            disableColumnReorder={enableColumnReorder ? false : true}
            onColumnOrderChange={onColumnOrderChange}
            disableVirtualization={disableVirtualization}
            onCellClick={handleOnCellClick}
            treeData={isTree}
            loading={loading}
            columns={headers}
            rows={rows}
            rowHeight={customRowHeight || 60}
            checkboxSelection={checkboxSelection}
            pageSize={pageSize || 20}
            page={page}
            rowsPerPageOptions={rowsPerPageOptions}
            keepNonExistentRowsSelected
            disableSelectionOnClick
            disableColumnMenu
            disableColumnResize
            apiRef={apiRef}
            editMode="row"
            hideFooterSelectedRowCount={true}
            hideFooter={noPagination}
            sortingMode={sortingMode}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            pagination={!noPagination}
            paginationMode={paginationMode}
            rowThreshold={0}
            getDetailPanelContent={showDetail ? getDetailPanelContent : null}
            getDetailPanelHeight={showDetail ? getDetailPanelHeight : null}
            onPageChange={onPageChange}
            rowCount={rowCount}
            onSelectionModelChange={handleSelectionChanged}
            selectionModel={onClickSelectedRows || selectedRows}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            pinnedRows={pinnedRows}
            experimentalFeatures={{ rowPinning: true, newEditingApi: true }}
            components={{
              BaseCheckbox: CheckboxWrapper,
              NoRowsOverlay: NoRowsComponent,
              NoResultsOverlay: NoRowsComponent,
              ColumnSortedAscendingIcon: AscendingIcon,
              ColumnSortedDescendingIcon: DescendingIcon,
              BooleanCellTrueIcon: BooleanCellTrueIcon,
              BooleanCellFalseIcon: BooleanCellFalseIcon,
              Pagination: EnhancedTablePagination,
            }}
            componentsProps={{
              pagination: {
                count: rowCount,
                rowsPerPageOptions: rowsPerPageOptions,
                handlePageSizeChange: onPageSizeChange,
                tablePagination: tablePagination,
                smallPagination: smallPagination,
              },
              noRowsOverlay: {
                noRowsImage,
                noRowsHeader,
                noRowsText,
              },
              noResultsOverlay: {
                noRowsImage,
                noRowsHeader,
                noRowsText,
              },
            }}
            pinnedColumns={{
              left: pinnedColumns,
            }}
            getTreeDataPath={getTreeDataPath}
            groupingColDef={groupingColDef}
            disableChildrenSorting={false}
            defaultGroupingExpansionDepth={-1}
            onRowClick={onRowClick}
            sx={sx}
          />
          {headers.find((x) => x.type == "action") && (
            <Menu
              sx={{
                ".MuiList-root": {
                  paddingTop: 0,
                  paddingBottom: 0,
                },
              }}
              className={classes.popupMenu}
              anchorEl={actionsAnchorEl}
              open={actionsOpen}
              onClose={handleActionsClose}
            >
              {headers
                .find((x) => x.type == "action")
                .actions.map((action, actionIndex) => {
                  return (
                    <MenuItem
                      className={classes.menuItem}
                      key={actionIndex}
                      sx={{ fontSize: "14px", color: "#314469" }}
                      onClick={() => {
                        if (action.callback)
                          action.callback(actionsAnchorEl.dataset.id);
                        setActionsAnchorEl(null);
                      }}
                    >
                      {" "}
                      {action.icon} {action.label}
                    </MenuItem>
                  );
                })}
            </Menu>
          )}
          {headers.find((x) => x.type == "actionNotHover") && (
            <Menu
              sx={{
                ".MuiList-root": {
                  paddingTop: 0,
                  paddingBottom: 0,
                },
              }}
              className={classes.popupMenu}
              anchorEl={actionsAnchorEl}
              open={actionsOpen}
              onClose={handleActionsClose}
            >
              <div>
                {headers
                  .find((x) => x.type == "actionNotHover")
                  .actions.map((action, actionIndex) => {
                    let sx = {};
                    let disable = false;
                    let disableTransfer = false;
                    if (action.checkTransferDisable && actionsAnchorEl) {
                      const currentRow = rows.find(
                        (element) => element.id == actionsAnchorEl.dataset.id
                      );
                      if (currentRow && currentRow.status != "chatting") {
                        disableTransfer = true;
                      } else if (!currentRow) {
                        setActionsAnchorEl(null);
                      }
                    }
                    return (
                      <MenuItem
                        onMouseEnter={() => onMouseEnteredRow(action)}
                        onMouseLeave={() => onMouseLeaveRow()}
                        {...((action.disabled ||
                          disableTransfer ||
                          disable) && { disabled: true })}
                        className={classes.menuItem}
                        key={actionIndex}
                        sx={{
                          fontSize: "12px",
                          color: "#314469",
                          fontWeight: 600,
                          ...sx,
                        }}
                        onClick={() => {
                          if (action.callback)
                            action.callback(actionsAnchorEl.dataset.id);
                          setActionsAnchorEl(null);
                        }}
                      >
                        {" "}
                        {hoverActionMenu === action.label
                          ? action.actionHoverIcon
                          : action.icon}{" "}
                        <Box className={classes.actionLabelNotHover}>
                          {action.label}
                        </Box>{" "}
                        {action.isBusy && (
                          <span className={classes.dotStyleNotHover}>
                            {action.isBusy === true ? (
                              <BusyDot width={8} height={8} />
                            ) : (
                              <OnlineDot width={8} height={8} />
                            )}
                          </span>
                        )}
                      </MenuItem>
                    );
                  })}
              </div>
            </Menu>
          )}
        </ThemeProvider>
      </Paper>
    </Box>
  );
}

function CheckboxWrapper(props) {
  const theme = createTheme({
    components: {
      MuiCheckbox: {
        styleOverrides: {
          root: {
            padding: "0 12px 0 0",
          },
          colorPrimary: {
            color: colors.borderField,
            "&.Mui-checked": {
              color: colors.checkedGreen,
            },
          },
          colorSecondary: {
            color: "#F02833",
            "&.Mui-checked": {
              color: "#F02833",
            },
          },
          colorInfo: {
            color: "#1271D9",
            "&.Mui-checked": {
              color: "#1271D9",
            },
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            height: "20px",
            width: "20px",
          },
        },
      },
    },
  });

  const PrimaryCheckboxBorder = styled("span")(({ theme }) => ({
    borderRadius: 2,
    width: 16,
    height: 16,
    border: `1px solid ${colors.borderField}`,
    backgroundColor: "white",
    "input:hover ~ &": {
      backgroundColor: colors.hover,
    },
    "input:disabled ~ &": {
      border: "none",
      background: colors.disabled,
    },
  }));

  return (
    <ThemeProvider theme={theme}>
      <Checkbox
        icon={<PrimaryCheckboxBorder />}
        checkedIcon={<PrimaryChecked />}
        {...props}
      />
    </ThemeProvider>
  );
}

function EnhancedTablePagination(props) {
  const { width } = useResizeDetector();
  const {
    rowsPerPageOptions,
    handlePageSizeChange,
    tablePagination,
    smallPagination,
  } = props;

  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);

  const theme = createTheme({
    components: {
      MuiPaginationItem: {
        styleOverrides: {
          icon: {
            height: smallPagination ? "8px !important" : "16px !important",
          },
          root: {
            MuiPaginationItem: {
              marginRight: smallPagination ? "-14px" : "",
            },
            color: colors.text,
            fontSize: smallPagination ? "12px" : "14px",
            marginRight: smallPagination ? "-14px" : "",
            "&.Mui-selected": {
              backgroundColor: "transparent",
              color: colors.primaryButton,
              fontWeight: 600,
              fontSize: smallPagination ? "12px" : "14px",
            },
            "&.Mui-disabled": {
              opacity: "1 !important",
              marginRight: smallPagination ? "-14px" : "",
            },
          },
          page: {
            paddingTop: smallPagination ? "4px" : "0",
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            border: "none",
            color: colors.blueHome,
            fontSize: smallPagination ? "12px" : "14px",
            fontWeight: "400",
            "&:before, &:after": {
              border: "none !important",
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            paddingRight: "12px !important",
            "&:focus": {
              backgroundColor: "transparent",
            },
          },
          icon: {
            top: "calc(50% - 4px)",
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontWeight: "500",
            fontSize: smallPagination ? "12px" : "14px",
            color: colors.infoTableHeader,
            "&.Mui-selected": {
              color: colors.primaryButton,
              backgroundColor: "transparent",
            },
            "&:hover, &.Mui-selected:hover": {
              backgroundColor: colors.infoTableHoverBg,
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: "8px",
            backgroundColor: "",
            color: "",
            border: "",
          },
        },
      },
    },
  });
  let rowsPerPageText =
    smallPagination && isMobile() ? "Row Per Page Small" : "Row Per Page";

  return (
    <ThemeProvider theme={theme}>
      <Box
        className={`${classes.paginationWrapper} ${
          smallPagination ? classes["paginationSmall"] : ""
        }`}
      >
        {handlePageSizeChange !== null && (
          <Box
            className={`${classes.paginationPageSize} ${
              width < 450 && !smallPagination
                ? classes["small"]
                : smallPagination
                ? classes["paginationSmall"]
                : ""
            }`}
          >
            {rowsPerPageText}
            <Select
              value={pageSize}
              onChange={handlePageSizeChange}
              variant="standard"
              IconComponent={PaginationPerIcon}
              sx={{ marginLeft: "10px" }}
            >
              {(rowsPerPageOptions || [10, 20, 50, 100]).map((x) => {
                return (
                  <MenuItem key={x} value={x}>
                    {x}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
        )}
        <Pagination
          renderItem={(item) => {
            if (tablePagination) {
              if (
                item.type === "next" ||
                item.type === "previous" ||
                item.selected
              ) {
                return (
                  <PaginationItem
                    components={{
                      previous: ArrowBackIcon,
                      next: ArrowForwardIcon,
                    }}
                    {...item}
                  />
                );
              }
            } else {
              return (
                <PaginationItem
                  components={{
                    previous: ArrowBackIcon,
                    next: ArrowForwardIcon,
                  }}
                  {...item}
                />
              );
            }
          }}
          page={page + 1}
          count={pageCount}
          onChange={(event, value) => apiRef.current.setPage(value - 1)}
        />
      </Box>
    </ThemeProvider>
  );
}

function BooleanCellTrueIcon() {
  return <Check />;
}

function BooleanCellFalseIcon() {
  return "-";
}

export function NoRowsComponent({ noRowsImage, noRowsHeader, noRowsText }) {
  return (
    <Box
      sx={{
        color: "#7084AC",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        mt: -3,
      }}
    >
      {noRowsImage || "No results found"}
      <Box sx={{ mt: -8, fontSize: "29px", fontWeight: 500 }}>
        <span>{noRowsHeader}</span>
        <span style={{ fontSize: "14px", fontWeight: 400 }}>{noRowsText}</span>
      </Box>
    </Box>
  );
}
