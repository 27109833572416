import React, { useState, useEffect } from "react";
import { Box, Divider, Modal } from "@mui/material";
import { ReactComponent as CloseIcon } from "@alotech/common/static/img/closeIcon.svg";
import { ReactComponent as CheckMark } from "@alotech/common/static/img/checkMark.svg";
import { ReactComponent as InfoIcon } from "@alotech/common/static/img/infoIconBlue.svg";
import RuleSelection from "./tabs/RuleSelection";
import CustomizedButton from "@alotech/common/components/form/CustomizedButton";
import DownloadIntervals from "./tabs/DownloadIntervals";
import axios from "axios";
import { getCookie } from "@alotech/common/utils/Utils";

export default function RuleDialog({
  open,
  setOpen,
  title,
  editMode,
  activeDownload,
  ftpProfile,
  setAlertSuccess,
  setAlertMessage,
  setAlertOpen,
  downloadRule,
  setSchedule
}) {
  const [currentTab, setCurrentTab] = useState(0);
  const [selectedRule, setSelectedRule] = useState("");
  const [selectedRuleData, setSelectedRuleData] = useState({});
  const [selectedIntervalType, setSelectedIntervalType] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);

  const getScheduleTime = (intervalType, date, time) => {
    const now = new Date();
    let scheduledDate = new Date(now.toISOString().split('T')[0] + 'T' + time);
    const [hours, minutes, seconds] = time.split(':').map(Number);
    scheduledDate.setHours(hours, minutes, seconds, 0);
    if (intervalType === 0) {
      if (scheduledDate <= now) {
        scheduledDate.setDate(scheduledDate.getDate() + 1);
      }
    } else {
      let targetDay = parseInt(date.value, 10);
      let currentDay = now.getDay();
      let daysToAdd = (targetDay - currentDay + 7) % 7;
      if (daysToAdd === 0 && scheduledDate <= now) {
        daysToAdd = 7;
      }
      scheduledDate.setDate(now.getDate() + daysToAdd);
    }
    return scheduledDate.toISOString().slice(0, 19).replace('T', ' ');
  }


  useEffect(() => {
    if (ftpProfile) {
      setSelectedRule(ftpProfile.profileName)
      setSelectedRuleData(ftpProfile)
    }
  }, [ftpProfile, open])

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };
  const tabs = ["Profile Selected", "Download Intervals"];

  useEffect(() => {
    if (open && editMode) {
    }
  }, [open, editMode]);

  const handleChangeTab = (newTab) => {
    if (currentTab == "0") {
      selectedRule && setCurrentTab(newTab);
    } else {
      setCurrentTab(newTab);
    }
  };

  const closeModal = () => {
    setCurrentTab(0);
    setSelectedRule(0);
    setSelectedRuleData({});
    setSelectedIntervalType(null);
    setSelectedTime(null);
    setSelectedDate(null);
    setOpen(false);
  };

  const onClickNext = () => {
    if (currentTab == "0" && !selectedRule) return;
    if (!(currentTab + 2 > tabs.length)) {
      setCurrentTab(currentTab + 1);
    } else {
      if (title === "Create Rule") {
        axios.post("/res/setSchedule/",
          {
            "request": {
              "id": ftpProfile.id,
              "operation": "INSERT",
              "interval": selectedIntervalType == 0 ? "DAILY" : "WEEKLY",
              "name": (Math.random() + 1).toString(36).substring(7),
              "month": 0,
              "week": 0,
              "weekday": 0,
              "day": selectedIntervalType == 0 ? 0 : selectedDate.value,
              "hour": selectedTime,
              "scheduled_date": getScheduleTime(selectedIntervalType, selectedDate, selectedTime),
              "next_scheduled_date": null,
              "repeat": 0,
              "status":"Active"
            }
          },
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
          }
        ).then((res) => {
          if (res.status === 200) {
            axios.post("/res/getSchedule/",
              {
                request: {
                  "id": ftpProfile.id,
                }
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "*",
                },
              }
            ).then((res) => {
              const { data } = res.data.response;
              setSchedule(data[0]);
            }).catch((err) => {
              console.log(err);
            } )
            setAlertSuccess(true);
            setAlertMessage("Rule Created Successfully");
            setAlertOpen(true);
            setOpen(false);
          }
        }).catch((err) => {
          setAlertSuccess(false);
          setAlertMessage("Rule Creation Failed");
          setAlertOpen(true);
        })
      }
      if (title === "Edit Rule") {
        axios.post("/res/setSchedule/",
          {
            "request": {
              "id": downloadRule.id,
              "operation": "UPDATE",
              "interval": selectedIntervalType == 0 ? "DAILY" : "WEEKLY",
              "name": (Math.random() + 1).toString(36).substring(7),
              "month": 0,
              "week": 0,
              "weekday": 0,
              "day": selectedIntervalType == 0 ? 0 : selectedDate.value,
              "hour": selectedTime,
              "scheduled_date": getScheduleTime(selectedIntervalType, selectedDate, selectedTime),
              "next_scheduled_date": null,
              "repeat": 0,
              "status":"Active"
            }
          },
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
          }
        ).then((res) => {
          if (res.status === 200) {
            axios.post("/res/getSchedule/",
              {
                request: {
                  "id": ftpProfile.id
                }
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "*",
                },
              }
            ).then((res) => {
              const { data } = res.data.response;
              setSchedule(data[0]);
            }).catch((err) => {
              console.log(err);
            } )
            setAlertSuccess(true);
            setAlertMessage("Rule Updated Successfully");
            setAlertOpen(true);
            setOpen(false);
          }
        }).catch((err) => {
          console.log(err);
          setAlertSuccess(false);
          setAlertMessage("Rule Updation Failed");
          setAlertOpen(true);
        })
      }
      closeModal();
    }
  };

  return (
    <Modal
      onClose={closeModal}
      open={open}
      disableAutoFocus={true}
      disableEnforceFocus
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#FFFFFF",
          width: "818px",
          height: "580px",
          borderRadius: "8px",
          ...style,
        }}
      >
        <Box
          sx={{
            display: "flex",
            borderBottom: "1px solid #CCDCE6",
            height: "68px",
            alignItems: "center",
            paddingLeft: "32px",
            justifyContent: "space-between",
            paddingRight: "32px",
          }}
        >
          <span style={{ color: "#002983", fontSize: "20px", fontWeight: 500 }}>
            {title}
          </span>
          <CloseIcon onClick={closeModal} style={{ cursor: "pointer" }} />
        </Box>
        <Box
          sx={{
            display: "flex",
            marginTop: "32px",
            gap: "16px",
            width: "100%",
            justifyContent: "center",
          }}
        >
          {tabs.map((element, i) => {
            let selected = currentTab == i;
            return (
              <React.Fragment key={i}>
                <Box
                  key={i}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "16px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleChangeTab(i)}
                >
                  <Box
                    sx={{
                      width: "40px",
                      height: "40px",
                      border: `1px solid ${selected || i < currentTab ? "#FD581F" : "#CCDCE6"
                        }`,
                      borderRadius: "50%",
                      color: selected ? "#fff" : "#6D809F",
                      fontSize: "12px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontWeight: 600,
                      backgroundColor: selected ? "#FD581FB3" : "#fff",
                    }}
                  >
                    {i < currentTab ? <CheckMark /> : i + 1}
                  </Box>
                  <Box
                    sx={{
                      color: selected ? "#FD581F" : "#6D809F",
                      fontSize: "12px",
                      fontWeight: 500,
                    }}
                  >
                    {element}
                  </Box>
                </Box>
                {i + 1 !== tabs.length && (
                  <Divider
                    sx={{
                      width: "36px",
                      alignSelf: "center",
                      borderColor: i < currentTab ? "#FD581F" : "#6D809F",
                      opacity: i < currentTab ? 1 : 0.08,
                    }}
                  />
                )}
              </React.Fragment>
            );
          })}
        </Box>
        <Box>
          {(() => {
            switch (currentTab) {
              case 0:
                return (
                  <RuleSelection
                    selectedRule={selectedRule}
                    selectedRuleData={selectedRuleData}
                  />
                );
              case 1:
                return (
                  <DownloadIntervals
                    selectedIntervalType={selectedIntervalType}
                    setSelectedIntervalType={setSelectedIntervalType}
                    selectedTime={selectedTime}
                    setSelectedTime={setSelectedTime}
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                  />
                );
            }
          })()}
        </Box>
        <Box
          sx={{
            display: "flex",
            marginTop: "32px",
            justifyContent:
              activeDownload && editMode ? "space-between" : "flex-end",
            paddingRight: "32px",
            paddingLeft: "32px",
          }}
        >
          {activeDownload && editMode && (
            <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
              <InfoIcon />
              <Box
                style={{
                  color: "#1171D9",
                  fontSize: "12px",
                  fontWeight: 500,
                  marginTop: "2px",
                }}
              >
                These changes will apply to the next download.
              </Box>
            </Box>
          )}
          <Box sx={{ gap: "8px", display: "flex" }}>
            <CustomizedButton
              onClick={closeModal}
              hoverBgColor={"#CCDCE629"}
              hoverBorderColor={"#CCDCE6"}
              text={"Cancel"}
              backgroundColor={"#CCDCE629"}
              borderColor={"#CCDCE6"}
              borderRadius={"4px"}
              fontWeight={"600"}
            />
            <CustomizedButton
              onClick={onClickNext}
              disabled={
                (currentTab == "0" && !selectedRule) ||
                (currentTab == "1" &&
                  (selectedIntervalType === null ||
                    (selectedIntervalType == "0" && !selectedTime) ||
                    (selectedIntervalType == "1" &&
                      (!selectedTime || !selectedDate?.value))))
              }
              hoverBgColor={"#FD581F"}
              hoverBorderColor={"#FD581F"}
              text={currentTab + 1 === tabs.length ? "Create" : "Next"}
              backgroundColor={"#FD581F"}
              borderColor={"#FD581F"}
              borderRadius={"4px"}
              fontWeight={"600"}
              color={"#FFFFFF"}
            />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
