import React from "react";
import { Box } from "@mui/material";

const Container = ({ children }) => {
  return (
    <Box
      height="100%"
      sx={{
        padding: "16px 0px",
      }}
    >
      {children}
    </Box>
  );
};

export default Container;
