import LocalizedStrings from 'react-localization';

export const strings = new LocalizedStrings({
    en: {
        add_contact: "Add Contact",
        back: "Back",
        submit: "Submit",
        login: "Login",
        call_search: "Type or paste a phone number",
        call_ended_top: "Ended",
        enter_phone: "Type an Internal number",
        never: "Never",
        inbound_call: "Inbound Call",
        outbound_call: "Outbound Call",
        hold: "Hold",
        notanswering: "Notanswering",
        call_active: "Talking",
        chat_active: "Chatting",
        calling: "You are calling now",
        speaking: "You are speaking now",
        call_line: 'Line {0}',
        active_tasks: "Active Tasks",
        add_number: "Add Number",
        call_ended: 'Ended: {0}',
        call_coming: 'Coming: {0}',
        call_hold: 'Hold: {0}',
        call_talking: 'Talking: {0}',
        call_calling: 'Calling: {0}',
        voice: "Voice",
        chat: "Chat",
        good_morning: "Good Morning",
        good_afternoon: "Good Afternoon",
        good_evening: "Good Evening",
        notifications: "Notifications",
        search_notifications: "Search Notifications",
        reply: "Reply",
        note: "Note",
        send: "Send",
        edit: "Edit",
        view: "View",
        stop: "Stop",
        duplicate: "Duplicate",
        chat_info: "Chat Info",
        chat_history: "Chat History",
        template_messages: "Template Messages",
        show: "Show",
        hide: "Hide",
        submit: "Submit",
        name: "Name",
        password: "Password",
        passwords_must_match: "Passwords must match",
        password_match_error: "Passwords do not match",
        dont_have_account: "Do not have an account?",
        sign_up: "Sign Up",
        email: "E-Mail",
        remember: "Remember",
        language: "Language",
        reset_password: "Reset Password",
        softphone: "Softphone",
        copy: "Copy",
        copy_link: "Copy Link",
        copy_ok: "Copied",
        softphone_connected: "Connected",
        softphone_problem: "Connection Problem",
        connection_unstable: "Your Internet Connection is unstable",
        connection_unstable_title: "Unstable Connection",
        connection_problem_title: "Connection Problem",
        strong_connection_title: "Strong Connection",
        ping: 'Ping: {0} ms',
        avg_ping: 'Average Ping: {0} ms',
        suggestions: "Suggestions",
        softphone_description: "You may experience latency of 250 ms or more. If the problem persists, check your connection and try to connect again.",
        softphone_suggestion1: "If you are using a wireless network, try moving closer to your modem.",
        softphone_suggestion2: "Avoid apps that heavily use your internet connection.",
        softphone_suggestion3: "Check your VPN, Firewall, Proxy settings.",
        open: "Open",
        close: "Close",
        no_active_call: "No Active Call",
        no_active_chat: "No Active Chat",
        no_active_video: "No Active Video",
        no_active_call_info: "Change your status to Ready to start receiving tasks.",
        send_message: "Send Message",
        message: "Message",
        to: "To",
        save: "Save",
        cancel: "Cancel",
        sender: "Sender",
        default: "Default",
        transfer: "Transfer",
        transfer_info: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Et pariatur esse inventore, nam distinctio voluptatibus.",
        delete_placeholder: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tempus, lorem vel blandit dignissim, nunc dolor faucibus augue, vel porta purus nisl id augue.",
        direct_number: "Direct Number",
        support_call_queue: "Support Call Queue",
        satisfaction_survey_result: "Satisfaction Survey Result",
        evaluate: "Evaluate",
        evaluate_results: "Evaluate Results",
        evaluating_score: "Evaluating Score",
        evaluating_user: "Evaluating User",
        call_result: "Call Result",
        date: "Date",
        objection_date: "Objection Date",
        objection_detail: "Objection Detail",
        objection_details: "Objection Details",
        objection_result: "Objection Result",
        objection_evaluation_date: "Objection Evaluation Date",
        objection_evaluating_user: "Objection Evaluating User",
        evaluation_description: "Evaluation Description",
        evaluated_username: "Evaluated Username",
        callback_date: "Callback Date",
        callback_user: "Callback User",
        callback_disposition: "Callback Disposition",
        call_type: "Call Type",
        previous_month: "Previous month",
        next_month: "Next month",
        queues: "Queues",
        agents: "Agents",
        users: "Users",
        contacts: "Contacts",
        ivr: "IVR",
        queue_settings: "Queue Settings",
        how_to_get_started: "How to get started",
        take_a_tour: "Take a tour",
        watch_a_video: "Watch a video",
        add_sub_label: "Add Sub Label",
        delete: "Delete",
        agent: "Agent",
        call: "Call",
        call_id: "Call ID",
        chat_id: "Chat ID",
        unique_id: "Unique ID",
        call_details: "Call Details",
        chat_details: "Chat Details",
        callback_details: "Callback Details",
        queue_adding: "Queue Adding",
        add_queue: "Add Queue",
        priority: "Priority",
        add_agent_to_queue: "Add Agent to Queue",
        queue_group: "Queue Group",
        search_messages: "Search Messages",
        delete_all_queues_from_agent: "Delete All Queues From Agent",
        queue: "Queue",
        delete: "Delete",
        wrapup_code: "Wrapup Code",
        wrapup_codes: "Wrapup Codes",
        black_list: "Black List",
        listen_to_recording: "Listen to Recording",
        play_voicemail: "Play Voicemail",
        play_callback: "Play Callback",
        download_voicemail: "Download Voicemail",
        download_callback: "Download Callback",
        information: "Information",
        select_wrapup_code: "Select Wrapup Code",
        please_select_wrapup_code: "Please write the wrapup code",
        change_workspaces: "Change Workspaces",
        sign_out: "Sign out of all accounts",
        change_password: "Change Password",
        new_password: "New Password",
        repeat_new_password: "Repeat New Password",
        change: "Change",
        change_status: "Change Status",
        reserve_status: "Reserve Status",
        select_status: "Select Status",
        select_reserve_status: "Select Reserve Status",
        current_status: "Current Status",
        status_duration: "Status Duration",
        supervisor_password: "Supervisor Password",
        cancel: "Cancel",
        change_password_info: " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eu lorem ut nisi suscipit aliquet.",
        left_column: "Left Column",
        middle_column: "Middle Column",
        right_column: "Right Column",
        saved: 'Saved {0}',
        add_ticket: "Add Ticket",
        home: "Home",
        advanced_settings: "Advanced Settings",
        system_administrator: "System Administrator",
        search_settings: "Search Settings",
        available: "Available",
        shortbreak: "Short Break",
        unavailable: "Unavailable",
        active: "Active",
        passive: "Passive",
        create: "Create",
        delete: "Delete",
        preview: "Preview",
        upload_file: "Upload File",
        you_can_drag_and_drop_your_image_here: "You can drag and drop your image here.",
        queue_team_settings: "Queue Team Settings",
        search_key: "Arama Planı Key",
        search_name: "Arama Planı Adı",
        search_rule_name: "Arama Kuralı Adı",
        calling_number: "Arayan Numara",
        number_to_call: "Aranacak Numara",
        passage: "Geçit",
        enable_backup_dial_plan: "Yedek Arama Planını Etkinleştir",
        backup_dial_plan: "Yedek Arama Planı",
        refresh: "Refresh",
        add: "Add",
        prefix: "Ön Ek",
        count_from_left: "Soldan Say",
        ringing_time: "Çaldırma Süresi",
        unique_caller_number: "Tekil Arayan Numara",
        multiple_caller_number: "Çoklu Arayan Numara",
        add_profile: "Add Profile",
        applyChanges: "Apply Changes",
        add: "Add",
        refresh: "Refresh",
        data: "Data",
        contact: "Contact",
        start: "Start",
        campaign_settings: "Campaign Settings",
        call_rules: "Call Rules",
        finish_codes: "Finish Codes",
        contact_notifications: "Contact Notifications",
        announce: "Announce",
        conditional_scripter: "Conditional Scripter",
        call_pause: "Call Pause",
        campaign_editing: "Campaign Editing",
        call_pause_description: "You can pause calls to your campaign contacts by selecting certain parameters. You can define pauses based on your specific campaign lists or your entire campaign data. You can make the necessary definitions in the added lines by clicking the Add Condition button.",
        add_condition: "Add Condition",
        save_condition: "Save Condition",
        parameter: "Parameter",
        operator: "Operator",
        value: "Value",
        or: "or",
        total_number_of_resumed_contacts: "Total number of resumed contacts: ",
        stop_all_calls: "Stop all calls",
        condition_group_delete_title: "The condition group will be removed.",
        condition_group_delete_description: "Calls will be started to all phone numbers that meet this condition group. Do you want to continue?",
        delete_condition: "Delete Condition",
        delete_profile: "Delete Profile",
        edit_profile: "Edit Profile",
        select_user: "Select User",
        new_profile: "New Profile",
        dialing_settings: "Dialing Settings",
        time_settings: "Time Setting",
        home_phone: "Home Phone",
        mobile_phone: "Mobile Phone",
        work_phone: "Work Phone",
        special_phone: "Special Phone",
        dialing_settings_description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum tincidunt id ipsum non sagittis. Integer consectetur, dui non",
        phone_profile: "Phone Profile",
        office_hours: "Office Hours",
        office_hours_description: "By customizing the Click2Connect working hours to your office hours, you can determine whn your customers can reach you. You can set up your system with the default office hours fast.",
        default_office_hours: "Default Office Hours",
        default_office_hours_description: "If you do not customize the working hours, Click2Connect will be active 24/7. By clicking on add working hours you can customize the office hours.",
        open_724: "7/24 Open",
        add_hours: "Add Hours",
        specific_dates: "Specific Dates",
        specific_dates_description: "You can plan the office hours for holidays beforeheand without having to modify the default settings. This way you can create your customized calendar.",
        add_specific_date: "Add Specific Date",
        select_times: "Select Times",
        days: "Days",
        start_time: "Start Time",
        end_time: "End Time",
        status: "Status",
        statuses: "Statuses",
        finish_codes_description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum tincidunt id ipsum non sagittis. Integer consectetur, dui non",
        add_new_finish_code: "Add New Finish Code",
        edit_finish_code: "Edit Finish Code",
        reason_code: "Reason Code",
        finish_code: "Finish Code",
        successful: "Successful",
        finish_code_list: "Finish Code List",
        scheduled_delay: "Scheduled Delay",
        completed: "Completed",
        scheduled: "Scheduled",
        invalid_call: "Invalid Call",
        day: "Day",
        daily_dialing_limit: "Daily Dialing Limit",
        total_dialing_limit: "Total Dialing Limit",
        search_rules: "Search Rules",
        reasons: "Reasons",
        delay_sec: "Delay (sec)",
        dial_plan: "Dial Plan",
        do_not_call: "Do not call",
        busy: "Busy",
        no_answer: "No answer",
        abandon: "Abandon",
        invalid_number: "Invalid Number",
        answering_machine: "Answering Machine",
        disconnected: "Disconnected",
        choose_a_dial_plan: "Choose a Dial Plan",
        quantity: "Quantity",
        time: "Time",
        update: "Update",
        are_you_sure_to_delete_finish_code_below: "Are you sure to delete finish code below?",
        contact_notifications_description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum tincidunt id ipsum non sagittis. Integer consectetur, dui non",
        contact_webhook_active: "Contact Webhook Active",
        contact_webhook_url: "Contact Webhook URL",
        post_call_webhook_active: "Post Call Webhook Active",
        post_call_webhook_url: "Post Call Webhook URL",
        announce_description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum tincidunt id ipsum non sagittis. Integer consectetur, dui non",
        announce_repeat_period: "Announce repeat period (sec)",
        play_once_when_call_opens: "Play Once When Call Opens",
        conditional_scripter_description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum tincidunt id ipsum non sagittis. Integer consectetur, dui non",
        add_campaign: "Add Campaign",
        before: "Before",
        enter_campaign_settings: "Enter campaign settings",
        campaign_type: "Campaign Type",
        predictive: "Predictive",
        select_option: "Select Option",
        campaign_name: "Campaign Name",
        campaign_id: "Campaign ID",
        enter_dialing_rules_according_to_phone_number_type: "Enter dialing rules according to phone number type.",
        dialing_rules: "Dialing Rules",
        redial: "Redial",
        enter_the_start_and_end_dates_of_the_campaign: "Enter the start and end dates of the campaign.",
        start_date_and_time: "Start Date and Time",
        end_date_and_time: "End Date and Time",
        upload_campaign_list: "Upload Campaign List",
        upload_campaign_list_description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum tincidunt id ipsum non sagittis. Integer consectetur, dui non",
        list_name: "List Name",
        choose_file: "Choose File",
        email_the_list_summary: "Email the list summary",
        check_for_duplicate_number: "Check for duplicate number",
        just_cancel_the_duplicates: "Just cancel the duplicates",
        uploaded_lists: "Uploaded Lists",
        uploader: "Uploader",
        last_data_upload_date: "Last Data Upload Date",
        rows: "Rows",
        remainder: "Remainder",
        dialing_rule: "Dialing Rule",
        export: "Export",
        list: "List",
        tree: "Tree",
        add_step_info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tempus, lorem vel blandit dignissim, nunc dolor faucibus augue, vel porta purus nisl id augue.",
        add_filter: "Add Filter",
        filter_by: "Filter by",
        is: "is",
        is_not: "is not",
        starts_with: "starts with",
        ends_with: "ends with",
        contains: "contains",
        does_not_contain: "does not contain",
        is_unknown: "is unknown",
        has_any_value: "has any value",
        missing_value: "missing value",
        done: "Done",
        true: "True",
        false: "False",
        add_step: "Add Step",
        sell_ivr: "Satış IVR",
        history: "History",
        rev_changes_mode: "Rev. Changes Mode",
        last_saved: "Last Saved: ",
        add_dialing: "Tuş Ekle",
        step_type: "Adım Tipi",
        prev_step: "Önceki Adım",
        next_step: "Sonraki Adım",
        dialing: "Dialing",
        attendant_name: "Attendant Name",
        column: "Column",
        addRemove: "Add / Remove",
        settings: "Settings",
        active_users: "Available Users",
        all_users: "All Users",
        select_users: "Select Users",
        select_all: "Select All",
        filtered_select_all: "Filtered Select All",
        supervisor: "Supervisor",
        reporting: "Reporting",
        quality_control: "Quality Control",
        extension: "Extension",
        operator_console: "Operator Console",
        access_control_list: "Access Control List",
        apply: "Apply",
        table_settings: "Table Settings",
        save_settings: "Save Settings",
        restore_system_settings: "Restore System Settings",
        restore_table_settings: "Restore Table Settings",
        pin: "Pin",
        pinned: "Pinned",
        unpin: "Unpin",
        publish: "Publish",
        config: "Config",
        exits: "Exits",
        delete_node: "Delete Step",
        delete_node_text: "Are you sure that you want to delete this step?",
        of: "of",
        rows_per_page: "Rows per page')}}:",
        selected: "Selected",
        change_role: "Change Role",
        select_roles_to_apply: "Select Roles to Apply",
        add_ivr: "Add IVR",
        general_settings: "General Settings",
        limit_settings: "Limit Settings",
        call_can_be_transferred_in: "Call can be transferred in",
        call_can_be_transferred_in_description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ac risus elemantum diam euismod",
        attendant_name: "Attendant Name",
        user_status_after_transfer: "User Status After Transfer",
        wrap_up: "Wrap-up",
        inform_the_agent_if_transferred_call_is_hangup: "Inform the agent if transferred call is hangup",
        inform_the_agent_if_transferred_call_is_hangup_description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ac risus elemantum diam euismod",
        internal: "Internal",
        credit_card: "Credit Card",
        credit_card_description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ac risus elemantum diam euismod",
        limit_active: "Limit Active",
        limit_active_description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ac risus elemantum diam euismod",
        limit_type: "Limit Type",
        concurrent_call_limit: "Concurrent Call Limit",
        limit_multiplier: "Limit Multiplier",
        overflow_attendant: "Overflow Attendant",
        limit_strategy: "Limit Strategy",
        add_icr: "Add ICR",
        add_icr_description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tempus, lorem vel",
        profile: "Profile",
        teams: "Teams",
        locations: "Locations",
        roles: "Roles",
        station_number: "Station Number",
        station_password: "Station Password",
        old_password: "Old Password",
        password_again: "Password Again",
        choose_who_can_see_your_profile_photo: "Choose who can see your profile photo",
        upload_photo: "Upload Photo",
        drag_your_image_to_crop_your_desired_area: "Drag your image to crop your desired area.",
        upload_new_file: "Upload New Photo",
        zoom_in: "Zoom In",
        zoom_out: "Zoom Out",
        crop: "Crop",
        rotate: "Rotate",
        filter: "Filter",
        reset: "Reset",
        image_rotate: "Image Rotate",
        select_filter: "Select Filter",
        delete_profile_photo: "Delete Profile Photo",
        delete_profile_text: "Are you sure you want to delete the profile photo?",
        icr_name: "ICR Name",
        link_parameter_name: "Link Parameter Name",
        link_html: "Link HTML",
        add_link: "Add Link",
        add_scripter: "Add Scripter",
        next: "Next",
        add_condition: "Add Condition",
        scripter: "Scripter",
        conference: "Conference",
        mic: "Mic",
        continue: "Continue",
        slides: "Slides",
        add_slide: "Add Slide",
        title: "Title",
        description: "Description",
        photo: "Photo",
        carousel_buttons: "Carousel Buttons",
        button_text: "Button Text",
        button_type: "Button Type",
        url: "URL",
        add_button: "Add Button",
        set_variable: "Set Variable",
        message_to_send: "Message to Send",
        conference_call: "Conference Call",
        people: "People",
        pay_ivr: "Pay with IVR",
        name_last_name: "Name Last Name",
        help_center: "Help Center",
        performance_cards: "Performance Cards",
        status_durations: "Status Durations",
        chat_performance: "Chat Performance",
        translate_status: "Translate Status",
        no_message_incoming: "No Incoming Message",
        no_message_sent: "No Message Sent",
        no_team_member: "No Team Member",
        no_previous_call: "No Previous Calls",
        no_previous_chat: "No Previous Chats",
        no_queue: "No Queue",
        no_evaluations: "No Evaluations",
        no_chat_evaluations: "No Chat Evaluations",
        no_appointment: "No Appointment",
        no_contact: "No Contact",
        no_parked_chat: "No Parked Chats",
        performance_table: "Performance Table",
        sort_by: "Sort By",
        select_visible: "Filter by",
        highest_inbound: "Highest Inbound",
        lowest_inbound: "Lowest Inbound",
        highest_outbound: "Highest Outbound",
        lowest_outbound: "Lowest Outbound",
        highest_efficiency: "Highest Efficiency",
        lowest_efficiency: "Lowest Efficiency",
        no_pending_chat: "No Pending Chats",
        no_pending_call: "No Pending Calls",
        wallboard: "Wallboard",
        inbound_calls: "Inbound Calls",
        outbound_calls: "Outbound Calls",
        answered_calls: "Answered Calls",
        short_calls: "Short Calls",
        active_calls: "Active Calls",
        active_videocalls: "Active Video Calls",
        video: "Video",
        videocall_records: "Video Call Records",
        videocall_details: "Video Call Details",
        videocall_id: "Video Call ID",
        pending_calls: "Pending Calls",
        abondoned_calls: "Abandoned Calls",
        pending_chats: "Pending Chats",
        callback_request: "Callback Request",
        voice_mails: "Voice Mails",
        listen_conversation: "Listen Conversation",
        hang_up: "Hang Up",
        caller: "Caller",
        called: "Called",
        reserve_agent: "Reserve Agent",
        duration: "Duration",
        inbound: "Inbound", // Başka Prda ekledim burası silinecek
        outbound: "Outbound", // Başka Prda ekledim burası silinecek
        voicemail_duration: "Voicemail Duration",
        talk_duration: "Talk Duration",
        evaluating_user: "Evaluating User",
        evaluation_score: "Evaluation Score",
        evaluate_objection: "Evaluate Objection",
        cancel_objection: "Cancel Objection",
        cancel_objection_content: "Are you sure you want to cancel this objection? Canceling an objection will prevent users from evaluating it.",
        download: "Download",
        total_talk_duration: "Total Talk Duration",
        total_hold_duration: "Total Hold Duration",
        active_chats: "Active Chats",
        call_records: "Call Records",
        call_quality_evaluation_objection: "Call Quality Evaluation Objection",
        chat_quality_evaluation_objection: "Chat Quality Evaluation Objection",
        chat_records: "Chat Records",
        start_date: "Start Date",
        end_date: "End Date",
        number: "Number",
        custom_variables: "Custom Variables",
        client_name: "Client Name",
        client_email: "Client Email",
        phone_number: "Phone Number",
        parking_start_date: "Parking Start Date",
        last_parking_agent: "Last Parking Agent",
        view_chat: "View Chat",
        end_conversation: "End Conversation",
        search: "Search",
        notification_type: "Notification Types",
        forget_password: "Forget Password",
        check_all_notifications: "Check All Notifications",
        auto_transition: "Auto Transition",
        agt: "AGT",
        chatting: "Chatting",
        chattingRatio: "(1/4)",
        talking: "Talking",
        not_answering: "Not Answering",
        nothing_selected: "Nothing Selected",
        short_break: "Short Break",
        all_teams: "All Teams",
        all_agents: "All Agents",
        revert: "Revert",
        shorter_than: "{0}% shorter than ",
        longer_than: "{0}% longer than ",
        change_team: "Change Team",
        change_queue: "Change Queue",
        transition_interval: "Transition Interval",
        please_enter_color: "Please enter value ranges for the color scale in the chart.",
        all_statuses: "All Statuses",
        default_tab: "Default Tab",
        red: "Red",
        orange: "Orange",
        green: "Green",
        hide_inactive_hours: "Hide Inactive Hours",
        show_inactive_hours: "Show Inactive Hours",
        existing_queue_behavior: "Existing Queue Behavior",
        existing_team_behavior: "Existing Team Behavior",
        lunch: "Lunch",
        meeting: "Meeting",
        training: "Training",
        ringing: "Ringing",
        wrapup: "Wrapup",
        aftercallwork: "After call work",
        acw: "ACW",
        remove_queue: "Remove Queue",
        add_team: "Add Team",
        remove_team: "Remove Team",
        overwrite: "Overwrite",
        keep: "Keep",
        all_queues: "All Queues",
        default_dashboard: "Default Dashboard",
        all_locations: "All Locations",
        new_dashboard: "New Dashboard",
        one_column: "Column",
        unsaved_changes: "Unsaved Changes",
        select_time_secs: "Select Time (sec)",
        component: "Components",
        layout: "Layout",
        table_columns: "Table Settings",
        confirm: "Confirm",
        clear_All: "Clear All",
        rows_per_page_small: "Rows:",
        filters: "Filters",
        graph_sl: "SL",
        graph_ar: "AR",
        graph_avg_time: "Avg. Time",
        button_border_color: "Button Border Color",
        button_text_color: "Button Text Color",
        dashboard_messages: "Messages",
        dashboard_notifications: "Notification Board",
        dahboard_status_table: "Status Table",
        dashboard_chat_status_table: "Chat Status Table",
        dashboard_pending_calls: "Pending Calls",
        dashboard_pending_chats: "Pending Chats",
        dashboard_queue_statistics: "Queue Statistics",
        dashboard_interval_table: "Interval Table",
        button_add_component: "Add Component",
        pop_up_title_component: "Components",
        layout_name: "Layout",
        sl_ar_info_text:"SL = SL içerisinde Cevaplanan / Toplam Cevaplanan\nSL2 = SL içerisinde Cevaplanan / (Toplam Gelen Çağrı - Kısa Çağrı)\nAR = Cevaplanan / Gelen Çağrı AR2 = Cevaplanan / (Gelen Çağrı - Kısa Cağrı)\nAHT = (Konuşuyor + Bekletme + Çalıyor + ACW + Çağrı Sonu) /Cevaplanan",
        total: "Total",
        queue_name: "Queue Name",
        longest_wait: "Longest Wait",
        you: "You",
        sent_a_file: "Sent a file...",
        no_results_found: "No results found",
        tag: "Tag",
        subject: "Subject",
        tags: "Tags",
        design_selection: "Design Selection",
        version_1: "Version 1",
        version_2: "Version 2",
        apply_design: "Apply Design",
        alerts: "Alerts",
        inbound: "Inbound",
        outbound: "Outbound",
        backoffice: "Back Office",
        voicemail: "Voicemail",
        callback: "Callback",
        efficiency: "Efficiency",
        listen_agent: "Listen Agent",
        listen_agent_permission_alert: "You cannot use the listening feature because the Listen option in the user settings is not active, please contact your system administrator.",
        listen_agent_softphone_disabled_alert: "You cannot use the listening feature because the Supervisor Softphone Disabled option in the user settings is activated, please contact your system administrator.",
        agent_performance: "Agent Performance",
        agent_settings: "Agent Settings",
        agent_chat_busy: "Agent Chat Busy",
        system_chat_busy: "System Chat Busy",
        transferred_calls: "Transferred Calls",
        transfer_date: "Transfer Date",
        transfer_type: "Transfer Type",
        transferor: "Transferor",
        transferee: "Transferee",
        custom_id: "Custom ID",
        initial_queue: "Initial Queue",
        last_agent: "Last Agent",
        play_recording: "Play Recording",
        user: "User",
        active_call_key: "Active Call Key",
        abandoned_calls_from_queues: "Abandoned Calls from Queues",
        numbers: "Numbers",
        call_date: "Call Date",
        callback_date: "Callback Date",
        callback_time: "Callback Time",
        callback_agent: "Callback Agent",
        callback_disposition: "Callback Disposition",
        callback_details: "Callback Details",
        total_score: "Total Score",
        answers: "Answers",
        satisfaction_survey: "Satisfaction Survey",
        voicemail_callback_request: "Voicemail & Callback Request Calls",
        called_back: "Called back",
        repeat_call: "Repeat Call",
        cancelled: "Cancelled",
        accepted: "Accepted",
        accept: "Accept",
        rejected: "Rejected",
        reject: "Reject",
        insert_date: "Insert Date",
        customer_evaluation_score: "Customer Evaluation Score",
        select_objection_result: "Select Objection Result",
        add_description: "Add Description",
        current_inbound_calls: "Current Inbound Calls",
        current_outbound_calls: "Current Outbound Calls",
        daily_answered_calls: "Daily Answered Calls",
        daily_short_calls: "Daily Short Calls",
        current_active_calls: "Current Active Calls",
        current_pending_calls: "Current Pending Calls",
        daily_abandoned_calls: "Daily Abandoned Calls",
        current_pending_chats: "Current Pending Chats",
        daily_callback_requests: "Daily Callback Request",
        daily_voice_mails: "Daily Voice Mails",
        add_tag: "Add Tag",
        status_name: "Status Name",
        color: "Color",
        selectable: "Selectable",
        production: "Production",
        spv_selectable: "SPV Selectable",
        auto_change_dur: "Auto. Change Dur.",
        team: "Team",
        component_name: "Component Name",
        amount_limit: "Amount Limit",
        duration_limit: "Duration Limit",
        home_page: "Home Page",
        pending_alerts: "Pending Alerts",
        editing_pending_alerts: "Editing Pending Alerts",
        custom_statuses: "Custom Statuses",
        adding_custom_statuses: "Adding Custom Statuses",
        editing_custom_statuses: "Editing Custom Statuses",
        deleting_custom_statuses: "Deleting Custom Statuses",
        status_settings: "Status Settings",
        default_statuses: "Default Statuses",
        editing_default_statuses: "Editing Default Statuses",
        enter_a_custom_status: "Enter a custom status",
        no_custom_status_to_show: "No Custom Status to Show",
        spv_alarm_info_text: "Notification on the supervisor screen for the agent that exceeds the specified time (min)",
        agent_alarm_info_text: "Pop-up notification on the agent screen when the specified time is exceeded (sec)",
        alarm_duration_info_text: "Define in terms of seconds how long will the triggered popup be visible. It is advised that the time you define in the popup (sec) fields to be greater than the one in this field. The default value is 3 seconds.",
        selectable_limit_info_text: "A value of 0 disables limit control at the specified status.",
        spv_alert: "SPV Alert",
        agent_alert: "Agent Alert",
        alert_dur: "Alert Dur.",
        agent_limit: "Agent Limit",
        having_a_problem: "Having a problem?",
        you_can_reach_the_change_status_screen_by_clicking_the_button: "You can reach the Change Status screen by clicking the button.",
        the_screen_has_been_updated: "The screen has been updated. Please try again.",
        banned_date: "Banned Date",
        unblock: "UnBlock",
        user_name: "User Name",
        general: "General",
        new: "New",
        team_has_no_users: "The team currently has no users.",
        team_has_no_queues: "The team currently has no queues.",
        team_name: "Team Name",
        creation_date: "Creation Date",
        canceled: "Canceled",
        okay: "Okay",
        are_you_sure_you_want_to_delete: "Are you sure you want to delete?",
        warning: "WARNING!",
        new_team: "New Team",
        please_enter_the_name_of_your_new_team: "Please enter the name of your new team.",
        once_continued_you_cannot_edit_the_team_name: "Once continued, you cannot edit the team name.",
        no_data_chat_detail: "This chat does not have the data for the selected page.",
        no_data_call_detail: "This call does not have the data for the selected page.",
        last_updated: "Last Updated",
        dtmf_name: "DTMF Name",
        dtmf: "DTMF",
    }
});