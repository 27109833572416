//-------------------------------------------------------------------------------
// Copyright © 2022 Valven. - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and Confidential
//-------------------------------------------------------------------------------

import React from 'react'
import styles from './Slide.module.scss'
import { Box, Button } from "@mui/material";
import CustomButton from './CustomButton';
import { ReactComponent as AddIcon } from '../../static/img/common/add.svg';
import colors from '../../styles/_export.scss';
import { strings } from '@alotech/common/components/i18n';
import AddSlideDialog from '../systemAdministrator/slide/AddSlideDialog'
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import { ReactComponent as MoveIcon } from '../../static/img/common/move_large.svg';
import { ReactComponent as EditIcon } from '../../static/img/common/ac_edit.svg';
import { ReactComponent as DeleteIcon } from '../../static/img/common/ac_delete.svg';
import { ReactComponent as RightIcon } from '../../static/img/common/chevron_right.svg';
import { ReactComponent as LeftIcon } from '../../static/img/common/chevron_left.svg';
import {
    DragDropContext,
    Droppable,
    Draggable
} from 'react-beautiful-dnd';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { styled } from '@mui/material/styles';
import { BootstrapDialogTitle } from '../customDialog/CustomDialog'
import MobileStepper from '@mui/material/MobileStepper';
import SwipeableViews from 'react-swipeable-views';
import { createTheme, ThemeProvider } from '@mui/material/styles';


export default function Slide(props) {

    const { onChange, data } = props;
    const [values, setValues] = React.useState(data.values ?? []);
    const [openAddDialog, setOpenAddDialog] = React.useState(false);
    const [openPreviewDialog, setOpenPreviewDialog] = React.useState(false);

    React.useEffect(() => {
        onChange({
            "type": "slide",
            "values": values
        })
    }, [values]);

    const onCreate = () => {
        setOpenAddDialog(true);
    }

    const onDelete = (i) => {
        const tmp = [...values];
        tmp.splice(i, 1);
        setValues(tmp);
    }

    const handleCloseAddDialog = function (e) {
        setOpenAddDialog(false);
    }

    const onPreview = () => {
        setOpenPreviewDialog(true);
    }

    const handleClosePreviewDialog = function (e) {
        setOpenPreviewDialog(false);
    }

    const handleCreateSubmit = () => {
        setOpenAddDialog(false);
        setValues([...values, 
            {
                "title": "Title1",
                "description": "Description1",
                "img": "https://st2.depositphotos.com/1006318/5909/v/950/depositphotos_59095529-stock-illustration-profile-icon-male-avatar.jpg",
                "buttons": [
                    {
                        "text": "Button1",
                        "type": 1,
                        "value": "Button1"
                    },
                    {
                        "text": "Button2",
                        "type": 1,
                        "value": "Button2"
                    },
                    {
                        "text": "Button3",
                        "type": 1,
                        "value": "Button3"
                    }
                ]
            }])

    }

    const onSettingsDragEnd = ({ destination, source }) => {
        if (!destination) return;

        const newState = Array.from(values);
        const [removed] = newState.splice(source.index, 1);
        newState.splice(destination.index, 0, removed);

        setValues(newState);
    };

    return (
        <>
            {values.length > 0 &&
                <>
                    <FormLabel id={`data-${data.name}`} className={styles.Slide_InnerLabel}>{data.innerLabel}
                        <CustomButton onClick={() => { onPreview() }} style={{ "marginLeft": "auto" }} size="small" variant="text" color="secondary">{strings.preview}</CustomButton>
                    </FormLabel>

                    <DragDropContext
                        onDragEnd={onSettingsDragEnd}
                    >
                        <Droppable droppableId='droppable-list'>

                            {(provided, snapshot) => {
                                return (
                                    <FormGroup
                                        sx={{ marginTop: 0 }}
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}>
                                        {values.map((header, i) =>

                                            <Draggable key={i} index={i} draggableId={i.toString()}>
                                                {(provided, snapshot) => {
                                                    return <div ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        style={{
                                                            ...provided.draggableProps.style
                                                        }}>
                                                        <Box className={styles.Slide_Carousel}>
                                                            <Box {...provided.dragHandleProps}>
                                                                <MoveIcon width={24} height={24} />
                                                            </Box>
                                                            <Box className={styles.Slide_Carousel_Index}>
                                                                {i + 1}
                                                            </Box>
                                                            <img className={styles.Slide_Carousel_Img} src={header.img} />
                                                            <Box className={styles.Slide_Carousel_Detail}>
                                                                <Box className={styles.Slide_Carousel_Detail_Title}>{header.title}</Box>
                                                                <Box className={styles.Slide_Carousel_Detail_Description}>{header.description}</Box>
                                                            </Box>
                                                            <Box className={styles.Slide_Carousel_Action}>
                                                                <EditIcon width={24} height={24} />
                                                                <DeleteIcon width={24} height={24} onClick={() => { onDelete(i) }} />
                                                            </Box>
                                                        </Box>
                                                    </div>
                                                }}

                                            </Draggable>
                                        )}
                                        {provided.placeholder}
                                    </FormGroup>
                                )
                            }}
                        </Droppable>
                    </DragDropContext>
                </>
            }

            <CustomButton onClick={onCreate} className={styles.Slide_AddButton} startIcon={<AddIcon />} size="medium" variant="outlined" color="primary">{strings.add_slide}</CustomButton>
            <AddSlideDialog open={openAddDialog} handleClose={handleCloseAddDialog} onFormSubmit={handleCreateSubmit} />
            <Preview content={<Stepper items={values} />} open={openPreviewDialog} handleClose={handleClosePreviewDialog} name={"Carousel Slide Preview"} />
        </>
    )
}

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        backgroundColor: `${colors.white} !important`,
        borderRadius: '4px',
        width: '410px',
        height: '600px',
        position: 'absolute',
        right: 0,
        bottom: 0,
    },
    '& .MuiDialogTitle-root': {
        marginBottom: '8px',
        boxShadow: '0px 0px 10px #00000029',
        padding: '16px 24px !important'
    },
    '& .MuiDialogContent-root': {
        border: 'none',
        padding: '0px'
    },
    '& .MuiDialogActions-root': {
        backgroundColor: `${colors.white}`,
        padding: '8px',
        borderTop: '1px solid #E4E4E4',
        height: 113
    },
    '& .MuiTabs-root': {
        padding: '0px 0px'
    },
    '& .MuiTabPanel-root': {
        paddingTop: '30px !important'
    },
    '& .MuiDialog-root': {
        width: '100% !important'
    },
    '& .MuiDialog-paperWidthXl': {
        margin: '0 24px 0 0'
    },
}));

function Preview({ open, handleClose, content, width, name, ...others }) {
    return (
        <BootstrapDialog
            maxWidth='xl'
            onClose={handleClose}
            open={open}
            sx={{ width: width }}
            {...others}>

            {name &&
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    <span className={styles.headerText}>{name}</span>
                </BootstrapDialogTitle>
            }

            <DialogContent>
                {content}
            </DialogContent>
        </BootstrapDialog>
    )
}

function Stepper({ items }) {
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = items.length;

    const theme = createTheme({
        components: {
            MuiMobileStepper: {
                styleOverrides: {
                    dot: {
                        background: '#FFFFFF 0% 0% no-repeat padding-box',
                        border: '1px solid #8898AA',
                        width: '11px',
                        height: '11px'
                    },
                    dotActive: {
                        background: '#6D809F 0% 0% no-repeat padding-box',
                        border: '1px solid #6D809F',
                        width: '11px',
                        height: '11px'
                    },
                    positionStatic: {
                        backgroundColor: '#F6F7FA'
                    }
                },
            }
        }
    });

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <ThemeProvider theme={theme}>
            <Box className={styles.SwipeBox}>
                <SwipeableViews style={styles.slideContainer}>
                    {items.map((item, index) => (
                        <Box key={index} className={styles.SwipeBox_Item}>
                            <img className={styles.SwipeBox_Item_Img} src={item.img} />
                            <Box className={styles.SwipeBox_Item_Title}>{item.title}</Box>
                            <Box className={styles.SwipeBox_Item_Description}>{item.description}</Box>
                            
                            {item.buttons.map((val, i) => (
                                <Box key={i} className={styles.SwipeBox_Button}>
                                    {val.text}
                                </Box>
                            ))}
                        </Box>
                    ))}
                </SwipeableViews>
                <MobileStepper
                    steps={maxSteps}
                    position="static"
                    activeStep={activeStep}
                    nextButton={
                        <Button
                            size="small"
                            onClick={handleNext}
                            disabled={activeStep === maxSteps - 1}
                        >
                            <RightIcon />
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                            <LeftIcon />
                        </Button>
                    }
                />
            </Box>
        </ThemeProvider>
    );
}