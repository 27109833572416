import React from "react";
import { ReactComponent as DateIcon } from "../../static/img/dateIcon.svg";
import { ReactComponent as TimeIcon } from "../../static/img/timeIcon.svg";

export default function DateLine({ date, time }) {
  return (
    <div style={{ display: "flex", gap: "9px", alignItems: "center" }}>
      {date && (
        <div style={{ display: "flex", alignItems: "center", gap: "3px" }}>
          <DateIcon style={{ marginBottom: "3px" }} />
          <span style={{ color: "#6D809F", fontWeight: 600, fontSize: "12px" }}>
            {date}
          </span>
        </div>
      )}
      {time && (
        <div style={{ display: "flex", alignItems: "center", gap: "3px" }}>
          <TimeIcon style={{ marginBottom: "3px" }} />
          <span style={{ color: "#6D809F", fontWeight: 600, fontSize: "12px" }}>
            {time}
          </span>
        </div>
      )}
    </div>
  );
}
