import React from "react";
import TextField from "@mui/material/TextField";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import colors from "../../styles/_export.scss";
import styles from "./InputField.module.scss";

export default function InputField(props) {
  const { className, error, ...other } = props;

  let klazz = error ? styles.InputField_Error : "";
  klazz = className ? className + " " + klazz : klazz;

  const defaultProps = {
    inputheight: "50px",
    lineheight: "20px",
    padding: "15px",
    textcolor: other.textColor ?? colors.primaryMain,
    bordercolor: colors.selectBorder,
  };
  if (other.size == "xsmall") {
    defaultProps["inputheight"] = "32px";
    defaultProps["padding"] = "10px 16px";
    defaultProps["fontSize"] = "12px";
    defaultProps["fontWeight"] = "500";
    defaultProps["textcolor"] = other.textColor ?? colors.blue;
  } else if (other.size == "small") {
    defaultProps["inputheight"] = "40px";
    defaultProps["padding"] = "12px 16px";
    defaultProps["fontSize"] = "14px";
    defaultProps["fontWeight"] = "700";
    defaultProps["textcolor"] = other.textColor ?? colors.blue;
    if (other.customStyle) {
      Object.assign(defaultProps, { ...other.customStyle });
    }
  } else if (other.size == "grid") {
    defaultProps["inputheight"] = "39px";
    defaultProps["padding"] = "8px";
    defaultProps["fontSize"] = "13px";
  }
  const allProps = { ...defaultProps, ...other };
  if (allProps.multiline) {
    allProps["inputheight"] = null;
  }
  if (error) {
    allProps["error"] = error;
  }

  const theme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            height: allProps.inputheight ?? "auto",
            backgroundColor: allProps.backgroundColor ?? "white",
            color: allProps.textcolor ?? "currentColor",
            fontSize: allProps.fontSize ?? "inherit",
            fontWeight: allProps.fontWeight ?? "inherit",
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor:
                allProps.bordercolor ??
                (allProps.multiline ? colors.selectBorder : colors.bordercolor),
            },
            "&.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: colors.borderError,
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline legend": {
              color:
                allProps.bordercolor ??
                (allProps.multiline ? colors.selectBorder : colors.bordercolor),
            },
            "&.Mui-error": {
              borderColor: colors.borderError,
            },
          },
          input: {
            padding: allProps.padding ?? "12px",
            fontSize: other.fontSize && `${other.fontSize}px !important`,
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            margin: allProps.textmargin ?? "0",
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: allProps.textcolor ?? "rgba(0, 0, 0, 0.6)",
            fontSize: allProps.fontSize ?? "inherit",
            fontWeight: allProps.fontWeight ?? "inherit",
            lineHeight: allProps.lineheight ?? "initial",
            fontSize: other.fontSize && `${other.fontSize}px !important`,
            "&.Mui-focused": {
              color:
                allProps.bordercolor ??
                (allProps.multiline ? colors.selectBorder : colors.bordercolor),
            },
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <TextField className={klazz} {...allProps} />
    </ThemeProvider>
  );
}
