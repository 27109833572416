import styled from "@emotion/styled";
import { Button } from "@mui/material";
import React from "react";

export default function CustomizedButton({
  color,
  hoverBgColor,
  borderColor,
  height,
  hoverBorderColor,
  text,
  borderRadius,
  width,
  variant,
  backgroundColor,
  fontFamily,
  fontWeight,
  fontSize,
  disabled,
  onClick,
  marginRight,
  marginLeft,
}) {
  const CustomizedButton = styled(Button)({
    color: color ?? "#002983",
    borderColor: borderColor ?? "unset",
    borderRadius: borderRadius ?? "unset",
    height: height ?? "40px",
    width: width ?? "120px",
    backgroundColor: backgroundColor ?? "unset",
    fontFamily: fontFamily ?? "Axiforma",
    fontWeight: fontWeight ?? "400",
    fontSize: fontSize ?? "12px",
    textTransform: "none",
    marginRight: marginRight ?? "unset",
    marginLeft: marginLeft ?? "unset",
    "&:hover": {
      borderColor: hoverBorderColor ?? "unset",
      backgroundColor: hoverBgColor ?? "unset",
    },
    "&.Mui-disabled": {
      backgroundColor: "#FFFFFF",
      borderColor: "#CCDCE6",
      color: "#CCDCE6",
    },
  });

  return (
    <CustomizedButton
      onClick={onClick}
      disabled={disabled}
      variant={variant ?? "outlined"}
    >
      {text}
    </CustomizedButton>
  );
}
