import React,{ useState, useEffect } from 'react';
//Mui
import Modal from '@material-ui/core/Modal';
import {makeStyles} from '@material-ui/core/styles';
//Img
import closeIcon from "../static/img/close.png";
//Sass
import styles from "../styles/NewModal.module.scss";

export default function ({
    openNewModal,
    clickOkButton,
    clickCancelButton,
    okButton,
    cancelButton,
    request_otp_code,
    OTPModal,
    title,
    content
    }) {
    
    // You have to send these props

    // openNewModal --> boolean
    // OTPModal --> boolean
    // clickOkButton --> function
    // clickCancelButton --> function
    // okButton --> boolean
    // cancelButton --> boolean
    // title --> string
    // content --> string

    const [modalStyle] = useState(getModalStyle);

    const [otp, setOtp] = useState("");
    const [codeTime, setCodeTime] = useState("02:00");
    const [showCodeTime, setShowCodeTime] = useState(true);
    const [reloadTimerRefresh, setReloadTimerRefresh] = useState(false);
    
    function getModalStyle() {
        const top = 50 ;
        const left = 50 ;
        
        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
            width: "auto",
            height: "auto",
            paddingBottom: "10px"
        };
    }

    const useStyles = makeStyles(theme => ({
        paper: {
            position: 'absolute',
            width: OTPModal ? 610 : 725,
            height: OTPModal ? 293 : 235,
            background: "#FFFFFF 0% 0% no-repeat padding-box",
            border: '1px solid #E4E7F2',
            borderRadius: "10px",
            boxShadow:"0px 4px 10px #0000001A",
            outline: 'none'
        },
    }));
    
    const classes = useStyles();

    useEffect(() => {
        let interval = ""
        if(OTPModal && openNewModal){
            setShowCodeTime(true);
            setCodeTime("02:00");
            interval = countDownTimer();
        }
        return () => {
            clearInterval(interval)
        }
        
    }, [openNewModal, reloadTimerRefresh]);

    const countDownTimer = () => {
        var totalseconds = 120;
        const interval = setInterval(() => {
            totalseconds --;
            const minutes = Math.floor(totalseconds / 60);
            const seconds = totalseconds < 60 ? totalseconds : Math.floor(totalseconds % (minutes*60));
            const remainingTime = (minutes < 10 ? "0"+minutes : minutes) +":"+ (seconds < 10 ? "0"+seconds : seconds);
            setCodeTime(remainingTime);
            if(totalseconds === 0){
                clearInterval(interval);
                setShowCodeTime(false);
            }
        }, 1000);
        return interval        
    } 
    
    const startCodeTime = (e) => {
        setReloadTimerRefresh(!reloadTimerRefresh)
        request_otp_code();
    }
    const clkOkButton = () => {
        clickOkButton(otp);
    }
    const clkCancelButton= () => {
        clickCancelButton();
    }
    const handleChangeOTPCode = (e) => {
        setOtp(e.target.value);
    }

    return (
        <>
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={openNewModal}
            >
                <div style={modalStyle} className={classes.paper}>
                    <div className={styles.headerContainer}>
                        <div className={styles.modalTitle}>
                            <h5>{title}</h5>
                        </div>
                        <div onClick={clickCancelButton} className={styles.modalCloseIcon}>
                            <img src={closeIcon} alt="closeIcon"/>
                        </div>
                    </div>
                    <div className={styles.contentContainer}>
                        <span>
                            {content}
                        </span>
                    </div>
                    {
                        OTPModal ? 
                            <div className={styles.inputContainer}>
                                <input type="text" onChange={handleChangeOTPCode} />
                                {
                                    showCodeTime ? 
                                        <span className={styles.codeTime}>{codeTime}</span> 
                                    : 
                                        <span onClick={startCodeTime} className={styles.codeText}>Resend Code</span>
                                }
                            </div>
                        : null
                    }
                    <div className={styles.buttonsContainer}>
                        {
                            cancelButton ?
                                <button onClick={clkCancelButton} className={styles.leftButton}>
                                    Cancel
                                </button>
                            : null

                        }
                        {
                            okButton ?
                                <button onClick={clkOkButton} className={styles.rightButton}>
                                    OK
                                </button>
                            : null
                        }
                    </div>
                </div>
            </Modal>
        </>
    )
}