import React from "react";
import styles from "./DashboardItemHeader.module.scss";
import { Box } from "@mui/material";

export default function DashboardItemHeader(props) {
  const { title, path } = props;

  return (
    <Box
      sx={{
        backgroundColor: "#eaf2f4",
        position: "sticky",
        top: "0px",
        zIndex: "15",
        height: "70px",
      }}
    >
      <Box
        className={styles.dashboardToolbar}
        sx={{ paddingLeft: "0px", backgroundColor: "white" }}
      >
        <Box className={styles.dashboardToolbarNames}>
          <Box className={styles.dashboardToolbarName}>
            <Box className={styles.headerNameSection}>
              <span className={styles.titleArea}>{title}</span>
            </Box>
            {path ? (
              <Box className={styles.dashboardToolbarPath}> {path} </Box>
            ) : null}
          </Box>
          <div className={styles.leftButtonSection}></div>
        </Box>
      </Box>
    </Box>
  );
}
