import React from "react";
import { Box } from "@mui/material";
import styles from "./DraggableColumns.module.scss";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ReactComponent as MoveIcon } from "../../static/img/move-d.svg";
import { ReactComponent as PinnedIcon } from "../../static/img/pin1.svg";
import { ReactComponent as PinIcon } from "../../static/img/Pin.svg";
import colors from "../../styles/_export.scss";
import Menu from "@mui/material/Menu";
import CheckboxButton from "../form/CheckboxButton";
import FormGroup from "@mui/material/FormGroup";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import CustomizedButton from "../form/CustomizedButton";

import cloneDeep from "lodash/cloneDeep";

export default function DraggableColumns(props) {
  const {
    headers,
    onSettingsChanged,
    storedName,
    orderedColumns,
    setOrderedColumns,
    settingsAnchorEl,
    setSettingsAnchorEl,
  } = props;
  const settingsOpen = Boolean(settingsAnchorEl);

  const handleSettingsClose = () => {
    setSettingsAnchorEl(null);
  };

  const onSettingsDragEnd = ({ destination, source }) => {
    if (!destination) return;

    const newState = Array.from(orderedColumns);
    const [removed] = newState.splice(source.index, 1);
    newState.splice(destination.index, 0, removed);

    var orderArr = [];
    newState.map((value, index) => {
      orderArr[index] = {
        id: value.id,
        hide: value.hide ? value.hide : false,
        pin: value.pin ? value.pin : false,
      };
    });

    localStorage.setItem(storedName, JSON.stringify(orderArr));

    setOrderedColumns(newState);
    onSettingsChanged(newState);
  };

  const handleSettingsCheckedChange = (event) => {
    let newState = Array.from(orderedColumns);
    newState.find((x) => x.id == event.target.name)["hide"] =
      !event.target.checked;
    var orderArr = [];
    newState.map((value, index) => {
      orderArr[index] = {
        id: value.id,
        hide: value.hide ? value.hide : false,
        pin: value.pin ? value.pin : false,
      };
    });

    localStorage.setItem(storedName, JSON.stringify(orderArr));

    setOrderedColumns(newState);
  };

  const handleSaveSettings = () => {
    const orderArr = [];
    orderedColumns.map((value, index) => {
      orderArr[index] = {
        id: value.id,
        hide: value.hide ? value.hide : false,
        pin: value.pin ? value.pin : false,
      };
    });
    localStorage.setItem(storedName, JSON.stringify(orderArr));
    onSettingsChanged(orderedColumns);
    setSettingsAnchorEl(null);
  };

  const handleRestoreSystemSettings = () => {
    let ary = cloneDeep(headers);
    setOrderedColumns(ary);
    onSettingsChanged(ary);
    setSettingsAnchorEl(null);
    localStorage.removeItem(storedName);
  };

  const handleOnPin = (event) => {
    let newState = Array.from(orderedColumns);
    let setting = newState.find(
      (x) => x.id == event.currentTarget.dataset.name
    );
    setting["pin"] = setting?.pin != null ? !setting.pin : true;

    let action = newState.find((x) => x.id === "action");
    if (action) {
      let pinned = newState.find((x) => x.pin === true && x.id !== "action");
      action["pin"] = pinned != null;
    }
    const orderArr = [];
    newState.map((value, index) => {
      orderArr[index] = {
        id: value.id,
        hide: value.hide ? value.hide : false,
        pin: value.pin ? value.pin : false,
      };
    });

    localStorage.setItem(storedName, JSON.stringify(orderArr));

    setOrderedColumns(newState);
    onSettingsChanged(newState);
  };

  const settingsTheme = createTheme({
    components: {
      MuiMenu: {
        styleOverrides: {
          root: {
            "& .MuiPaper-root": {
              border: `1px solid ${colors.border}`,
              borderRadius: "8px !important",
              boxShadow: `0px 20px 40px ${colors.boxShadow}`,
            },
          },
        },
      },
      MuiFormGroup: {
        styleOverrides: {
          root: {
            padding: "0px 16px",
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          labelPlacementEnd: {
            marginLeft: "0px",
            "& .MuiFormControlLabel-label": {
              height: "26px",
              fontSize: "12px !important",
              display: "flex",
              alignItems: "center",
              color: colors.infoTableHeader,
              fontWeight: "500 !important",
              "& svg": {
                marginRight: "8px",
              },
            },
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            paddingBottom: "0px",
            paddingTop: "0px",
          },
        },
      },
    },
  });

  return (
    <Box className={styles.buttonsWrapper}>
      <ThemeProvider theme={settingsTheme}>
        <Menu
          anchorEl={settingsAnchorEl}
          open={settingsOpen}
          transformOrigin={{
            horizontal: 200,
            vertical: 0,
          }}
          onClose={handleSettingsClose}
        >
          <Box key="title" className={styles.Grid_Toolbar_Settings_Title}>
            Table Columns
          </Box>
          <Box className={styles.DragDropContext}>
            <DragDropContext onDragEnd={onSettingsDragEnd}>
              <Droppable droppableId="droppable-list">
                {(provided, snapshot) => {
                  return (
                    <FormGroup
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {orderedColumns.map(
                        (header, i) =>
                          header.label != null &&
                          header.label != "" && (
                            <Draggable
                              key={i}
                              index={i}
                              draggableId={i.toString()}
                            >
                              {(provided, snapshot) => {
                                return (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    style={{
                                      ...provided.draggableProps.style,
                                    }}
                                  >
                                    <Box
                                      className={
                                        styles.Grid_Toolbar_Settings_Item
                                      }
                                    >
                                      <Box
                                        className={
                                          styles.Grid_Toolbar_Settings_Item_Actions
                                        }
                                      >
                                        <FormControlLabel
                                          control={
                                            <>
                                              <CheckboxButton
                                                style={{
                                                  width: 28,
                                                  height: 28,
                                                }}
                                                height={16}
                                                width={16}
                                                name={header.id}
                                                checked={!header.hide}
                                                onChange={
                                                  handleSettingsCheckedChange
                                                }
                                              />
                                            </>
                                          }
                                          label={header.label}
                                          labelPlacement={"end"}
                                          key={i}
                                        />
                                        <span
                                          className={
                                            styles.Grid_Toolbar_Settings_Pin_Button
                                          }
                                          onClick={handleOnPin}
                                          data-name={header.id}
                                        >
                                          {header.pin ? (
                                            <PinnedIcon />
                                          ) : (
                                            <PinIcon />
                                          )}
                                        </span>
                                      </Box>
                                      <Box
                                        className={
                                          styles.Grid_Toolbar_Settings_Item_Move
                                        }
                                        {...provided.dragHandleProps}
                                      >
                                        <MoveIcon />
                                      </Box>
                                    </Box>
                                  </div>
                                );
                              }}
                            </Draggable>
                          )
                      )}
                      {provided.placeholder}
                    </FormGroup>
                  );
                }}
              </Droppable>
            </DragDropContext>
          </Box>

          <Box
            key="applyButton"
            className={styles.Grid_Toolbar_Settings_Buttons}
          >
            <CustomizedButton
              width={"296px"}
              height={"40px"}
              backgroundColor={"rgb(253, 88, 31)"}
              color={"#FFFFFF"}
              borderRadius={"4px"}
              text={"Save Settings"}
              fontWeight={"600"}
              hoverBgColor={"rgb(252, 48, 31)"}
              onClick={handleSaveSettings}
            />
            <CustomizedButton
              width={"296px"}
              height={"40px"}
              variant={"text"}
              color={"rgb(109, 128, 159)"}
              fontWeight={"600"}
              hoverBgColor={"rgba(25, 118, 210, 0.04)"}
              borderRadius={"200px"}
              onClick={handleRestoreSystemSettings}
              text={"Restore Table Settings"}
            />
          </Box>
        </Menu>
      </ThemeProvider>
    </Box>
  );
}
