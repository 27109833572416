import { Box } from "@mui/material";
import React from "react";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import DateLine from "@alotech/common/components/dateLine/DateLine";

export default function ProgressSection({ barColor, borderColor, title, percentage, remaningTime, totalFile, completedFile, totalSize, completedSize, ftpAdress, startDate, fileName, startTime }) {
	const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
		height: 16,
		borderRadius: 8,
		borderLeft: percentage == 100 || !percentage ? "unset" : borderColor ? `1px solid ${borderColor}` : "1px solid #FD581F",
		[`&.${linearProgressClasses.colorPrimary}`]: {
			backgroundColor: "#f9fafb",
		},
		[`& .${linearProgressClasses.bar}`]: {
			borderRadius: 16,
			backgroundColor: barColor ?? "#fc8961",
			border: borderColor ? `1px solid ${borderColor}` : "1px solid #FD581F"
		},
	}));
	return (
		<Box sx={{ display: "flex", flexDirection: "column", fontSize: "12px", color: "#6D809F" }}>
			<Box sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
				{title && <span style={{ color: "#314469", fontSize: "14px", fontWeight: 600 }}>{title}</span>}
				<Box sx={{ display: "flex", gap: "15px", marginBottom: "15px" }}>
					<Box sx={{ display: "flex", flexDirection: "column", gap: "7px" }}>
						{ftpAdress && <span style={{ color: "#314469", fontWeight: "500", height: "20px" }}>FTP Adress:</span>}
						{(startDate || startTime) && <span style={{ color: "#314469", fontWeight: "500", height: "20px" }}>Start Date:</span>}
						{fileName && <span style={{ color: "#314469", fontWeight: "500", height: "20px" }}>File Name:</span>}
					</Box>
					<Box sx={{ display: "flex", flexDirection: "column", gap: "7px" }}>
						{ftpAdress && <span style={{ color: "#6D809F", fontWeight: "600", height: "20px" }}>{ftpAdress}</span>}
						{(startDate || startTime) && <span style={{ color: "#6D809F", fontWeight: "600", height: "20px" }}><DateLine date={startDate} time={startTime} /></span>}
						{fileName && <span style={{ color: "#6D809F", fontWeight: "600", height: "20px" }}>{fileName}</span>}
					</Box>

				</Box>
			</Box>
			<Box sx={{ display: "flex", flexDirection: "column" }}>
				<Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
					<Box sx={{ flex: "1" }}>
						<BorderLinearProgress variant="determinate" value={percentage} />
					</Box>
					<Box sx={{ marginTop: "2px", color: "#6D809F", fontWeight: 600, fontSize: "14px" }}>{percentage ?? "- "} %</Box>
				</Box>
				<Box sx={{ display: "flex", marginLeft: "auto", gap: "30px", marginTop: "9px", marginRight: "49px" }}>
					{remaningTime && <span>{`${remaningTime} Remaining`}</span>}
					{completedFile && totalFile && <span>{`${completedFile} ${!completedSize ? "MB" : ""}/ ${totalFile} ${!completedSize ? "MB" : ""}`}</span>}
					{completedSize && totalSize && <span>{`${completedSize} MB / ${totalSize}`} MB</span>}
				</Box>
			</Box>
		</Box>
	)
}
