import React from "react";
import InputField from "./InputField";

export default function OutlinedInput(props) {
  const inputProps = {
    fullWidth: true,
    variant: "outlined",
    textmargin: "10px 0",
  };

  const allProps = { ...props };

  if (allProps.tabIndex) {
    inputProps["tabIndex"] = allProps.tabIndex;
    allProps.tabIndex = null;
  }

  return <InputField {...inputProps} {...allProps} />;
}
