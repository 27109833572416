import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

export default function RuleSelection({
  selectedRuleData,
}) {
  const fields = [
    {
      label: "Profile Name",
      value: selectedRuleData.profileName,
    },
    {
      label: "FTP Adress",
      value: selectedRuleData.host,
    },
    {
      label: "FTP Port",
      value: selectedRuleData.port,
    },
    {
      label: "FTP Username",
      value: selectedRuleData.username,
    },
    {
      label: "FTP Password",
      value: selectedRuleData.password,
    },
    {
      label: "FTP Path",
      value: selectedRuleData.path,
    },
  ];
  return (
    <Box sx={{ paddingX: "64px", marginTop: "44px", marginBottom: "32px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginTop: "32px",
          opacity: Object.keys(selectedRuleData).length === 0 ? "0.4" : "1",
        }}
      >
        {fields.map((element, i) => {
          return (
            <Box
              key={i}
              sx={{
                display: "flex",
                height: "44px",
                borderTop: "1px solid #f5f6f7",
                alignItems: "center",
                borderBottom:
                  i + 1 == fields.length ? "1px solid #f5f6f7" : "unset",
              }}
            >
              <Box
                sx={{
                  flex: 0.5,
                  color: "#314469",
                  fontSize: "12px",
                  fontWeight: 500,
                }}
              >
                {element.label}:
              </Box>
              <Box
                sx={{
                  flex: 2,
                  color: "#6D809F",
                  fontSize: "12px",
                  fontWeight: 600,
                }}
              >
                {element.value ?? "-"}
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
