//-------------------------------------------------------------------------------
// Copyright © 2022 Valven. - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and Confidential
//-------------------------------------------------------------------------------

import React from 'react'
import styles from './Link.module.scss'
import { InputField } from '.';
import { IconButton } from "@mui/material";
import Box from '@mui/material/Box';
import CustomButton from './CustomButton';
import { strings } from '@alotech/common/components/i18n';
import { ReactComponent as AddIcon } from '../../static/img/common/add.svg';
import { ReactComponent as DeleteIcon } from '../../static/img/ac_delete_32.svg';


export default function LinkV(props) {

    const { onChange } = props;
    const [values, setValues] = React.useState(props.values ?? []);

    React.useEffect(() => {
        onChange({
            "type": "link",
            "values": values
        })
    }, [values]);

    const onCreate = () => {
        setValues([...values, {
            "value0": null,
            "value1": null
        }]);
    }

    const onDelete = (index) => {
        const tmp = [...values];
        tmp.splice(index, 1);
        setValues(tmp);
    }

    const onInputChange = (ev, i, key) => {
        const tmp = [...values];
        let item = { ...tmp[i] };
        item[key] = ev.target.value;
        tmp[i] = item;

        setValues(tmp);
    }

    return (
        <>
            {values?.map(function (value, i) {
                return (
                    <Box key={i} className={styles.Link_Row}>
                        <Box>
                            <LinkInput onChange={(ev) => { onInputChange(ev, i, "value0") }} value={value.value0} inputLabel={strings.link_parameter_name} />
                            <LinkInput onChange={(ev) => { onInputChange(ev, i, "value1") }} value={value.value1} inputLabel={strings.link_html} />
                        </Box>
                        <Box>
                            <IconButton onClick={() => { onDelete(i) }}>
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                    </Box>
                )
            })}
            <CustomButton onClick={onCreate} className={styles.Link_AddButton} startIcon={<AddIcon />} size="medium" variant="outlined" color="primary">{strings.add_link}</CustomButton>
        </>
    )
}

function LinkInput({ value, inputLabel, onChange }) {
    return (
        <Box className={styles.Link_FieldWrapper}>
            <InputField
                fullWidth
                onChange={onChange}
                value={value ?? ""}
                label={inputLabel}
                key="input"
                type="text"
                variant="outlined" />
        </Box>
    )
}