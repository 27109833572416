import React from "react";
import { Box, Modal } from "@mui/material";
import { ReactComponent as CloseIcon } from "@alotech/common/static/img/closeIcon.svg";
import { ReactComponent as DangerSign } from "@alotech/common/static/img/dangerSign.svg";

export default function RuleDialog({
	open,
	setOpen,
	problemModal,
	handleClose,
}) {
	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
	};


	return (
		<Modal
			onClose={handleClose}
			open={open}
			disableAutoFocus={true}
			disableEnforceFocus
		>
			<Box sx={{ display: "flex", flexDirection: "column", backgroundColor: "#FFFFFF", width: "650px", height: "350px", borderRadius: "8px", ...style }}>
				<Box sx={{ display: "flex", paddingLeft: "24px", justifyContent: "space-between", paddingRight: "24px", marginTop: "24px" }}>
					<Box sx={{ display: "flex", flexDirection: "column" }}>
						<span style={{ color: "#002983", fontSize: "20px", fontWeight: 500 }}>Problem Details</span>
						<span style={{ color: "#F02833", fontSize: "12px", fontWeight: 600 }}>{`${!!problemModal && problemModal?.problems.length} Problems Detected`}</span>
					</Box>
					<CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
				</Box>
				<Box sx={{ display: "flex", flexDirection: "column", height: "100%", paddingX: "24px", marginTop: "24px", overflowY: "auto", gap: "8px", marginBottom: "8px" }}>
					{
						problemModal && problemModal.problems.map((element, i) => {
							return (
								<Box key={i} sx={{ display: "flex", width: "500px", backgroundColor: "#f9fafb", borderRadius: "8px", alignItems: "center", justifyContent: "space-between" }}>
									<Box sx={{ display: "flex", paddingLeft: "16px", alignItems: "center", gap: "16px" }}>
										<DangerSign />
										<span style={{ color: "#314469", fontSize: "14px", fontWeight: 500, maxWidth: "400px" }}>{element}</span>
									</Box>
								</Box>
							)
						})
					}

				</Box>


			</Box>
		</Modal>
	)
}
