import React, { useState, useEffect, useCallback } from "react";
import { Box } from "@mui/material";

import { EnhancedTablePro } from "@alotech/common/components/grid/EnhancedTable";
import TableFiltersTab from "@alotech/common/components/tableFilterTabs/TableFiltersTab";
import { Alert } from "@alotech/common/components/alert";
import DraggableColumns from "@alotech/common/components/draggableColumns/DraggableColumns";
import { arraysAreEqual } from "@alotech/common/utils/Utils";
import ProblemModal from "../problemModal/ProblemModal";
import ItemHeader from "@alotech/common/components/itemHeader/ItemHeader";
import axios from "axios";

export default function DownloadHistory({ ftpProfile, refresh }) {
  const [data, setData] = useState([]);
  const [rows, setRows] = useState([]);
  const [pageSize, setPageSize] = useState(13);
  const [rowCount, setRowCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [problemModalOpen, setProblemModal] = useState(false);
  // const [id, setId] = useState(null);
  const storedName = "downloadHistory";
  const [orderedColumns, setOrderedColumns] = React.useState([]);
  const [settingsAnchorEl, setSettingsAnchorEl] = React.useState(null);
  const [columns, setColumns] = useState([]);

  const [downloadResult, setDownloadResult] = useState([
    { id: 1, label: "Success", checked: false },
    { id: 2, label: "Cancelled", checked: false },
    { id: 3, label: "Problem", checked: false },
  ]);
  const [recordType, setRecordType] = useState([
    { id: 1, label: "Call", checked: false },
    { id: 2, label: "Video Call", checked: false },
  ]);
  const [ftpAddress, setFtpAddress] = useState([]);
  const [user, setUser] = useState([]);


  const updateFtpAddressFunc = useCallback(
    (filteredArr) => {
      !arraysAreEqual(ftpAddress, filteredArr) && setFtpAddress(filteredArr);
    },
    [ftpAddress]
  );

  // const updateUserFunc = useCallback(
  //   (filteredArr) => {
  //     !arraysAreEqual(user, filteredArr) && setUser(filteredArr);
  //   },
  //   [user]
  // );

  // const updateRecordTypeFunc = useCallback(
  //   (filteredArr) => {
  //     !arraysAreEqual(recordType, filteredArr) && setRecordType(filteredArr);
  //   },
  //   [recordType]
  // );

  const updateDownloadResultFunc = useCallback(
    (filteredArr) => {
      !arraysAreEqual(downloadResult, filteredArr) &&
        setDownloadResult(filteredArr);
    },
    [downloadResult]
  );


  const handleSettingsClick = (event) => {
    setSettingsAnchorEl(event.currentTarget);
  };
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    getData(ftpAddress, user, recordType, downloadResult);
  }, [ftpAddress, user, recordType, downloadResult]);

  let headers = [
    {
      id: "ftp_address",
      label: "FTP Address",
      minWidth: 150,
      flex: 1,
      type: "string",
    },
    // {
    //   id: "user",
    //   label: "User",
    //   minWidth: 100,
    //   flex: 1,
    //   type: "string",
    // },
    {
      id: "record_type",
      label: "Record Type",
      type: "recordType",
      flex: 1,
      minWidth: 150,
    },
    {
      id: "download_result",
      label: "Download Result",
      type: "downloadResult",
      flex: 1,
      minWidth: 150,
      callback: (cellValues) => {
        if (cellValues.formattedValue.toLocaleLowerCase().includes("problem")) {
          setProblemModal(cellValues.row);
        }
      },
    },
    {
      id: "start_date",
      label: "Start Date",
      minWidth: 180,
      flex: 1,
      type: "dateWithIcons",
    },
    {
      id: "end_date",
      label: "End Date",
      minWidth: 180,
      flex: 1,
      type: "dateWithIcons",
    },
    {
      id: "record_amt",
      label: "Record Amt.",
      type: "string",
      flex: 1,
      minWidth: 140,
    },
    {
      id: "record_size",
      label: "Record Size (MB)",
      type: "string",
      flex: 1,
      minWidth: 140,
    },
  ];
  useEffect(() => {
    if (!ftpProfile.id) return;
    axios.get("/res/getLogs/", {
      params: {
        "id": ftpProfile.id
      }
    },
    )
      .then((res) => {
        const data = res.data.response.data;
        setData(data.sort((a, b) => new Date(b.start_date) - new Date(a.start_date)).map((item) => {
          return {
            id: item.id,
            ftp_address: item.ftp_address,
            user: item.username || "-",
            record_type: item.record_type,
            download_result: item.download_result === "Success with Problems" ? item.problems.length + " Problems" : item.download_result,
            start_date: item.start_date,
            end_date: item.end_date,
            record_amt: item.record_amt,
            record_size: (+item?.record_size)?.toFixed(2),
            problems: item.problems,
          };
        }));
        setUser(data.filter((item, index, self) => {
          if (item.username) {
            return index === self.findIndex((t) => (
              t.username === item.username
            ))
          }
        }).map((item) => {
          return {
            id: Math.random(),
            label: item.username,
            checked: false,
          }
        }));
        setFtpAddress(data.filter((item, index, self) =>
          index === self.findIndex((t) => (
            t.ftp_address === item.ftp_address
          ))
        ).map((item) => {
          return {
            id: Math.random(),
            label: item.ftp_address,
            checked: false,
          }
        }));
        setRowCount(data.length);
        setLoading(false);
      }).catch((err) => {
        console.log(err);
      })
  }, [ftpProfile, refresh]);
  const getData = () => {
    setLoading(true);
    const isFilter =
      ftpAddress.filter((ftp) => ftp.checked).length > 0 ||
      user.filter((user) => user.checked).length > 0 ||
      recordType.filter((recordType) => recordType.checked).length > 0 ||
      downloadResult.filter((downloadResult) => downloadResult.checked).length > 0;
    const filteredData = data.filter((item) => {
      return (
        (ftpAddress.filter((ftp) => ftp.checked).length > 0
          ? ftpAddress
            .filter((ftp) => ftp.checked)
            .some((ftp) => {
              return ftp.label === item.ftp_address;
            })
          : true) &&
        (user.filter((user) => user.checked).length > 0
          ? user
            .filter((user) => user.checked)
            .some((user) => {
              return user.label === item.user;
            })
          : true) &&
        (recordType.filter((recordType) => recordType.checked).length > 0
          ? recordType
            .filter((recordType) => recordType.checked)
            .some((recordType) => {
              return recordType.label.toLowerCase() == item?.record_type?.toLowerCase();
            })
          : true) &&
        (downloadResult.filter((downloadResult) => downloadResult.checked).length > 0
          ? downloadResult
            .filter((downloadResult) => downloadResult.checked)
            .some((downloadResult) => {
              return item.download_result.includes(downloadResult.label);
            })
          : true)
      );
    });
    const selectedData = isFilter ? filteredData : data;
    setRows(selectedData);
    setRowCount(selectedData.length);
    setLoading(false);
  };

  useEffect(() => {
    //settingColumns
    var storedTabOrder = JSON.parse(localStorage.getItem(storedName));
    if (storedTabOrder) {
      const changedArr = [];
      if (!storedTabOrder.some(e => e.order)) {
        storedTabOrder = storedTabOrder.map((e, index) => {
          return { ...e, order: index }
        })
      }
      headers.forEach(element => {
        const storedElement = storedTabOrder.find(e => e.id === element.id);
        if (storedElement) {
          changedArr.push({ ...element, hide: storedElement.hide, pin: storedElement.pin, order: storedElement.order })
        } else {
          changedArr.push({ ...element, hide: false, pin: false, order: 99 })
        }
      })
      changedArr.sort((a, b) => {
        if (a.order > b.order) return 1;
        if (a.order < b.order) return -1;
        return 0;
      })
      setOrderedColumns(changedArr);
      onSettingsChanged(changedArr)
    } else {
      setOrderedColumns(headers)
      onSettingsChanged(headers)
    }
  }, []);
  const onSettingsChanged = (newHeader) => {
    setColumns(
      newHeader.map((column) => {
        if (column.id === 'action') {
          return {
            ...column,
            actions: getActions(),
          };
        }
        return column;
      })
    );
  }
  useEffect(() => {
    onSettingsChanged(orderedColumns);
  }, [rows]);

  const handlePageSizeChange = (event, newSize) => {
    const newPageSize = newSize.props.value;
    setPageSize(newPageSize);
  };

  function handleColumnOrderChange(params, event, details) {
    const newColumns = [...columns];
    const newOrdered = [...orderedColumns]
    newColumns[params.targetIndex] = columns[params.oldIndex]
    newColumns[params.oldIndex] = columns[params.targetIndex]

    newOrdered[params.targetIndex] = { ...orderedColumns[params.oldIndex], order: params.targetIndex }
    newOrdered[params.oldIndex] = { ...orderedColumns[params.targetIndex], order: params.oldIndex }
    setOrderedColumns(newOrdered);
    onSettingsChanged(newColumns);
    localStorage.setItem(storedName, JSON.stringify(newOrdered));
  }

  return (
    <Box
      sx={{
        border: "1px solid rgb(204, 220, 230)",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white",
        borderRadius: "8px",
        overflow: "hidden",
        marginTop: "8px",
        marginBottom: "16px",
      }}
    >
      <ItemHeader title={"Download History"} />
      <TableFiltersTab
        ftpAddress={ftpAddress}
        // user={user}
        // recordType={recordType}
        downloadResult={downloadResult}
        updateFtpAddressFunc={updateFtpAddressFunc}
        // updateUserFunc={updateUserFunc}
        // updateRecordTypeFunc={updateRecordTypeFunc}
        updateDownloadResultFunc={updateDownloadResultFunc}
        isTableFilter={true}
        setSettingsAnchorEl={setSettingsAnchorEl}
      />
      <Box
        sx={{
          height: "515px !important",
          backgroundColor: "white",
          borderRadius: "0 0 5px 5px",
          boxShadow: "0px 1px 1px #0000000D",
          borderTop: "none",
          boxSizing: "border-box",
        }}
      >
        <EnhancedTablePro
          onColumnOrderChange={handleColumnOrderChange}
          enableColumnReorder={true}
          loading={loading}
          rows={rows}
          getRowId={(row) => row.id}
          rowCount={rowCount}
          rowsPerPageOptions={[13, 25, 50, 100]}
          pageSize={pageSize}
          headers={columns}
          noBorder
          onPageSizeChange={handlePageSizeChange}
          smallPagination
          fontWeight={600}
          fontSize={"12px"}
          customRowHeight={32}
          paddingSize={"16px"}
          paddingSizeHeader={"16px"}
          paginationMode="client"
          sortingMode="client"
        />
      </Box>

      <Alert
        message={alertMessage}
        severity={
          alertSuccess === true
            ? "success"
            : alertSuccess === "warning"
              ? "warning"
              : "error"
        }
        closable={true}
        open={alertOpen}
        onClose={() => {
          setAlertOpen(false);
        }}
      />
      {settingsAnchorEl && (
        <DraggableColumns
          headers={headers}
          onSettingsChanged={onSettingsChanged}
          storedName={storedName}
          orderedColumns={orderedColumns}
          setOrderedColumns={setOrderedColumns}
          handleSettingsClick={handleSettingsClick}
          settingsAnchorEl={settingsAnchorEl}
          setSettingsAnchorEl={setSettingsAnchorEl}
        />
      )}
      <ProblemModal
        problemModal={problemModalOpen}
        open={problemModalOpen}
        setOpen={setProblemModal}
        handleClose={() => {
          setProblemModal(false);
        }}
      />
    </Box>
  );
}
