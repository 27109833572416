import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import axios from "axios";
//Mui
import { Box } from '@mui/material';
//Components
import NewModal from "../../../ftp-transfer/src/components/NewModal";
import { Form } from '@alotech/common/components/form'
import { Alert } from '@alotech/common/components/alert'
//Data
import configData from "../../../ftp-transfer/src/config.json"
//Css
import commonStyles from './LoginPage.module.scss';
import OtpQrModal from 'src/components/OtpQrModal';

export default function LoginPage({ isOpenAdminModal, setIsLogin }) {

    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [message, setMessage] = useState();
    const [openOTP, setOpenOTP] = useState(false);
    const [otpTrue, setOtpTrue] = useState(true);
    const [loading, setLoading] = useState();
    const [otpMessage, setOTPMessage] = useState();
    const [alertOpen, setAlertOpen] = useState(false);
    const [loginCheckDisable, setLoginCheckDisable] = useState(false);
    const [otpLoginDisable, setOtpLoginDisable] = useState(false);
    const [openQr, setOpenQr] = useState(false);
    const [qrContent, setQrContent] = useState("");
    const [tenantName, setTenantName] = useState("");

    let fields = [
        {
            type: "text",
            name: "tenant",
            outlined: true,
            required: true,
            label: "Tenant"
        }, 
        {
            type: "email",
            name: "email",
            outlined: true,
            required: true,
            label: "Email"
        }, 
        {
            type: "password",
            name: "password",
            outlined: true,
            required: true,
            label: "Password"
        }, 
        // {
        //     type: "forgotPasswordLink",
        //     name: "forgotPasswordLink",
        //     send_mail_to_created_user: send_mail_to_created_user
        // },
      /*  {
            type: "captcha",
            name: "verify",
            required: true,
            onChange: function(event) {
                console.log(event);
            }
        }*/
    ]

    const navigate = useNavigate();

    const onSignupClick = function () {
        navigate("/signup");
    }

    const onFormSubmit = function (ev, data) {
        if(loginCheckDisable) {
            return;
        }
        setTenantName(data.tenant);
        setLoginCheckDisable(true);
        setEmail(data.email);
        setPassword(data.password);
        axios({
            method: "POST",
            url: `https://${data.tenant}/api/`,
            params: {
              function: "login_check"
            },
            data: {
                email: data.email,
                password: data.password
            },
            headers: { "Content-Type": "multipart/form-data" },

        }).then(res => {
            if (res.data.login_check === true) {
                if (res.data.otp) {
                    if (res.data.otp_method == 'otp_app'){
                        setOpenQr(true);
                        res.data.otp_uri ? setQrContent(res.data.otp_uri) : setQrContent("")
                    } else {
                        setOpenOTP(true);
                    }
                } else {
                    login(data.email, data.password, data.tenant);
                }
             
            } else {
                if (res.data.error_code === "1") {
                    setMessage(res.data.message);
                    setAlertOpen(true);
                } else if (res.data.error_code === "2") {
                    setMessage(res.data.message);
                    setAlertOpen(true);
                } else if (res.data.error_code === "3") {
                    setMessage(res.data.message);
                    setAlertOpen(true);
                } else if (res.data.error_code === "4") {
                    setMessage(res.data.message);
                    setAlertOpen(true);
                } else if (res.data.error_code === "5") {
                    setMessage(res.data.message);
                    setAlertOpen(true);
                }
                if (!!res.data.ban) {
                    setMessage( '{{ _("This user is banned due to unsuccessful login attemps. ") }}' + '{{ _("Please, contact your system administrator to login!") }}');
                    setAlertOpen(true);
                }
              }
        }).catch(err => {
            setMessage('{{ _("An error occurred during login") }}');
            setAlertOpen(true);
            console.error(err);
        }).finally(() => {
            setLoginCheckDisable(false);
        });
    }

    const request_otp_code = () => {
        axios({
          method: "GET",
          url: `https://${tenantName}/api/?`,
          params: {
            function: "request_otp_code",
            email
          }
        }).then(res => {
            if (!res.data.success && res.data.message) {
                setMessage(res.data.message);
                setAlertOpen(true);
            } else {
                console.log(res);
            }
        }).catch(err => {
            console.error(err);
        });
    }
    function login(userEmail, userPassword, tenantName) {
        axios({
            method: "POST",
            url: `https://${tenantName}/api/`,
            params: {
                function: "login"
            },
            data: {
                email: userEmail,
                password: userPassword
            },
            headers: { "Content-Type": "multipart/form-data" },

        }).then(res => {
            if (res.data.login === false) {
                setMessage(res.data.message);
                setAlertOpen(true);
            } else {
                // axios({
                //     method: "GET",
                //     url: `https://${tenantName}/api/?function=privilidges`,
                // }).then(response => {
                //     let data = response.data
                //     if(!data.supervisor && !data.login){
                //         setMessage('{{ _("An error occurred during login") }}');
                //         setAlertOpen(true);
                //     }else{
                //         document.cookie = `tenantName=${tenantName}`
                //         setIsLogin(true);
                //         navigate("/dashboard");
                //     }
                // }).catch((error) => console.log(error));
                document.cookie = `tenantName=${tenantName}`
                setIsLogin(true);
                localStorage.setItem("isLogin", true);
                navigate("/dashboard");
            }
        }).catch(err => {
            console.error(err);
        });
    }

    function handleOTPLogin(otp) {
        if(otpLoginDisable){
            return;
        }
        setOtpLoginDisable(true);

        axios({
            method: "POST",
            url: `https://${tenantName}/api/`,
            params: {
                function: "login"
            },
            data: {
                email: email,
                password: password,
                otp:otp
            },
            headers: { "Content-Type": "multipart/form-data" },
        }).then(res => {
            if (res.data.login === false) {
                setMessage(res.data.message);
                setAlertOpen(true);
            } else {
                // axios({
                //     method: "GET",
                //     url: `https://${tenantName}/api/?function=privilidges`,
                // }).then(response => {
                //     let data = response.data
                //     if(!data.supervisor && !data.login){
                //         setMessage('{{ _("An error occurred during login") }}');
                //         setAlertOpen(true);
                //     }else{
                //         document.cookie = `tenantName=${tenantName}`
                //         setIsLogin(true);
                //         navigate("/dashboard");
                //     }
                // }).catch((error) => console.log(error));
                document.cookie = `tenantName=${tenantName}`
                setIsLogin(true);
                localStorage.setItem("isLogin", true);
                navigate("/dashboard");
            }
        }).catch(err => {
            console.error(err);
        }).finally(() => {
            setOtpLoginDisable(false);
        });
    }

    const clickOtpOkButton = (otp) => handleOTPLogin(otp);
    const clickOtpCancelButton = () => setOpenOTP(false);
    
    // OTP QR Modal
    const clickOtpQrOkButton = (otp) => handleOTPLogin(otp);
    const clickOtpQrCancelButton = () => setOpenQr(false);

    // Admin Modal
    const clickAdminModalOkButton = () => navigate("/");
    const clickAdminModalCancelButton = () => navigate("/");


    return (
        <>
            {/* Admin Modal - logindenied */}
            <NewModal 
                openNewModal={isOpenAdminModal}
                OTPModal={false}
                clickOkButton={clickAdminModalOkButton}
                clickCancelButton={clickAdminModalCancelButton}
                okButton={true}
                cancelButton={false}
                title='{{ _("Login Denied") }}'
                content='{{ _("Login with /admin is disabled for this tenant.") }}'
            />
            {/* OTP Modal */}
            <NewModal 
                openNewModal={openOTP}
                OTPModal={true}
                clickOkButton={clickOtpOkButton}
                clickCancelButton={clickOtpCancelButton}
                okButton={true}
                cancelButton={true}
                request_otp_code={request_otp_code}
                title="Verification Code"
                content="Please enter the verification code sent to your Phone or E-Mail address"
            />
            {/* OTP QR Modal */}
            <OtpQrModal 
                openNewModal={openQr}
                clickOkButton={clickOtpQrOkButton}
                clickCancelButton={clickOtpQrCancelButton}
                qrUri={qrContent}
            />
                <Form 
                    onFormSubmit={onFormSubmit} 
                    fields={fields} 
                    submitButtonLabel={"Login"}
                    submitProps={{ className: commonStyles.LoginCommon_Button, size: "large" }} 
                />
                <Alert message={!otpTrue ? otpMessage : message} severity="error"
                    closable={true} open={alertOpen} autoHideDuration={message && 6000}
                    onClose={() => {
                        setAlertOpen(false)
                    }} />

        </>
    )
}