//-------------------------------------------------------------------------------
// Copyright © 2022 Valven. - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and Confidential
//-------------------------------------------------------------------------------

import React, { useState } from "react";
import CustomButton from './CustomButton'
import FormGroup from '@mui/material/FormGroup';
import FormField from './FormField'

export default function FormComponent({ fields, className, formID, submitProps, submitButtonLabel, onFormSubmit, children, formRef, fieldMaxWidth, fieldPadding }) {
    const [formData, _setFormData] = useState({})

    const formDataRef = React.useRef(formData);
    const setFormData = data => {
        formDataRef.current = data;
        _setFormData(data);
    };

    var typingTimer;
    var doneTypingInterval = 150;

    React.useEffect(() => {
        for (let i = 0; i < fields?.length; i++) {
            let q = fields[i]
            // set initial values
            if (q.value) {
                formData[q.name] = q.value
            }
        }
        console.info(formData)
    }, []);

    const handleChange = (ev) => {
        const doneTyping = () => {
            let data = { ...formDataRef.current }
            data[name] = value
            setFormData(data);
        }
        if (ev instanceof Object && ev["type"]) {
            if (ev.type == "link") {
                setFormData({...formDataRef.current, [ev.type]:ev.values});
                return
            } else if (ev.type == "ifclause") {
                setFormData({...formDataRef.current, [ev.type]:ev.values});
                return
            } else if (ev.type == "step_rules") {
                setFormData({...formDataRef.current, [ev.type]:ev.values});
                return
            } else if (ev.type == "slide") {
                return
            }
        }
        const { value, name, type, checked } = ev.target;
        if (type == "checkbox" || type == "radio") {
            formDataRef.current[name] = checked;
            setFormData({ ...formDataRef.current, [name]: checked });
        } else if (type == "captcha") {
            formDataRef.current[name] = value;
            setFormData({ ...formDataRef.current, [name]: value });
        } else {
            formDataRef.current[name] = value;
            clearTimeout(typingTimer);
            typingTimer = setTimeout(doneTyping, doneTypingInterval);
        }

    }

    let formValid = true
    for (let i = 0; i < fields?.length; i++) {
        let q = fields[i]
        if (!formData[q.name] && q.required) {
            formValid = false
            break
        }
    }

    var items = fields?.map(function (field, l) {
        return (
            <FormField key={l} data={field} fieldMaxWidth={fieldMaxWidth} fieldPadding={fieldPadding} onChange={(ev) => {
                handleChange(ev)
                if (field.onChange) {
                    field.onChange(ev)
                }
            }} />
        )
    })

    if (items && items.length) {
        if (submitButtonLabel) {
            items.push(
                <CustomButton key="submit" size="medium" variant="contained" disabled={formValid ? false : true}
                    color="primary" fullWidth={false} type="submit" {...submitProps}>{submitButtonLabel}</CustomButton>
            )
        }
    }

    return (
        <form id={formID} ref={formRef} onSubmit={(ev) => {
            ev.preventDefault();
            if (onFormSubmit) onFormSubmit(ev, formData);
            return false
        }} className={className}>
            <FormGroup>
                {items && items.length ? items : children}
            </FormGroup>
        </form>
    )
}
