import * as React from "react";
import { Box, Button, Link } from "@mui/material";
import logoCcs from "../../static/img/CCS.svg";
import logoAlotech from "../../static/img/alotech_logowhite1.svg";
import logout from "../../static/img/logout.svg";
import { useState } from "react";
import CustomModal from "../customModal/CustomModal";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Alert } from '@alotech/common/components/alert'

const Sidebar = React.memo(() => {
  const navigate = useNavigate();
  const tenantName = localStorage.getItem("tenantName");
  const [backModalOpen, setBackModalOpen] = useState(false);
  const [ message, setMessage ] = useState("");
  const [ alertOpen, setAlertOpen ] = useState(false);
  function resetModal() {
    setBackModalOpen(false);
  }
  function submitClick() {
    localStorage.setItem("isLogin", false);
    navigate("/");
    

  //   axios({
  //     method: "POST",
  //     url: `http://${tenantName}/api/`,
  //     params: {
  //       function: "logout"
  //     },
  // }).then(res => {
  //   if(res.data.logout === true){
  //     setIsLogin(false);
  //     navigate("/");
  //   } else {
  //     setAlertOpen(true);
  //     setMessage(res.data.message);
  //   }
  // }).catch(err => {
  // }).finally(() => {
  // });
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "80px",
        }}
      >
        <Link href="/">
          <img
            src={logoCcs}
            alt="logo"
            style={{
              height: "60px",
              width: "62px",
              maxWidth: "100%",
              margin: "46px 0 16.5px",
            }}
          />
        </Link>
      </Box>
      <Box m={"0 0 16.5px"} sx={{ display: "flex", flexDirection: "column" }}>
        <Button
          variant="raised"
          color="primary"
          sx={{
            p: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "56px",
            padding: "6px 11px",
            width: "110px",
            margin: "0 auto 0 auto",
            border: "solid 1px #74d6f052",
            backgroundColor: "#314b88",
            padding: "16px 0 30px 0",
            width: "68px",
            height: "108px",
            border: "solid 1px #74d6f052",
            backgroundColor: "#314b88",
            padding: "16px 0",
            marginTop: "auto",
          }}
          disableRipple={true}
          onClick={() => setBackModalOpen(true)}
        >
          <img src={logout} />
          <span
            style={{
              color: "#CCDCE6",
              textTransform: "capitalize",
              marginTop: "20px",
              marginBottom: "10px",
              lineHeight: "1",
              fontWeight: "500",
              fontSize: "10px",
              borderRadius: "200px",
              letterSpacing: "0",
            }}
          >
            Sign Out
          </span>
        </Button>
      </Box>
      <CustomModal
        open={backModalOpen}
        setOpen={setBackModalOpen}
        handleClose={resetModal}
        title="Sign Out"
        content="Are you sure you want to sign out?"
        icon={null}
        submitClick={submitClick}
        buttonText1="Cancel"
        buttonText2="Sign Out"
      />
      <Alert 
        message={ message} 
        severity="error"
        closable={true} open={alertOpen} autoHideDuration={message && 6000}
        onClose={() => {
          setAlertOpen(false)
        }} />
    </>
  );
});

export default Sidebar;
