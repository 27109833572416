import React, { useState } from "react";
import Header from "@alotech/common/components/dashboard/DashboardItemHeader";
import { Box, Grid } from "@mui/material";
import styles from "./DashboardPage.module.scss";
import Container from "@alotech/common/components/layout/Container";
import DownloadHistory from "../../components/downloadHistory/DownloadHistory";
import FtpTransfer from "src/components/ftpTransfer/FtpTransfer";
import { useEffect } from "react";
import axios from "axios";
import { useInterval } from "@alotech/common/utils/Utils";
import { getCookie } from "@alotech/common/utils/Utils";

export default function DashboardPage(props) {
  const [ftpProfile, setFtpProfile] = useState({});
  const [countFtp, setCountFtp] = useState(0);
  const [schedule, setSchedule] = useState({});
  const [overallProgress, setOverallProgress] = useState({});
  const [currentFileProgress, setCurrentFileProgress] = useState({});
  const [downloadStarted, setDownloadStarted] = useState(false);
  const [pause, setPause] = useState(false);
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    const tenantName = getCookie("tenantName");

    axios.post("/res/getFTPProfile/", {
      request : {
        "tenant":  tenantName?.split(".")[0]
      }
    },
    {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    }
    ).then((res) => {
      if(res.data.response.status !== "success"){
        return;
      }
      const {data} = res.data.response;
      setFtpProfile(data[0]);
      setCountFtp(data.length);
      axios.post("/res/getSchedule/", 
      {
        request : {
          "id": data[0].id
        }
      },
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
      ).then((res) => {
        const {data} = res.data.response;
        setSchedule(data[0]);
      }).catch((err) => {
        console.log(err);
      });
      getServiceStatus(data[0].id);
      getCurrentFileProgressData(data[0].id);
      getOverallProgressData(data[0].id);
    }).catch((err) => {
      console.log(err);
    });
  }, []);

  const getServiceStatus = (id) => {
    axios.get("/res/getServiceStatus/",
    {
      params : {
        "id": id
      }
    },
    {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    }).then((res) => {
      const {data} = res.data.response;
      if(data[0].status === "Active"){
        setDownloadStarted(false);
      } else if(data[0].status === "Running"){
        setDownloadStarted(true);
        setPause(false);
      } else if(data[0].status === "Paused"){
        setDownloadStarted(true);
        setPause(true);
      } else if(data[0].status === "Cancelled"){
        setDownloadStarted(false);
      } else {
        setDownloadStarted(false);
      }
    }).catch((err) => {
      console.log(err);
    }
    );
  }
  

  const getOverallProgressData = (id) => {
    axios.post("/res/getOverallProcess/", 
    {
      request : {
        "id": id
      }
    },
    {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    }
    ).then((res) => {
      const {data} = res.data.response;
      setOverallProgress(data[0]);
      if(data[0]?.totalNumberOfRecords === data[0]?.totalNumberOfTransferredRecords && data[0]?.totalSize === data[0]?.totalSizeTransferred){
        setDownloadStarted(false);
        setTimeout(() => {
          setRefresh(!refresh);
        }, 10000);
      }
    }).catch((err) => {
      console.log(err);
    }
    );
  }
  const getCurrentFileProgressData = (id) => {
    axios.post("/res/getCurrentFileProcess/", 
    {
      request : {
        "id": id
      }
    },
    {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    }
    ).then((res) => {
      const {data} = res.data.response;
      setCurrentFileProgress(data[0]);
    }).catch((err) => {
      console.log(err);
    }
    );
  }

  useInterval(() => {
    if(downloadStarted){
      getOverallProgressData(ftpProfile.id);
      getCurrentFileProgressData(ftpProfile.id);
    }
  }, 1000 * 5);

  useInterval(() => {
    if(!downloadStarted && Object.keys(ftpProfile).length>0 && Object.keys(schedule).length>0){
      getServiceStatus(ftpProfile.id);
    }
  }, 1000 * 60);

  return (
    <Container>
      <Grid container className={styles.Layout_fullHeight}>
        <Box
          sx={{
            overflowY: "unset",
            padding: "0px 32px",
          }}
          className={styles.Layout_fullHeight + " " + styles.layout}
        >
          <Header title="FTP Transfer" />
          <Grid
            className={styles.Dashboard_Grid_Container}
            item
            container
            direction={"row"}
            xs={12}
            columnSpacing={12 / 12}
          >
            <Grid item xs={12}>
              <FtpTransfer 
                countFtp={countFtp} 
                downloadRule={schedule} 
                setSchedule={setSchedule} 
                active={downloadStarted} 
                setActive={setDownloadStarted} 
                ftpProfile={ftpProfile} 
                currentFileProgress={currentFileProgress} 
                overallProgress={overallProgress} 
                setFtpProfile={setFtpProfile}
                setCountFtp={setCountFtp}
                pause={pause}
                setPause={setPause}
                setRefresh={setRefresh}
                refresh={refresh}
                />
            </Grid>
            <Grid item xs={12}>
              <DownloadHistory ftpProfile={ftpProfile} refresh={refresh} />
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Container>
  );
}
