import React from "react";
import logoAlotech from "@alotech/common/static/img/alotech_logowhite1.svg";
import logoCCS from "@alotech/common/static/img/CCS.svg";
import { Box, Grid, Link } from "@mui/material";
import { Outlet } from "react-router-dom";
import Sidebar from "../sidebar/Sidebar";
import { Button } from "@mui/material";
import logout from "../../static/img/logout.svg";

import styles from "./Layout.module.scss";

const Layout = () => {
  const gridContainer = {
    height: "100%",
    overflowY: "hidden",
  };
  return (
    <>
      <Grid container spacing={0} alignItems="stretch" style={gridContainer}>
        <Grid
          item
          md="auto"
          className={`${styles.sylayout} ${styles.open}`}
          style={{
            width: "80px",
            height: "100%",
            background: "#1e3772",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Sidebar />
        </Grid>
        <Grid
          item
          container
          xs
          sm
          md
          spacing={0}
          style={{
            width: "100%",
            height: "100%",
            position: "unset",
          }}
          justifyContent="flex-start"
          alignItems="stretch"
          direction="column"
        >
          <Grid
            item
            container
            xs
            spacing={0}
            alignItems="stretch"
            sx={{
              height: "calc(100% - 38px)",
              overflowY: "auto",
              background: "#EAF2F4 !important",
            }}
          >
            <Grid item xs={12} md style={{ height: "100%", width: "100%" }}>
              <Outlet />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Layout;
