import React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";

import Checkbox from "@mui/material/Checkbox";

import colors from "../../styles/_export.scss";

import { ReactComponent as PrimaryChecked } from "../../static/img/primary_checked.svg";

const theme = createTheme({
  components: {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          margin: "0 12px 0 0",
          padding: "0px",
        },
        colorPrimary: {
          color: colors.borderField,
          "&.Mui-checked": {
            color: colors.checkedGreen,
            "&.Mui-disabled": {
              opacity: 0.3,
            },
          },
          "&.Mui-disabled": {
            opacity: 0.3,
          },
        },
        colorSecondary: {
          color: "#F02833",
          "&.Mui-checked": {
            color: "#F02833",
          },
        },
        colorInfo: {
          color: "#1271D9",
          "&.Mui-checked": {
            color: "#1271D9",
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          height: "20px",
          width: "20px",
        },
      },
    },
  },
});

const CheckboxButton = React.forwardRef((props, ref) => {
  const { color, width, height, primaryCheckedSize, ...other } = props;

  const PrimaryCheckboxBorder = styled("span")(({ theme }) => ({
    borderRadius: 2,
    width: width ?? 20,
    height: height ?? 20,
    border: `1px solid ${colors.borderField}`,
    backgroundColor: "white",
    // '.Mui-focusVisible &': {
    //     outline: '2px auto rgba(19,124,189,.6)',
    //     outlineOffset: 2,
    // },
    "input:hover ~ &": {
      backgroundColor: colors.hover,
    },
    "input:disabled ~ &": {
      border: "none",
      background: colors.disabled,
    },
  }));

  return (
    <ThemeProvider theme={theme}>
      {!color || color == "primary" ? (
        <Checkbox
          icon={<PrimaryCheckboxBorder />}
          checkedIcon={<PrimaryChecked />}
          {...other}
        />
      ) : (
        <Checkbox color={color} {...other} />
      )}
    </ThemeProvider>
  );
});

export default CheckboxButton;
