import { Box, Divider } from "@mui/material";
import React, { useState } from "react";
import ItemHeader from "@alotech/common/components/itemHeader/ItemHeader";
import { ReactComponent as InfoIcon } from "@alotech/common/static/img/infoIconOrange.svg";
import { ReactComponent as PlusIcon } from "@alotech/common/static/img/plusIcon.svg";
import { ReactComponent as ResumeIcon } from "@alotech/common/static/img/resumeIcon.svg";
import ProgressSection from "../progressSection/ProgressSection";
import CustomTooltip from "@alotech/common/components/customTooltip/CustomTooltip";
import DateLine from "@alotech/common/components/dateLine/DateLine";
import RuleDialog from "../ruleDialog/RuleDialog";
import { GearIcon, StopIcon, CancelIcon } from '@alotech/common/utils/SvgUtil';
import CustomModal from "@alotech/common/components/customModal/CustomModal";
import CreateFtpProfileModal from "../createFtpProfileModal/CreateFtpProfileModal";
import { Alert } from "@alotech/common/components/alert";
import axios from "axios";
import { getCookie } from "@alotech/common/utils/Utils";
import dayjs from "dayjs";

export default function FtpTransfer({ active, downloadRule, setActive, countFtp, ftpProfile, currentFileProgress, overallProgress, setSchedule,  setFtpProfile, setCountFtp, pause, setPause, setRefresh, refresh }) {
  const [hoverChange, setHoverChange] = useState(false);
  const [hoverStop, setHoverStop] = useState(false);
  const [hoverCancel, setHoverCancel] = useState(false);
  const [ruleModalOpen, setRuleModalOpen] = useState(false);
  const [ruleModalEdit, setRuleModalEdit] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [pauseDisabled, setPauseDisabled] = useState(false);

  const [open, setOpen] = useState(false);

  const [alertSuccess, setAlertSuccess] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);

  const getInfoTitle = (date) => {
    const day = dayjs(date, { utc: true }).format("DD/MM/YYYY");
    const time = dayjs(date, { utc: true }).format("HH:mm");
    
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          height: "100%",
          justifyContent: "center",
          gap: "3px",
        }}
      >
        <span style={{ color: "#314469", fontSize: "12px", fontWeight: 500 }}>
          Next download date:
        </span>
        <DateLine date={day} time={time} />
      </div>
    );
  };


  //onClick functions
  function handleChange() {
    setRuleModalEdit(true);
    setRuleModalOpen(true);
  }

  function handleCancel() {
    setCancelModalOpen(true);
  }

  function handlePause() {
    if(pauseDisabled){
      return;
    }
    setPauseDisabled(true);
    const tenantName = getCookie("tenantName");
    axios.post("/res/pauseService/", 
    {
      request : {
        "id": ftpProfile.id,
        "tenant": tenantName?.split(".")[0]
      }
    },
    {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    }
    ).then((res) => {
      if(res.status === 200){
        setPause(true);
        setAlertSuccess(true);
        setAlertMessage("Service paused successfully.");
        setAlertOpen(true);
      } else {
        setAlertSuccess(false);
        setAlertMessage("Service paused failed.");
        setAlertOpen(true);
      }
    }).catch((err) => {
      console.log(err);
    }
    ).finally(() => {
      setPauseDisabled(false);
      setTimeout(() => {
        setRefresh(!refresh);
      }, 10000);
    }
    );
  }
  function handleResume() {
    if(pauseDisabled){
      return;
    }
    setPauseDisabled(true);
    const tenantName = getCookie("tenantName");
    axios.post("/res/runService/", 
    {
      request : {
        "id": ftpProfile.id,
        "tenant": tenantName?.split(".")[0]
      }
    },
    {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    }
    ).then((res) => {
      if(res.status === 200){
        setPause(false);
        setAlertSuccess(true);
        setAlertMessage("Service resumed successfully.");
        setAlertOpen(true);
      } else {
        setAlertSuccess(false);
        setAlertMessage("Service resumed failed.");
        setAlertOpen(true);
      }
    }).catch((err) => {
      console.log(err);
    }
    ).finally(() => {
      setPauseDisabled(false);
      setTimeout(() => {
        setRefresh(!refresh);
      }, 10000);
    }
    );
  }
  function handleCreateRule() {
    setRuleModalEdit(false);
    setRuleModalOpen(true);
  }

  function onClickCancel() {
    if(pauseDisabled){
      return;
    }
    setPauseDisabled(true);
    const tenantName = getCookie("tenantName");
    axios.post("/res/cancelService/", 
    {
      request : {
        "id": ftpProfile.id,
        "tenant": tenantName?.split(".")[0]
      }
    },
    {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    }
    ).then((res) => {
      if(res.status === 200){
        setPause(false);
        setAlertSuccess(true);
        setAlertMessage("Service cancelled successfully.");
        setAlertOpen(true);
        setActive(false);
      } else {
        setAlertSuccess(false);
        setAlertMessage("Service cancelled failed.");
        setAlertOpen(true);
      }
    }).catch((err) => {
      console.log(err);
    }
    ).finally(() => {
      setPauseDisabled(false);
      setCancelModalOpen(false);
      setTimeout(() => {
        setRefresh(!refresh);
      }, 10000);
    }
    );
  }
  return (
    <Box
      sx={{
        border: "1px solid rgb(204, 220, 230)",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white",
        borderRadius: "8px",
        overflow: "hidden",
      }}
    >
      <ItemHeader title={"FTP Progress"} />
      {
        countFtp > 0 ? ( 
          <>
            <Box
              sx={{
                height: "48px",
                backgroundColor: "#f9fafb",
                display: "flex",
                padding: "0px 48px",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
                
              {/* left side */}
              <Box>
                {downloadRule && Object.keys(downloadRule).length > 0 ? (
                  <Box sx={{ display: "flex", alignItems: "center", gap: "9px" }}>
                    <span
                      style={{ fontSize: "12px", color: "#314469", fontWeight: 500, display: "flex", alignItems: "center", gap: "5px" }}
                    >
                      The download rule has been set to{" "}
                      <span style={{ color: "#FD581F", fontWeight: 600, textTransform: "capitalize", display: "flex", alignItems: "center", gap: "6px" }}>
                        {downloadRule?.interval?.toLowerCase()} <DateLine date={dayjs(downloadRule.scheduled_date, { utc: true }).format("DD/MM/YYYY")} time={dayjs(downloadRule.scheduled_date, { utc: true }).format("HH:mm")} />
                      </span>
                    </span>
                    {/* <Box sx={{ marginTop: "5px" }}>
                      <CustomTooltip
                        title={getInfoTitle(downloadRule.scheduled_date)}
                        placement={"bottom"}
                      >
                        <InfoIcon />
                      </CustomTooltip>
                    </Box> */}
                  </Box>
                ) : (
                  <span
                    style={{ fontSize: "12px", color: "#314469", fontWeight: 500 }}
                  >
                    There is no active download rule.
                  </span>
                )}
              </Box>
              {/* right side */}
              <Box sx={{ display: "flex", gap: "15px" }}>
                {downloadRule && Object.keys(downloadRule).length > 0 ? (
                  <>
                   {(pause || !active) && (
                    <Box
                      sx={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        gap: "3px",
                      }}
                      onMouseEnter={() => setHoverChange(true)}
                      onMouseLeave={() => setHoverChange(false)}
                      onClick={handleChange}
                    >
                      <GearIcon isHover={hoverChange} />
                      <span
                        style={{
                          color: hoverChange ? "#FD581F" : "#6D809F",
                          fontSize: "12px",
                          fontWeight: 600,
                          marginTop: "1px",
                        }}
                      >
                        Change
                      </span>
                    </Box>
                  )  }
                    {active ? (
                      <>
                        {pause ? (
                          <Box
                            sx={{
                              cursor: "pointer",
                              display: "flex",
                              alignItems: "center",
                              gap: "3px",
                            }}
                            onClick={handleResume}
                          >
                            <ResumeIcon />
                            <span
                              style={{
                                color: "#FD581F",
                                fontSize: "12px",
                                fontWeight: 600,
                                marginTop: "1px",
                              }}
                            >
                              Resume
                            </span>
                          </Box>
                        ) : (
                          <Box
                            sx={{
                              cursor: "pointer",
                              display: "flex",
                              alignItems: "center",
                              gap: "3px",
                            }}
                            onMouseEnter={() => setHoverStop(true)}
                            onMouseLeave={() => setHoverStop(false)}
                            onClick={handlePause}
                          >
                            <StopIcon isHover={hoverStop} />
                            <span
                              style={{
                                color: hoverStop ? "#FD581F" : "#6D809F",
                                fontSize: "12px",
                                fontWeight: 600,
                                marginTop: "1px",
                              }}
                            >
                              Pause
                            </span>
                          </Box>
                        )}
                        <Box
                          sx={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            gap: "3px",
                          }}
                          onMouseEnter={() => setHoverCancel(true)}
                          onMouseLeave={() => setHoverCancel(false)}
                          onClick={handleCancel}
                        >
                          <CancelIcon isHover={hoverCancel} />
                          <span
                            style={{
                              color: hoverCancel ? "#f02833" : "#6D809F",
                              fontSize: "12px",
                              fontWeight: 600,
                              marginTop: "1px",
                            }}
                          >
                            Cancel
                          </span>
                        </Box>
                      </>
                    ) : null}
                  </>
                ) : (
                  <Box
                    sx={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      gap: "3px",
                    }}
                    onClick={handleCreateRule}
                  >
                    <PlusIcon />
                    <span
                      style={{
                        color: "#FD581F",
                        fontSize: "12px",
                        fontWeight: 600,
                        marginTop: "1px",
                      }}
                    >
                      Create Rule
                    </span>
                  </Box>
                )}
              </Box>
            </Box>
            {/* progress section */}
            <Box sx={{ padding: "32px 48px", opacity: active ? 1 : 0.4 }}>
              <ProgressSection
                percentage={active ?
                  (overallProgress?.totalNumberOfRecords && overallProgress?.totalNumberOfTransferredRecords && +overallProgress?.totalNumberOfRecords != 0 && +overallProgress?.totalNumberOfTransferredRecords != 0) ?
                    (((overallProgress?.totalNumberOfTransferredRecords / overallProgress?.totalNumberOfRecords) * 100).toFixed(2)) 
                    : 0
                  : null } 
                title={"Overall Progress"}
                remaningTime={active ? overallProgress?.estimated_time : "-"}
                totalFile={active ? (+overallProgress?.totalNumberOfRecords)?.toFixed(0) : "-"}
                completedFile={active ? (+overallProgress?.totalNumberOfTransferredRecords)?.toFixed(0) : "-"}
                totalSize={active ? (+overallProgress?.totalSize)?.toFixed(2) : "-"}
                completedSize={active ? (+overallProgress?.totalSizeTransferred)?.toFixed(2) : "-"}
                // ftpAdress={active ? "192.102.85.33 / Ataberk" : "-"}
                ftpAdress={active ? `${ftpProfile?.host} / ${ftpProfile?.username}` : "-"}
                startDate={active ? overallProgress?.timestamp?.split(" ")[0] : "-"}
                startTime={active ? overallProgress?.timestamp?.split(" ")[1] : "-"}
                barColor={pause ? "#6D809FB3" : null}
                borderColor={pause ? "#6D809F" : null}
              />
              <Divider sx={{ marginY: "20px", color: "#6D809F", opacity: 0.8 }} />
              {/* {
                  "id": "890199",
                  "filename": "record_3169666.wav",
                  "totalSize": 6,
                  "totalSizeTransferred": 15
              } */}
              <ProgressSection
                percentage={active ?
                  (currentFileProgress?.totalSize && currentFileProgress?.totalSizeTransferred && +currentFileProgress?.totalSize != 0 && +currentFileProgress?.totalSizeTransferred != 0) ?
                    (((currentFileProgress?.totalSizeTransferred / currentFileProgress?.totalSize) * 100).toFixed(2)) 
                    : 0
                  : null } 
                title={"Current File Progress"}
                totalFile={active ? (+currentFileProgress?.totalSize)?.toFixed(2) : "-"}
                completedFile={active ? (+currentFileProgress?.totalSizeTransferred)?.toFixed(2) : "-"}
                fileName={active ? currentFileProgress?.filename : "-"}
                borderColor={pause ? "#6D809F" : "#FD581F"}
                barColor={"#f9fafb"}
              />
            </Box>
         </>
        ) : (
          <Box sx={{ padding: "32px 48px", height: "443px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <Box
                sx={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  gap: "3px",
                }}
                onClick={() => {setOpen(true)}}
              >
                <PlusIcon />
                <span
                  style={{
                    color: "#FD581F",
                    fontSize: "12px",
                    fontWeight: 600,
                    marginTop: "4px",
                  }}
                >
                  Create FTP Profile
                </span>
            </Box> 
          </Box>
        )}
      <RuleDialog
        open={ruleModalOpen}
        setOpen={setRuleModalOpen}
        title={ruleModalEdit ? "Edit Rule" : "Create Rule"}
        editMode={ruleModalEdit}
        activeDownload={active}
        ftpProfile={ftpProfile}
        setAlertSuccess={setAlertSuccess}
        setAlertMessage={setAlertMessage}
        setAlertOpen={setAlertOpen}
        downloadRule={downloadRule}
        setSchedule={setSchedule}
      />
      <CustomModal
        open={cancelModalOpen}
        setOpen={setCancelModalOpen}
        handleClose={() => {setCancelModalOpen(false)}}
        title="Cancel"
        content="Are you sure you want to cancel this transfer?"
        icon={null}
        submitClick={onClickCancel}
        buttonText1="Cancel"
        buttonText2="Confirm"
      />
      <CreateFtpProfileModal
        open={open}
        setOpen={setOpen}
        setAlertSuccess={setAlertSuccess}
        setAlertMessage={setAlertMessage}
        setAlertOpen={setAlertOpen}
        setFtpProfile={setFtpProfile}
        setCountFtp={setCountFtp}
      />
      <Alert
        message={alertMessage}
        severity={
          alertSuccess === true
            ? "success"
            : alertSuccess === "warning"
            ? "warning"
            : "error"
        }
        closable={true}
        open={alertOpen}
        onClose={() => {
          setAlertOpen(false);
        }}
      />
    </Box>
  );
}
