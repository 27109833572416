import React, { useState } from "react";
import { Autocomplete, Box, Popper, TextField } from "@mui/material";
import styles from "./DownloadIntervals.module.scss";
import { ReactComponent as DropDownArrow } from "@alotech/common/static/img/dropdownArrow.svg";
import { TimeIcon, DailyIcon, WeeklyIcon } from '@alotech/common/utils/SvgUtil';

export default function DownloadIntervals({
	selectedIntervalType,
	setSelectedIntervalType,
	selectedTime,
	setSelectedTime,
	selectedDate,
	setSelectedDate,
}) {
	const [timeSelectOpen, setTimeSelectOpen] = useState(false);
	const generateTimeArray = () => {
		const times = [];

		for (let hour = 0; hour <= 23; hour++) {
			const paddedHour = hour.toString().padStart(2, "0");

			for (let minute = 0; minute <= 30; minute += 30) {
				const paddedMinute = minute.toString().padStart(2, "0");
				times.push({
					value: `${paddedHour}:${paddedMinute}:00`,
					label: `${paddedHour}:${paddedMinute}:00`,
				});
			}
		}

		return times;
	};

	const daysArray = [
		{
			label: "Monday",
			value: "1",
		},
		{
			label: "Tuesday",
			value: "2",
		},
		{
			label: "Wednesday",
			value: "3",
		},
		{
			label: "Thursday",
			value: "4",
		},
		{
			label: "Friday",
			value: "5",
		},
		{
			label: "Saturday",
			value: "6",
		},
		{
			label: "Sunday",
			value: "0",
		},
	];


	const intervalTypeList = [
		{
			label: "Daily",
			icon: <DailyIcon active={selectedIntervalType == 0} />,
			value: 0
		},
		{
			label: "Weekly",
			icon: <WeeklyIcon active={selectedIntervalType == 1} />,
			value: 1
		},
	]

	return (
		<Box sx={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "center", marginTop: "40px" }}>
			<Box sx={{ color: "#314469", fontSize: "14px", fontWeight: 500 }}>At which interval would you like the download to happen?</Box>
			<Box sx={{ marginTop: "64px", display: "flex", gap: "16px", cursor: "pointer" }}>
				{intervalTypeList.map((element) => {
					let active = selectedIntervalType == element.value
					return (
						<Box onClick={() => setSelectedIntervalType(element.value)} key={element.value} sx={{ width: "160px", height: "56px", border: `1px solid ${active ? "#FD581F" : "#CCDCE6"}`, borderRadius: "8px", display: "flex", alignItems: "center", justifyContent: "center", gap: "10px", backgroundColor: active ? "#FD581F0A" : "#fff" }}>
							{element.icon}
							<Box style={{ color: active ? "#FD581F" : "#6D809F", fontSize: "14px", fontWeight: 500, lineHeight: 0, marginTop: "2px" }}>{element.label}</Box>
						</Box>
					)
				})}
			</Box>
			<Box sx={{ height: "138px", marginTop: "24px", width: "100%", backgroundColor: "#f9fafb", display: "flex", justifyContent: "center" }}>
				{(() => {
					switch (selectedIntervalType) {
						case 0:
							return (
								<Box sx={{ color: "#314469", alignItems: "center", justifyContent: "center", height: "100%", width: "260px", fontSize: "12px", display: "flex", fontWeight: 500, flexDirection: "column", alignSelf: "center", gap: "12px" }}>
									<span style={{ alignSelf: "flex-start" }}>Download Details</span>
									<Autocomplete
										id="combo-box-demo"
										value={selectedTime}
										isOptionEqualToValue={(option, value) => option.value == value}
										onChange={(e, option) => { option ? setSelectedTime(option.value) : setSelectedTime(null) }}
										open={timeSelectOpen}
										onOpen={() => { setTimeSelectOpen(true) }}
										onClose={() => { setTimeSelectOpen(false) }}
										popupIcon={<TimeIcon active={timeSelectOpen} />}
										options={generateTimeArray()}
										PopperComponent={({ style, ...props }) => (
											<Popper
												{...props}
												placement='bottom-start'
												style={{ ...style, }} // width is passed in 'style' prop
												sx={{
													"& .MuiAutocomplete-listbox": {
														maxHeight: "107px"
													}
												}}
											/>
										)}
										renderOption={(props, option) => {
											return (
												<Box {...props} key={option.value} className={styles.option}>
													{option.label}
												</Box>
											)
										}}
										size='small'
										sx={{
											width: "260px", borderColor: "#CCDCE6", outline: "none",
											borderRadius: 0,
											"& .MuiAutocomplete-popupIndicator": {
												marginTop: selectedTime ? "1px" : "3px",
												transform: 'none'
											},
											"& .MuiAutocomplete-inputRoot": {
												background: "white",
												color: "#314469",
												fontSize: "12px",
												fontWeight: 500,
												fontFamily: "Axiforma",
												"input": {
													marginTop: "3px"
												},
												"& .MuiOutlinedInput-notchedOutline": {
													borderColor: "#CCDCE6",
													borderRadius: "4px"
												},
											}
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												placeholder='Download Hour (hh:mm:ss aa)'
												variant='outlined'
												inputProps={{
													...params.inputProps,
												}}
												onChange={(e) => { 
													const value = e.target.value;
													if(value.length <= 8 && value.match(/^([01]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/)){
														setSelectedTime(value);
													} else {
														setSelectedTime(null);
													} 
												}}
												sx={{
													"& ::placeholder": {
														color: "#6D809F",
														fontSize: "12px",
														fontFamily: "Axiforma",
														fontWeight: 500,
													}
												}}
											/>)}
									/>

								</Box>
							)
						case 1:
							return (
								<Box sx={{ color: "#314469", alignItems: "center", justifyContent: "center", height: "100%", width: "536px", fontSize: "12px", display: "flex", fontWeight: 500, flexDirection: "column", alignSelf: "center", gap: "12px" }}>
									<span style={{ alignSelf: "flex-start" }}>Download Details</span>
									<Box sx={{ display: "flex", gap: "16px" }}>
										{/* week day */}
										<Autocomplete
											id="combo-box-demo"
											value={selectedDate}
											isOptionEqualToValue={(option, value) => option.value == value.value}
											onChange={(e, option) => { setSelectedDate(option) }}
											popupIcon={<DropDownArrow />}
											options={daysArray}
											PopperComponent={({ style, ...props }) => (
												<Popper
													{...props}
													placement='bottom-start'
													style={{ ...style, }} // width is passed in 'style' prop
													sx={{
														"& .MuiAutocomplete-listbox": {
															maxHeight: "107px"
														}
													}}
												/>
											)}
											renderOption={(props, option) => {
												return (
													<Box {...props} key={option.value} className={styles.option}>
														{option.label}
													</Box>
												)
											}}
											size='small'
											sx={{
												width: "260px", borderColor: "#CCDCE6", outline: "none",
												borderRadius: 0,
												"& .MuiAutocomplete-popupIndicator": {
													marginTop: selectedDate?.value ? "0px" : "2px",
												},
												"& .MuiAutocomplete-inputRoot": {
													background: "white",
													color: "#314469",
													fontSize: "12px",
													fontWeight: 500,
													fontFamily: "Axiforma",
													"input": {
														marginTop: "3px"
													},
													"& .MuiOutlinedInput-notchedOutline": {
														borderColor: "#CCDCE6",
														borderRadius: "4px"
													},
												}
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													placeholder='Select a week day'
													variant='outlined'
													sx={{
														"& ::placeholder": {
															color: "#6D809F",
															fontSize: "12px",
															fontFamily: "Axiforma",
															fontWeight: 500,
															lineHeight: 0
														}
													}}
												/>)}
										/>
										{/* download hour */}
										<Autocomplete
											id="combo-box-demo"
											value={selectedTime}
											isOptionEqualToValue={(option, value) => option.value == value}
											onChange={(e, option) => { option ? setSelectedTime(option.value) : setSelectedTime(null) }}
											open={timeSelectOpen}
											onOpen={() => { setTimeSelectOpen(true) }}
											onClose={() => { setTimeSelectOpen(false) }}
											popupIcon={<TimeIcon active={timeSelectOpen} />}
											options={generateTimeArray()}
											PopperComponent={({ style, ...props }) => (
												<Popper
													{...props}
													placement='bottom-start'
													style={{ ...style, }} // width is passed in 'style' prop
													sx={{
														"& .MuiAutocomplete-listbox": {
															maxHeight: "107px"
														}
													}}
												/>
											)}
											renderOption={(props, option) => {
												return (
													<Box {...props} key={option.value} className={styles.option}>
														{option.label}
													</Box>
												)
											}}
											size='small'
											sx={{
												width: "260px", borderColor: "#CCDCE6", outline: "none",
												borderRadius: 0,
												"& .MuiAutocomplete-popupIndicator": {
													marginTop: selectedTime ? "1px" : "3px",
													transform: 'none'
												},
												"& .MuiAutocomplete-inputRoot": {
													background: "white",
													color: "#314469",
													fontSize: "12px",
													fontWeight: 500,
													fontFamily: "Axiforma",
													"input": {
														marginTop: "3px"
													},
													"& .MuiOutlinedInput-notchedOutline": {
														borderColor: "#CCDCE6",
														borderRadius: "4px"
													},
												}
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													placeholder='Download Hour (hh:mm:ss aa)'
													variant='outlined'
													inputProps={{
														...params.inputProps,
													}}
													onChange={(e) => { 
														const value = e.target.value;
														if(value.length <= 8 && value.match(/^([01]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/)){
															setSelectedTime(value);
														} else {
															setSelectedTime(null);
														} 
													}}
													sx={{
														"& ::placeholder": {
															color: "#6D809F",
															fontSize: "12px",
															fontFamily: "Axiforma",
															fontWeight: 500,
															lineHeight: 0
														}
													}}
												/>)}
										/>
									</Box>
								</Box>
							)
						default:
							return (
								<Box sx={{ color: "#6D809F", alignItems: "center", justifyContent: "center", height: "100%", width: "100%", fontSize: "12px", display: "flex", fontWeight: 500 }}>
									<span>Select an option to view the details.</span>
								</Box>
							)

					}
				})()}
			</Box>
		</Box>
	)
}
