import * as React from 'react';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import {useEffect} from "react";
import { useSelector } from 'react-redux';

export default function ThemedSwitch(props) {
    const width = props.small ? 47 : 59
    const height = props.small ? 24 : 30
    const thumbSize =  props.small ? 22 : 28
    const translateX = props.small ? 22 : 28
    const {onChange, checked, ...others} = props
    const [checkState, setCheckState] = React.useState(checked)

    const darkModeState = useSelector(state => state.darkMode);
    const darkMode = darkModeState.darkMode;

    useEffect(()=>{
        setCheckState(checked);
    },[checked]);

    let color = null
    switch (props.klazz) {
        case "error":
            color = "#F02833"
            break;
        case "warn":
            color = "#ECAD00"
            break;
        case "info":
            color = "#1271D9"
            break;
        case "success":
            color = "#52D52A"
            break;
        default:
            color = "#52D52A"
            break;
    }
    
    const IOSSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: width,
        height: height,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 1,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(' + translateX+'px)',
                color: darkMode ? "#081D33" : '#fff',
            },
            '&.Mui-checked + .MuiSwitch-track': {
                backgroundColor: darkMode ? "#0d7033" : color,
                opacity: 1
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color: 'white',
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                background: '#C5CAD2 0% 0% no-repead padding-box',
                boxShadow: 'inset 1px 1px 0px #00000012'
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            boxShadow: '0px 2px 3px #00000029',
            width: thumbSize,
            height: thumbSize,
            color: darkMode ? "#98A5B7" : '',
        },
        '& .MuiSwitch-track': {
            borderRadius: 100,
            backgroundColor: darkMode ? "#2D4059" : '#E4E7F2',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    }));

    return <IOSSwitch onChange={(ev) => {
            setCheckState(ev.target.checked);
            if (onChange){
                onChange(ev)
            }
        }} checked={checkState} {...others}></IOSSwitch>
}