import React, { useState, useCallback, useEffect } from "react";
import { Box, setRef, Chip } from "@mui/material";
import styles from "./TableFiltersTab.module.scss";
import FilterTable, {
  FilterContainer,
} from "@alotech/common/components/filterTab/FilterTab";
import { ReactComponent as ColumnIcon } from "@alotech/common/static/img/filter_column.svg";
import {ReactComponent as RefreshIcon} from "@alotech/common/static/img/refresh.svg";
import { ReactComponent as AgentBusyIcon } from '@alotech/common/static/img/agentbusy.svg';
import { ReactComponent as SystemBusyIcon } from '@alotech/common/static/img/systembusy.svg';
import colors from '@alotech/common/styles/_export.scss';
const sx = {
  '&.MuiChip-root': {
    padding: '6px 14px 6px 10px',
    height: '28px',
  },
  '& .MuiChip-label': {
    fontSize: '12px',
    fontWeight: '500',
    paddingLeft: '4px !important',
    paddingRight: '4px !important',
    marginTop: '2px',
  },
  '& .MuiChip-icon': {
    marginRight: '0px !important',
    marginLeft: '0px !important',
    width: '16px',
    height: '16px',
  },
  '& .MuiChip-selectIcon': {
    marginRight: '0px !important',
    marginLeft: '0px !important',
    width: '16px',
    height: '16px',
  },
}

const TableFiltersTab = React.memo((props) => {
  const {
    ftpAddress,
    updateFtpAddressFunc,
    user,
    updateUserFunc,
    recordType,
    updateRecordTypeFunc,
    downloadResult,
    updateDownloadResultFunc,
    tenants,
    updateTenantsFunc,
    queues,
    updateQueuesFunc,
    agents,
    updateAgentsFunc,
    teams,
    updateTeamsFunc,
    statuses,
    updateStatusesFunc,
    isTableFilter,
    setSettingsAnchorEl,
    setRefresh,
    agentBusyCount,
		systemBusyCount,
  } = props;
  const [isAgentBusy, setIsAgentBusy] = useState(false);
  const [isSystemBusy, setIsSystemBusy] = useState(false);

  return (
    <Box className={styles.filterTabWrapper}>
     
      {isTableFilter && (
        <Box
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            flexFlow: "wrap",
            padding: "4px 24px",
            flex: 1,
          }}
        >
          <>
            <FilterContainer
              filterMenuStyle={{
                padding: "10px 24px",
                transform: "unset",
                borderRadius: "0 0 5px 5px",
                display: "flex",
              }}
            >
              
              {
                !!ftpAddress && (
                  <FilterTable
                  singleSelect
                  isUpdateEveryChange
                  options={ftpAddress}
                  update={updateFtpAddressFunc}
                  title="FTP Address"
                  icon="ftpAddress"
                  selectedIcon="selectedFtpAddress"
                />
                )
              }
             
             {
                !!tenants && (
                  <FilterTable
                  multiSelect
                  isUpdateEveryChange
                  options={tenants}
                  update={updateTenantsFunc}
                  title="All Tenants"
                  icon="user"
                  selectedIcon="selectedUser"
                />
                )
             }
               {
                !!agents && (
                  <FilterTable
                  multiSelect
                  isUpdateEveryChange
                  options={agents}
                  update={updateAgentsFunc}
                  title="All Agents"
                  icon="user"
                  selectedIcon="selectedUser"
                />
                )
             }
              {
                !!queues && (
                  <FilterTable
                  multiSelect
                  isUpdateEveryChange
                  options={queues}
                  update={updateQueuesFunc}
                  title="All Queues"
                  icon="queues"
                  selectedIcon="selectedQueues"
                />
                )
             }
              {
                !!teams && (
                  <FilterTable
                  multiSelect
                  isUpdateEveryChange
                  options={teams}
                  update={updateTeamsFunc}
                  title="All Teams"
                  icon="teams"
                  selectedIcon="selectedTeams"
                />
                )
             }
              {
                !!statuses && (
                  <FilterTable
                  multiSelect
                  isUpdateEveryChange
                  options={statuses}
                  update={updateStatusesFunc}
                  title="All Statuses"
                  icon="statuses"
                  selectedIcon="selectedStatuses"
                />
                )
             }
              { !!agentBusyCount && !!systemBusyCount && (
                  <>
                      <Chip
                        key="Agent Chat Busy"
                        label={`Agent Chat Busy (${agentBusyCount})`}
                        icon={<AgentBusyIcon />}
                        data-id="Agent Chat Busy"
                        variant='outlined'
                        onClick={() => setIsAgentBusy(!isAgentBusy)}
                        sx={sx}
                        style={
                          isAgentBusy === true ? {
                            backgroundColor: '#FD581FB3',
                            color: '#fff',
                            border: '1px solid #FD581F',
                            marginRight: '10px',
                          } : {
                              backgroundColor:`${colors.hover}`,
                              color: '#002983',
                              border: '1px solid #D8DCEB',
                            marginRight: '10px',
                          }
                        }
                      />
                      <Chip
                        key="System Chat Busy"
                        label={`System Chat Busy (${systemBusyCount})`}
                        icon={<SystemBusyIcon />}
                        data-id="System Chat Busy"
                        variant='outlined'
                        onClick={() => setIsSystemBusy(!isSystemBusy)}
                        sx={sx}
                          style={
                          isSystemBusy === true ? {
                            backgroundColor: '#ECAD00B3',
                            color: '#fff',
                            border: '1px solid #ECAD00',
                            marginRight: '10px',
                          } : {
                              backgroundColor:`${colors.hover}`,
                              color: '#002983',
                              border: '1px solid #D8DCEB',
                            marginRight: '10px',
                          }
                        }
                      />
                    </>
                  )}
             {
               !!user && ( 
                <FilterTable
                multiSelect
                isUpdateEveryChange
                options={user}
                update={updateUserFunc}
                title="User"
                icon="user"
                selectedIcon="selectedUser"
              />
                )
             }
              {
                !!recordType && (
                  <FilterTable
                  singleSelect
                  isUpdateEveryChange
                  options={recordType}
                  update={updateRecordTypeFunc}
                  title="Record Type"
                  icon="recordType"
                  selectedIcon="selectedRecordType"
                />
                )
              }
              {
                !!downloadResult && (
                  <FilterTable
                  multiSelect
                  isUpdateEveryChange
                  options={downloadResult}
                  update={updateDownloadResultFunc}
                  title="Download Result"
                  icon="downloadResult"
                  selectedIcon="selectedDownloadResult"
                />
                )
              }
              
            </FilterContainer>
          </>
        </Box>
      )}
      <Box className={styles.buttonsWrapper}>
      {setRefresh && (
          <div
            onClick={(e) => setRefresh(true)}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              borderLeft: "1px solid rgb(204, 220, 230)",
              display: "flex",
              width: "47px",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            {" "}
            <RefreshIcon />{" "}
          </div>
        )}
        {setSettingsAnchorEl && (
          <div
            onClick={(e) => setSettingsAnchorEl(e.currentTarget)}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              borderLeft: "1px solid rgb(204, 220, 230)",
              display: "flex",
              width: "47px",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            {" "}
            <ColumnIcon />{" "}
          </div>
        )}
      </Box>
    </Box>
  );
});

export default TableFiltersTab;
