import React, { useState, useEffect } from "react";
import { Box, Divider, Modal } from "@mui/material";
import { ReactComponent as CloseIcon } from "@alotech/common/static/img/closeIcon.svg";
import CustomButton from "@alotech/common/components/form/CustomButton";
import CustomizedButton from "@alotech/common/components/form/CustomizedButton";
import InputField from "@alotech/common/components/form/InputField";
import axios from "axios";
import { getCookie } from "@alotech/common/utils/Utils";

export default function CreateFtpProfileModal({
  open,
  setOpen,
  setAlertMessage,
  setAlertSuccess,
  setAlertOpen,
  setFtpProfile,
  setCountFtp
}) {
  const [profileName, setProfileName] = useState("");
  const [user, setUser] = useState("");
  const [type, setType] = useState("");
  const [host, setHost] = useState("");
  const [port, setPort] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [path, setPath] = useState("");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  const closeModal = () => {
    setOpen(false);
  };
  const handleSubmit = () => {
    const tenantName = getCookie("tenantName");
    axios.post("/res/setFTPProfile/",
      {
        request : {
          "operation":"INSERT",
          "recordType":"CALL",
          "tenant": tenantName?.split(".")[0],
          "profileName":profileName,
          "user":user,
          "type":type,
          "host":host,
          "port":port,
          "username":username,
          "password":password,
          "path":path
        }
      },
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    ).then((res) => {
      if (res.status === 200) {
          axios.post("/res/getFTPProfile/", {
            request : {
              "tenant":  tenantName?.split(".")[0]
            }
          },
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
          }
          ).then((res) => {
            const {data} = res.data.response;
            setFtpProfile(data[0]);
            setCountFtp(data.length);
          }).catch((err) => {
            console.log(err);
          })
        setAlertSuccess(true);
        setAlertMessage("FTP Profile Created Successfully");
        setAlertOpen(true);
        setOpen(false);
      }
    }).catch((err) => {
      setProfileName("");
      setUser("");
      setType("");
      setHost("");
      setPort("");
      setUsername("");
      setPassword("");
      setPath("");
      setAlertSuccess(false);
      setAlertMessage("FTP Profile Creation Failed");
      setAlertOpen(true);
    }
    )
  };

  return (
    <Modal
      onClose={closeModal}
      open={open}
      disableAutoFocus={true}
      disableEnforceFocus
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#FFFFFF",
          width: "818px",
          height: "620px",
          borderRadius: "8px",
          ...style,
        }}
      >
        <Box
          sx={{
            display: "flex",
            borderBottom: "1px solid #CCDCE6",
            height: "68px",
            alignItems: "center",
            paddingLeft: "32px",
            justifyContent: "space-between",
            paddingRight: "32px",
          }}
        >
          <span style={{ color: "#002983", fontSize: "20px", fontWeight: 500 }}>
            Create FTP Profile
          </span>
          <CloseIcon onClick={closeModal} style={{ cursor: "pointer" }} />
        </Box>
        <Box>
          <Box sx={{ paddingX: "32px", marginTop: "44px", rowGap: "15px", display: "flex", flexDirection: "column" }}>
            <InputField
              label={"Profile Name"}
              value={profileName}
              fullWidth
              onChange={(e) => setProfileName(e.target.value)}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                sx: {
                  height: "38px",
                  "& input": {
                    font: "normal normal medium 12px/51px Axiforma",
                    letterSpacing: "0px",
                    color: "#314469",
                  },
                  "& input::placeholder": {
                    fontSize: "10px"
                  },
                  "& .MuiOutlinedInput-root": {
                    marginBottom: "10px"
                  }
                }
              }}
            />
            <InputField
              label={"User E-mail"}
              value={user}
              fullWidth
              onChange={(e) => setUser(e.target.value)}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                sx: {
                  height: "38px",
                  "& input": {
                    font: "normal normal medium 12px/51px Axiforma",
                    letterSpacing: "0px",
                    color: "#314469",
                  },
                  "& input::placeholder": {
                    fontSize: "10px"
                  },
                  "& .MuiOutlinedInput-root": {
                    marginBottom: "10px"
                  }
                }
              }}
            />
            <InputField
              label={"Type"}
              value={type}
              fullWidth
              onChange={(e) => setType(e.target.value)}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                sx: {
                  height: "38px",
                  "& input": {
                    font: "normal normal medium 12px/51px Axiforma",
                    letterSpacing: "0px",
                    color: "#314469",
                  },
                  "& input::placeholder": {
                    fontSize: "10px"
                  },
                  "& .MuiOutlinedInput-root": {
                    marginBottom: "10px"
                  }
                }
              }}
            />
            <InputField
              label={"Host"}
              value={host}
              fullWidth
              onChange={(e) => setHost(e.target.value)}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                sx: {
                  height: "38px",
                  "& input": {
                    font: "normal normal medium 12px/51px Axiforma",
                    letterSpacing: "0px",
                    color: "#314469",
                  },
                  "& input::placeholder": {
                    fontSize: "10px"
                  },
                  "& .MuiOutlinedInput-root": {
                    marginBottom: "10px"
                  }
                }
              }}
            />
            <InputField
              label={"Port"}
              value={port}
              fullWidth
              onChange={(e) => setPort(e.target.value)}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                sx: {
                  height: "38px",
                  "& input": {
                    font: "normal normal medium 12px/51px Axiforma",
                    letterSpacing: "0px",
                    color: "#314469",
                  },
                  "& input::placeholder": {
                    fontSize: "10px"
                  },
                  "& .MuiOutlinedInput-root": {
                    marginBottom: "10px"
                  }
                }
              }}
            />
            <InputField
              label={"Username"}
              value={username}
              fullWidth
              onChange={(e) => setUsername(e.target.value)}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                sx: {
                  height: "38px",
                  "& input": {
                    font: "normal normal medium 12px/51px Axiforma",
                    letterSpacing: "0px",
                    color: "#314469",
                  },
                  "& input::placeholder": {
                    fontSize: "10px"
                  },
                  "& .MuiOutlinedInput-root": {
                    marginBottom: "10px"
                  }
                }
              }}
            />
            <InputField
              label={"Password"}
              value={password}
              fullWidth
              onChange={(e) => setPassword(e.target.value)}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                sx: {
                  height: "38px",
                  "& input": {
                    font: "normal normal medium 12px/51px Axiforma",
                    letterSpacing: "0px",
                    color: "#314469",
                  },
                  "& input::placeholder": {
                    fontSize: "10px"
                  },
                  "& .MuiOutlinedInput-root": {
                    marginBottom: "10px"
                  }
                }
              }}
            />
            <InputField
              label={"Path"}
              value={path}
              fullWidth
              onChange={(e) => setPath(e.target.value)}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                sx: {
                  height: "38px",
                  "& input": {
                    font: "normal normal medium 12px/51px Axiforma",
                    letterSpacing: "0px",
                    color: "#314469",
                  },
                  "& input::placeholder": {
                    fontSize: "10px"
                  },
                  "& .MuiOutlinedInput-root": {
                    marginBottom: "10px"
                  }
                }
              }}
            />
          </Box>
        </Box>
        <Box sx={{ marginLeft: "auto", marginY: "24px" }}>
          <CustomizedButton
            key="reset"
            variant="outlined"
            type="reset"
            color={"rgb(30, 55, 114)"}
            backgroundColor={"#EAECF567"}
            width={120}
            height={40}
            fontSize={12}
            text={"Cancel"}
            borderColor={"rgb(216, 220, 235)"}
            borderRadius={4}
            fontWeight={600}
            onClick={() => closeModal()}
            hoverBorderColor={"rgb(216, 220, 235)"}
          />
          <CustomizedButton
            key="submit"
            variant="outlined"
            onClick={handleSubmit}
            type="submit"
            width={120}
            height={40}
            backgroundColor={"#F02833"}
            color={"#fff"}
            fontSize={12}
            text={"Submit"}
            hoverBgColor={"#F02833"}
            borderRadius={4}
            marginRight={24}
            marginLeft={8}
            fontWeight={600}
          />
        </Box>
     
      </Box>
      
    </Modal>
  );
}
