import * as React from 'react';
import { styled } from '@mui/material/styles';

import Button from '@mui/material/Button';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import colors from '../../styles/_export.scss';


export default function StyledTooltip({title, arrow, color, bg, border, disableHoverListener, position, width, fontSize, children, marginTop}) {
    if (!border && (!bg || bg == "white")){
        border = colors.orangeTab
    }
    const ArrowTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: bg??'white',
            "&:before": {
                border: border?"2px solid "+border:null
            },
        },
        [`& .${tooltipClasses.tooltip}`]: {
            background: bg??'white',
            width: "100%",
            minHeight: 25,
            paddingTop: '6px',
            fontSize: fontSize ?? '16px',
            textAlign:'center',
            border: border?"2px solid "+border:null,
            color: color ?? colors.blueGray,
            maxWidth: width ?? 300,
        },
        [`& .${tooltipClasses.tooltipPlacementTop}`]: {
            marginBottom: "4px !important"
        },
        [`& .${tooltipClasses.tooltipPlacementBottom}`]: {
            marginTop: marginTop ?? "4px !important"
        },
    }));

    const tooltipProps = {}
    if (arrow){
        tooltipProps["arrow"] = true
    }
    if (position){
        tooltipProps["placement"] = position
    }
    if (disableHoverListener) {
        tooltipProps["disableHoverListener"] = true
    }
    return (
        <ArrowTooltip title={title} {...tooltipProps}>
            {children}
        </ArrowTooltip>
    );
}