import React, { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';

export default function DefaultTooltip({ children, title, placement, arrowStyle, tooltipStyle, spanStyle, noSpan }) {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        arrow
        placement={placement || "top"}
        open={open}
        onClose={handleTooltipClose} 
        onOpen={handleTooltipOpen}
        componentsProps={{
          tooltip: {
            sx: {
              color: "#FFFFFF",
              backgroundColor: "rgba(75, 101, 163, 0.9)",
              // boxShadow: "3px 3px 4px 0px rgba(0, 0, 0, 0.3)",
              // border: "1px solid rgba(75, 101, 163, 0.9)",
              fontSize: 12,
              ...tooltipStyle
            }
          },
          arrow: {
            sx: {
              "&:before": {
              },
              color: "rgba(75, 101, 163, 0.9)",
              ...arrowStyle
            }
          },
        }}
        title={title}>
          {noSpan ? children :  <span style={spanStyle ?? {}} onClick={handleTooltipOpen}>{children}</span>}
      </Tooltip>
    </ClickAwayListener>
  )
}
