import * as React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ReactComponent as CloseIcon } from '../../static/img/icon_close.svg';
import { ReactComponent as CloseIconDarkMode } from '../../static/img/icon_close_darkMode.svg';
import classes from './CustomDialog.module.scss';
import { FormButton, FormField } from '../form';
import colors from '../../styles/_export.scss';
import { strings } from '../i18n';
import { useSelector } from 'react-redux';

export const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle className={classes.header} {...other}>
            {children}
            {onClose ? (
                <button className={classes.CloseButton} onClick={onClose}>
                    <CloseIcon />
                </button>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};


export default function CustomDialog({ name, open, width, handleClose, submitProps, formID, hasFooter = false, footerBorder = true, footerField, onClick, cancelProps, content, disabled, dialogTabBorderBottom, nameStyle, ...others }) {

    const darkModeState = useSelector(state => state.darkMode);
    const darkMode = darkModeState.darkMode;


    const theme = createTheme({
        components: {
            MuiDialog: {
                styleOverrides: {
                    paper: {
                        backgroundColor: darkMode ? '#081D33 !important' : `${colors.white} !important`,
                        borderRadius: '12px',
                        width: width || '936px'
                    }
                }
            },
            MuiDialogTitle: {
                styleOverrides: {
                    root: {
                        marginBottom: '8px'
                    }
                }
            },
            MuiDialogContent: {
                styleOverrides: {
                    root: {
                        border: 'none',
                        padding: '0px',
                        '& .MuiTabs-root': {
                            padding: '0px 32px',
                            borderBottom: dialogTabBorderBottom || (darkMode ? 'solid 2px #2D4059' : 'solid 2px #e4e7f2')
                        },
                        '& .MuiTabPanel-root': {
                            paddingTop: '30px',
                        },
                    }
                }
            },
            MuiDialogActions: {
                styleOverrides: {
                    root: {
                        backgroundColor: `${colors.white}`,
                        padding: '8px',
                        borderTop: footerBorder ? '1px solid #E4E4E4' : 'none',
                        height: 113
                    }
                }
            },
            MuiTabs: {
                styleOverrides: {
                    root: {
                        padding: '0px 32px',
                        borderBottom: 'solid 2px #e4e7f2'
                    }
                }
            },
            MuiTabPanel: {
                styleOverrides: {
                    root: {
                        paddingTop: '30px !important'
                    }
                }
            }
        }
    });

    return (

        <ThemeProvider theme={theme}>
            <Dialog
                maxWidth='xl'
                onClose={handleClose}
                open={open}
                {...others}>

                {name &&
                    <DialogTitle className={classes.header}>
                        <span className={darkMode ? classes.headerTextDarkMode : classes.headerText} style={nameStyle}>{name}</span>
                        {handleClose ? (
                            <button className={darkMode ? classes.CloseButtonDarkMode : classes.CloseButton} onClick={handleClose}>
                                {darkMode ? <CloseIconDarkMode /> : <CloseIcon />}
                            </button>
                        ) : null}
                    </DialogTitle>
                }

                <DialogContent>
                    {content}
                </DialogContent>
                {
                    hasFooter ?
                        <DialogActions sx={{ background: darkMode ? '#081D33 !important' : "" }}>
                            {footerField ? <div style={{
                                width: "200px",
                                position: "absolute",
                                left: "32px"
                            }}><FormField data={footerField} /><div style={{ flexGrow: 1 }}></div></div> : null}
                            <FormButton className={classes.cancelButton} key="reset" size="medium" variant="contained"
                                color="secondary"
                                onClick={handleClose} type="reset"{...cancelProps}>{strings.cancel}</FormButton>
                            <FormButton className={classes.submitButton} key="submit" form={formID} onClick={onClick} size="medium" variant="contained"
                                color="primary" type="submit" {...submitProps} disabled={disabled}>{strings.save}</FormButton>
                        </DialogActions>
                        : null
                }

            </Dialog>
        </ThemeProvider>
    )
}
