import React,{ useState, useEffect } from 'react';
import QRCode from "react-qr-code";
//Mui
import Modal from '@material-ui/core/Modal';
import {makeStyles} from '@material-ui/core/styles';
//Img
import closeIcon from "../static/img/close.png";
//Sass
import styles from "../styles/NewModal.module.scss";

export default function ({
    openNewModal,
    clickOkButton,
    clickCancelButton,
    qrUri
    }) {
    
    // You have to send these props

    // openNewModal --> boolean
    // clickOkButton --> function
    // clickCancelButton --> function
    // qr_uri --> string

    const [modalStyle] = useState(getModalStyle);
    const [otp, setOtp] = useState("");
    
    function getModalStyle() {
        const top = 50 ;
        const left = 50 ;
        
        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
            width: "auto",
            height: "auto",
            paddingBottom: "10px"
        };
    }

    const useStyles = makeStyles(theme => ({
        paper: {
            position: 'absolute',
            width: 610,
            height: 293,
            background: "#FFFFFF 0% 0% no-repeat padding-box",
            border: '1px solid #E4E7F2',
            borderRadius: "10px",
            boxShadow:"0px 4px 10px #0000001A",
            outline: 'none'
        },
    }));
    
    const classes = useStyles();
    
    const clkOkButton = () => {
        clickOkButton(otp);
    }
    const handleChangeOTPCode = (e) => {
        setOtp(e.target.value);
    }

    return (
        <>
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={openNewModal}
            >
                <div style={modalStyle} className={classes.paper}>
                    <div className={styles.headerContainer}>
                        <div className={styles.modalTitle}>
                            <h5>{'{{ _("Verification Code") }}'}</h5>
                        </div>
                        <div onClick={clickCancelButton} className={styles.modalCloseIcon}>
                            <img src={closeIcon} alt="closeIcon"/>
                        </div>
                    </div>
                    
                    {
                        qrUri ?
                            <div style={{display:'flex', padding:'30px 30px 0px 30px', gap:'20px'}}>
                                <div style={{width:'256px'}}>
                                    <span>
                                        Scan the QR code to match the user with the authentication method. You need to add an extension to your browser to scan the QR code. Search your browser’s extension marketplace for “authenticator” and add a suitable extension. Scanning the QR code is only for the first login. Once the matching is complete, you may use the codes generated in the extension or the mobile app to log in.
                                    </span>
                                </div>
                                <div style={{ height: "auto", margin: "0 auto", maxWidth: 256, width: "100%" }}>
                                    <QRCode
                                        size={256}
                                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                        value={qrUri}
                                        viewBox={`0 0 256 256`}
                                    />
                                </div>
                            </div>
                        :
                            <div className={styles.contentContainer}>
                                <span>
                                    You may get the verification code from the extension or the mobile app.
                                </span>
                            </div>
                    }

                    <div className={styles.inputContainer}>
                        <input type="text" placeholder={"Enter your OTP code"} onChange={handleChangeOTPCode} />
                    </div>
                    <div className={styles.buttonsContainer}>
                        <button onClick={clkOkButton} className={styles.rightButton}>
                            OK
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    )
}