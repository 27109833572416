import React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import Radio from "@mui/material/Radio";

import colors from "../../styles/_export.scss";

const PrimaryRadioBorder = styled("span")(({ theme }) => ({
  borderRadius: 100,
  width: 21,
  height: 21,
  border: "1px solid #D8DCEB",
  backgroundColor: "white",
  "input:hover ~ &": {
    backgroundColor: colors.hover,
  },
  "input:disabled ~ &": {
    border: "none",
    background: colors.disabled,
  },
}));

const theme = createTheme({
  components: {
    MuiRadio: {
      styleOverrides: {
        root: {
          padding: "6px 12px",
        },
        colorPrimary: {
          color: "#1E3772",
          "&.Mui-checked": {
            color: "#1E3772",
            "&.Mui-disabled": {
              opacity: 0.3,
            },
          },
          "&.Mui-disabled": {
            opacity: 0.3,
          },
        },
        colorSecondary: {
          color: "#F02833",
          "&.Mui-checked": {
            color: "#F02833",
          },
        },
        colorInfo: {
          color: "#1271D9",
          "&.Mui-checked": {
            color: "#1271D9",
          },
        },
      },
    },
  },
});

export default function RadioButton(props) {
  const { ...other } = props;

  return (
    <ThemeProvider theme={theme}>
      <Radio {...other} icon={<PrimaryRadioBorder />} />
    </ThemeProvider>
  );
}
