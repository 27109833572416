import React, { useLayoutEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import axios from "axios";
//Mui
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
//Sass
import "./App.scss";
import configData from "./config.json";
//Pages
import Layout from "@alotech/common/components/layout/Layout";
import Dashboard from "./pages/dashboard/DashboardPage";
import LoginPage from "@alotech/common/components/login/LoginPage";
import LoginLayout from "@alotech/common/components/layout/LoginLayout";
import { HashRouter } from "react-router-dom";

LicenseInfo.setLicenseKey(configData.muiLicense);

  const theme = createTheme({
    typography: {
      button: {
        textTransform: "none",
      },
    },
    components: {
      MuiPopover: {
        styleOverrides: {
          root: {
            padding: "26px",
          },
          paper: {
            boxShadow: "0 10px 20px 0 rgba(0, 0, 0, 0.05)",
            border: "solid 1px #e4e7f2",
            backgroundColor: "#fff !important",
            borderRadius: 12,
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontFamily: "Axiforma",
            fontWeight: "600",
            margin: "auto",
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            backgroundColor: "#fff",
            ".MuiTypography-caption": {
              color: "#032983",
              fontFamily: "Axiforma",
              fontSize: "18px",
            },
          },
        },
      },
    },
  });

  const App = () => {
    const [isLogin, setIsLogin] = useState(localStorage.getItem("isLogin") === "true" ? true : false);

    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <HashRouter>
          <Routes>
            <Route path="/" >
              <Route element={<LoginLayout contains="login" />}>
                  <Route index element={<LoginPage setIsLogin={setIsLogin} isOpenAdminModal={false} />} />
              </Route>
              {
                isLogin ? 
                    <Route element={<Layout />}>
                      <Route  path='/dashboard' element={<Dashboard />} />
                    </Route> : null
              }
            </Route>
          </Routes>
        </HashRouter>
      </ThemeProvider>
    );
  };

export default App;
