import React from "react";

export default function Header({ title }) {
  return (
    <div
      style={{
        color: "#0129A5",
        borderBottom: "1px solid #CCDCE6",
        height: "48px",
        fontSize: "14px",
        fontWeight: 500,
        display: "flex",
        alignItems: "center",
        gap: "7px",
      }}
    >
      <span style={{ marginLeft: "16px", color: "#0129A5" }}>{title}</span>
    </div>
  );
}
